import { useGetBrandedTournamentContent } from '@hooks';
import { matchPath, useLocation } from 'react-router-dom';
/**
 * Header should not appear on the influencer page unless the influencer page contains an error
 *
 * @returns
 */
const useShouldHideHeader = () => {
    const { pathname } = useLocation();
    // This needs to match the routes in RepeatAppScreen.tsx
    const matchedPath = matchPath(pathname, {
        path: ['/influencer-page/:slug', '/ip/:slug'],
    });
    const query = useGetBrandedTournamentContent(matchedPath === null || matchedPath === void 0 ? void 0 : matchedPath.params.slug);
    return !!matchedPath && !query.isError;
};
export default useShouldHideHeader;
