import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as React from 'react';
import { ClickAwayListener, Divider, Fade, Paper, Popper, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import doTrackEvent from '@components/Analytics/doTrackEvent';
import OutlineButton from '@components/Buttons/OutlineButton';
import useUserGamesListener from '@components/Hooks/useUserGamesListener';
import getGamePalette from '@helpers/getGamePalette';
import { useUserState } from '@context/UserContext';
import TagList from '../../../components/UI/TagList';
import { Link } from 'react-router-dom';
import { ACTION_SHOW_GAME_MODAL, useAddGameWebView } from '@hooks/useAddGameWebView';
const useStyles = makeStyles()((theme, { paletteName }) => ({
    button: {
        color: '#FFF',
        '& > div': {
            flex: 1,
            display: 'inline-flex',
            alignItems: 'center',
        },
        width: '178px',
        [theme.breakpoints.down('sm')]: {
            width: '70px',
        },
        marginLeft: theme.spacing(2),
        background: theme.palette[paletteName].main,
    },
    buttonGameIcon: {
        height: '18px',
        marginRight: '8px',
    },
    dropdownSelected: {
        borderRadius: '5px 5px 0px 0px',
    },
    roundedBottom: {
        borderRadius: '0px 0px 5px 5px',
    },
    dropdownContainer: {
        width: '178px',
        color: theme.palette.grey[300],
        display: 'flex',
        flexDirection: 'column',
    },
    dropdownRow: {
        padding: '8px',
        paddingLeft: '14px',
        paddingRight: '14px',
    },
    accountName: {
        fontSize: '14px',
        fontWeight: 700,
        marginBottom: '8px',
        color: theme.palette.grey[900],
        '& i': {
            marginRight: '8px',
            color: theme.palette.error.main,
        },
    },
    addAccount: {
        display: 'flex',
        justifyContent: 'center',
    },
    addButton: {
        background: theme.palette.common.white,
        alignSelf: 'center',
        border: '0',
        '& i': {
            marginRight: theme.spacing(1),
        },
        fontWeight: 600,
        fontSize: '12px',
        color: theme.palette.grey[900],
    },
    ugrRow: {
        background: theme.palette.common.white,
        border: '0',
        textAlign: 'start',
        padding: '12px',
        flex: 1,
    },
    connectGameButton: {
        [theme.breakpoints.down('sm')]: {
            width: '43px',
        },
    },
    popper: {
        zIndex: 1,
    },
}));
/**
 * This is used in the Tournament Banner where it displays user game name OR
 * a "Connect ID" button for user to add game.
 * It DOES NOT contain AddGameModal. Instead it exposes an
 * onAddGameClick event, and relies on the parent
 */
const TournamentBannerButton = ({ gameData, onAddGameClick }) => {
    var _a;
    const { classes, cx } = useStyles({
        paletteName: getGamePalette(gameData.gameKey),
    });
    const { userProfile } = useUserState();
    const { userGameByGameKey } = useUserGamesListener(userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle);
    const userGameRegions = userGameByGameKey(gameData.gameKey);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [referenceElement, setReferenceElement] = React.useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // When available regions change, update the selected index
    useEffect(() => {
        var _a, _b;
        const selectedAccounts = JSON.parse((_a = localStorage.getItem('repeatSelectedAccounts')) !== null && _a !== void 0 ? _a : '{}');
        const ugrId = (_b = selectedAccounts[gameData.gameKey]) === null || _b === void 0 ? void 0 : _b.id;
        if (!ugrId || !userGameRegions) {
            return;
        }
        userGameRegions.forEach((region, index) => {
            if (region.id === ugrId) {
                setSelectedIndex(index);
            }
        });
    }, [userGameRegions]);
    // Save selected UGR in localStorage against game_id
    const onItemSelected = (index) => {
        var _a;
        setShowDropdown(false);
        setSelectedIndex(index);
        localStorage.setItem('repeatSelectedAccounts', JSON.stringify({
            ...JSON.parse((_a = localStorage.getItem('repeatSelectedAccounts')) !== null && _a !== void 0 ? _a : '{}'),
            [gameData.gameKey]: userGameRegions[index],
        }));
    };
    const handleConnectGameClick = (label) => {
        onAddGameClick(label);
        doTrackEvent({
            type: 'show_game_modal',
            openedFrom: 'TournamentBannerButton',
            game: gameData.gameKey,
        });
    };
    useAddGameWebView(ACTION_SHOW_GAME_MODAL, () => handleConnectGameClick('mobileApp'));
    if (!userGameRegions || !userGameRegions.length) {
        return (_jsxs(OutlineButton, { className: cx(classes.button, classes.connectGameButton), onClick: () => handleConnectGameClick('Connect Game ID'), children: [!isMobile && (_jsxs(_Fragment, { children: [_jsx("img", { src: `/img/game_icons/${gameData.gameKey}.svg`, className: classes.buttonGameIcon, alt: gameData.name }), "Connect Game ID"] })), isMobile && _jsx("i", { className: "fas fa-plus" })] }));
    }
    return (_jsx(ClickAwayListener, { onClickAway: () => setShowDropdown(false), children: _jsxs("div", { ref: setReferenceElement, "data-testid": "game ids button", children: [_jsxs(OutlineButton, { className: cx(classes.button, showDropdown ? classes.dropdownSelected : ''), onClick: () => setShowDropdown(!showDropdown), children: [_jsxs("div", { "data-notranslate": true, children: [_jsx("img", { src: `/img/game_icons/${gameData.gameKey}.svg`, className: classes.buttonGameIcon, alt: gameData.name }), userGameRegions && !isMobile && ((_a = userGameRegions[selectedIndex]) === null || _a === void 0 ? void 0 : _a.handle)] }), _jsx("i", { className: "fa fa-caret-down" })] }), _jsx(Popper, { placement: "bottom-end", transition: true, anchorEl: referenceElement, open: showDropdown, className: classes.popper, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 350, children: _jsx(Paper, { square: true, className: cx(classes.dropdownContainer, classes.roundedBottom), "data-testid": "game ids dropdown", children: _jsxs(_Fragment, { children: [_jsx("div", { className: classes.dropdownRow, children: "Accounts" }), _jsx(Divider, {}), userGameRegions &&
                                        userGameRegions.map((region, index) => (_jsxs("div", { children: [_jsxs("button", { type: "button", className: cx(classes.ugrRow, classes.dropdownRow), onClick: () => onItemSelected(index), children: [_jsx("div", { className: classes.accountName, "data-notranslate": true, children: region.handle }), _jsx(TagList, { tags: [
                                                                {
                                                                    icon: 'fas fa-map-marker-alt',
                                                                    label: region.platform ? `${region.region} - ${region.platform.name}` : region.region,
                                                                },
                                                            ] })] }, `${region.id}-button`), _jsx(Divider, {}, `${region.id}-divider`)] }, region.id))), _jsx("div", { className: classes.addAccount, children: _jsxs("button", { type: "button", className: cx(classes.addButton, classes.dropdownRow), onClick: () => {
                                                setShowDropdown(false);
                                                handleConnectGameClick('Add Account');
                                            }, children: [_jsx("i", { className: "fal fa-plus-circle" }), "Add Account"] }) }), _jsx(Divider, {}), _jsxs(Link, { to: "/account/game-ids", className: cx(classes.addButton, classes.dropdownRow, classes.roundedBottom), children: [_jsx("i", { className: "fas fa-cog" }), "Manage Accounts"] })] }) }) })) })] }) }));
};
export default TournamentBannerButton;
