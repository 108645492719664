import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        textAlign: 'start',
        backgroundColor: '#f9f9f9',
        borderRadius: '4px',
        paddingRight: theme.spacing(2),
        minHeight: '70px',
        border: '0px',
        padding: 0,
        width: '100%',
    },
    iconContainer: {
        width: '41px',
        height: '41px',
        borderRadius: '4px',
        margin: theme.spacing(2),
        backgroundColor: '#F0F0F0',
        border: '1px solid #CACCCF',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        color: theme.palette.grey[400],
        '& i': {
            fill: '#85898D',
            height: '14px',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    textContainer: {
        fontFamily: 'Montserrat',
        flexDirection: 'row',
        flex: 1,
        '& h3': {
            fontWeight: 700,
            color: theme.palette.grey[900],
            margin: 0,
        },
        '& p': {
            margin: 0,
            marginTop: '4px',
            fontWeight: 600,
            color: theme.palette.grey[400],
        },
    },
}));
const AddLinkedAccountRow = ({ onPress }) => {
    const { classes } = useStyles();
    const addGameButtonLabel = 'Add Account';
    return (_jsxs("button", { className: classes.container, type: "button", onClick: () => onPress(addGameButtonLabel), children: [_jsx("div", { className: classes.iconContainer, children: _jsx("i", { className: "fa fa-plus" }) }), _jsxs("div", { className: classes.textContainer, children: [_jsx("h3", { children: addGameButtonLabel }), _jsx("p", { children: "Click here to add account" })] })] }));
};
export default AddLinkedAccountRow;
