import { createTheme } from '@mui/material/styles';
export const PaletteNames = [
    'primary',
    'secondary',
    'success',
    'error',
    'warning',
    'info',
    'purple',
    'grey',
    'fortnite',
    'cod',
    'cod-2',
    'lol',
    'dota2',
    'valorant',
    'apex',
    'twitch',
    'twitter',
    'youtube',
    'instagram',
    'paypal',
    'orange',
    'facebook',
    'psn',
    'playstation',
    'switch',
    'xbox',
    'pc',
    'mobile',
    'discord',
    'pouchDark',
    'swagbucksDark',
    'operaGX',
    'reddit',
    'google',
    'pubg',
    'pubg-mobile',
    'brawlStars',
    'apex-legends',
    'destiny-2',
    'rocketLeague',
    'riot',
    'mlb-the-show-23',
];
export const isPaletteName = (maybePaletteName) => PaletteNames.includes(maybePaletteName);
const RepeatTheme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'Roboto', 'Arial', 'sans-serif'].join(','),
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        common: {
            white: '#fff',
            black: '#1F2123',
        },
        primary: {
            dark: '#00567B',
            main: '#00ACF6',
            light: '#E6F7FE', // 10% opacity of main
        },
        secondary: {
            dark: '#7F5818',
            main: '#FFB400',
            light: '#fff7e6', // 10% opacity of main
        },
        success: {
            dark: '#006943',
            main: '#01D387',
            light: '#E6FBF3',
            contrastText: 'white',
        },
        error: {
            dark: '#731010',
            main: '#FF5A50',
            light: '#FAE9E8', // 10% opacity
        },
        warning: {
            dark: '#a85a37',
            main: '#FFB130',
            light: '#FEF3E9',
        },
        orange: {
            dark: '#DF9E32',
            main: '#FFB130',
            light: '#FFD083',
            contrastText: '#FFFFFF',
        },
        purple: {
            dark: '#8830b2',
            main: '#C345FF',
            light: '#f9ecff', // 10% opacity
        },
        fortnite: {
            main: '#605BCD',
            light: '#EAE9F5',
            dark: '#504FB4',
        },
        cod: {
            main: '#333E41',
            light: '#E5E6E7',
            dark: '#1d2829', // 10% opacity
        },
        'cod-2': {
            main: '#7EC92E',
            light: '#e1f5ce',
            dark: '#66AA1D',
            contrastText: '#fff',
        },
        lol: {
            main: '#00ACF6',
            light: '#E0F1F9',
            dark: '#1d566f', // 10% opacity
        },
        dota2: {
            main: '#fa6848',
            light: '#F9EBE7',
            dark: '#b94a2f', // 10% opacity
        },
        valorant: {
            main: '#FF4655',
            light: '#e8d6d7',
            dark: '#1e282b', // 10% opacity
        },
        apex: {
            main: '#cd3333',
            light: '#d24848',
            dark: '#b42b2b', // 10% opacity
        },
        twitch: {
            main: '#9146FF',
            light: '#BFABFF',
            dark: '#41145F',
            contrastText: '#fff',
        },
        twitter: {
            main: '#1DA1F2',
            light: '#6cd2ff',
            dark: '#0073bf',
            contrastText: '#fff',
        },
        youtube: {
            main: '#FF0000',
            light: '#ff5a36',
            dark: '#c20000',
            contrastText: '#fff',
        },
        instagram: {
            dark: '#C13584',
            main: '#E1306C',
            light: '#F77737',
            contrastText: '#fff',
        },
        paypal: {
            dark: '#152B60',
            main: '#253B80',
            light: '#354BA0',
            contrastText: '#fff',
        },
        facebook: {
            dark: '#004dbe',
            main: '#4267B2',
            light: '#6aa3ff',
            contrastText: '#fff',
        },
        psn: {
            // The PSN Logo should only be able to be brand colour or white or black(add game connect)
            dark: '#000000',
            main: '#023791',
            light: '#fff',
            contrastText: '#fff',
        },
        switch: {
            main: '#e4000f',
        },
        xbox: {
            main: '#0e7a0d',
        },
        pc: {
            main: '#ff9900',
        },
        mobile: {
            main: '#8527cf',
        },
        playstation: {
            dark: '#004dbe',
            main: '#023791',
            light: '#6aa3ff',
            contrastText: '#fff',
        },
        discord: {
            dark: '#3f5ca8',
            main: '#7289DA',
            light: '#a5b9ff',
            contrastText: '#fff',
        },
        reddit: {
            dark: '#c41d00',
            main: '#FF5700',
            light: '#ff8a3d',
            contrastText: '#fff',
        },
        google: {
            main: '#4285F4',
            light: '#7BAAF7',
            dark: '#2E5DAB',
            contrastText: '#fff',
        },
        pouchDark: {
            main: '#E5BC2C',
            light: '#E5BC2C',
            dark: '#1C1B18',
            contrastText: '#fff',
        },
        swagbucksDark: {
            main: '#6AB8D5',
            light: '#6AB8D5',
            dark: '#EE1831',
            contrastText: '#fff',
        },
        operaGX: {
            main: '#FA1E4E',
            light: '#FA1E4E',
            dark: '#FA1E4E',
            contrastText: '#fff',
        },
        pubg: {
            dark: '#d39300',
            main: '#E9B52A',
            light: '#FAC946',
            contrastText: '#fff',
        },
        'pubg-mobile': {
            dark: '#D99801',
            main: '#FFC031',
            light: '#FAC946',
            contrastText: '#fff',
        },
        brawlStars: {
            dark: '#D61A1A',
            main: '#F01919',
            light: '#ffe1e1',
            contrastText: '#fff',
        },
        'destiny-2': {
            dark: '#243F51',
            main: '#2A4A60',
            light: '#4b6576',
            contrastText: '#fff',
        },
        rocketLeague: {
            dark: '#006CB5',
            main: '#017FD3',
            light: '#25A6FC',
            contrastText: '#fff',
        },
        'mlb-the-show-23': {
            dark: '#1F0B37',
            main: '#310355',
            light: '#8626DF',
            contrastText: '#fff',
        },
        riot: {
            dark: '#EB0029',
            main: '#EB0029',
            light: '#EB0029',
            contrastText: '#fff',
        },
        info: {
            dark: '#7f7300',
            main: '#FFE700',
            light: '#FFFDE4',
        },
        grey: {
            50: '#fbfbfb',
            100: '#f0f0f0',
            200: '#cacccf',
            300: '#a0a4a8',
            400: '#85898D',
            500: '#73787d',
            600: '#62686d',
            700: '#52575c',
            800: '#44484d',
            900: '#25282B',
        },
    },
    components: {
        MuiModal: {
            styleOverrides: {
                root: {
                    fontFamily: ['Montserrat', 'Roboto', 'Arial', 'sans-serif'].join(','),
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    fontFamily: ['Montserrat', 'Roboto', 'Arial', 'sans-serif'].join(','),
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                adornedStart: {
                    backgroundColor: '#f3f3f3',
                },
                input: {
                    backgroundColor: '#f3f3f3',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '@media (min-width: 1080px)': {
                        maxWidth: '1420px', // contentMaxWidth
                    },
                    '@media (max-width: 1080px)': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '12px',
                },
                popper: {
                    zIndex: 9999999, // more than GenericModal
                },
            },
        },
    },
    zIndex: {
        // Zendesk chat button zindex
        chat: 999998,
        genericModal: 999999,
    },
    layout: {
        headerDesktopHeight: 55,
        headerMobileHeight: 72,
        contentMaxWidth: 1420,
        containerMaxWidth: 1468,
        contentDesktopSidePadding: '24px', // These two values containerMaxWidth and contentDesktopSidePadding are used in tandem to ensure the center content has a max-width of 1420px on desktop larger than 1420px.
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            // Desktop menu runs out of space around this breakpoint
            header: 1080,
            claimPrizesMobileLayout: 1365,
        },
    },
});
export default RepeatTheme;
