import getGameModes from '@api/getGameModes';
import { displayMessageFromError } from '@helpers';
import { useState } from 'react';
import { useQuery } from 'react-query';
const useGameModes = (gameKey) => {
    const [error, setError] = useState();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['game-mode', gameKey],
        queryFn: () => getGameModes(gameKey),
        onError: (err) => setError(displayMessageFromError(err)),
        onSuccess: () => setError(undefined),
    });
    return {
        gameModes: (data !== null && data !== void 0 ? data : []).sort((a, b) => a.sortOrder - b.sortOrder),
        isLoading,
        isError,
        error,
    };
};
export default useGameModes;
