import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Icon from './Icon';
const LoginServiceIcon = ({ service, optimise = false, color, strength = 'main', height, width, }) => {
    switch (service) {
        case 'activision':
            return (_jsx(Icon, { icon: "activision", optimise: optimise, color: color, strength: strength, height: height, width: width }));
        case 'battlenet':
            return (_jsx(Icon, { icon: "battlenet", optimise: optimise, color: color, strength: strength, height: height, width: width }));
        case 'psn':
            return _jsx(Icon, { icon: "ps4", optimise: optimise, strength: 'dark', height: height, width: width });
        case 'steam':
            return _jsx(Icon, { icon: "steam", optimise: optimise, color: color, strength: strength, height: height, width: width });
        case 'xboxlive':
            return (_jsx(Icon, { icon: "xboxone", optimise: optimise, color: color, strength: strength, height: height, width: width }));
        default:
            return _jsx(_Fragment, {});
    }
};
export default LoginServiceIcon;
