import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { ModalContent } from '@components/Modals/components';
import { Icon } from '@components';
import ConnectTwitchLink from './ConnectTwitchLink';
const useStyles = makeStyles()((theme) => ({
    container: {
        textAlign: 'center',
        fontFamily: theme.typography.fontFamily,
    },
    twitchLogo: {
        fill: theme.palette.twitch.main,
        width: '86px',
        paddingTop: theme.spacing(2),
    },
    title: {
        fontWeight: 800,
        fontSize: '26px',
        lineHeight: '128.9%',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        color: theme.palette.grey[900],
    },
}));
/**
 * This is one of the steps in the add social media account modal flow
 */
const ConnectTwitchAccount = ({ className }) => {
    const { classes, cx } = useStyles();
    return (_jsxs(ModalContent, { children: [_jsxs("div", { className: cx(classes.container, className), children: [_jsx(Icon, { icon: 'twitch', className: classes.twitchLogo }), _jsxs("h4", { id: "connect-twitch-heading", className: classes.title, children: ["Connect ", _jsx("span", { "data-ignore": true, children: "Twitch" })] }), _jsx("section", { children: _jsxs("p", { children: ["You\u2019ll be redirected to ", _jsx("span", { "data-ignore": true, children: "Twitch" }), " Login page in order to complete this process"] }) })] }), _jsx(ConnectTwitchLink, {})] }));
};
export default ConnectTwitchAccount;
