import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import IconBackground from '@components/UI/Icon/IconBackground';
import Flex from '@components/Layout/Flex';
import { Icon } from '@components/UI/Icon';
import { Button } from '@mui/material';
const shouldForwardProp = (prop) => prop !== 'buttonsize';
const StyledButton = styled(Button, { shouldForwardProp }) `
  color: ${({ theme }) => theme.palette.grey[900]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  line-height: 1;
  border-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.spacing(1)};
  height: ${({ buttonsize }) => (buttonsize === 'sm' ? '46px' : '52px')};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.palette.grey[0] : theme.palette.primary.light)};
    border-color: ${({ theme }) => theme.palette.grey[100]};
  }

  &:disabled {
    opacity: 0.2;
  }
`;
const Chevron = styled(Icon) `
  fill: ${({ theme }) => theme.palette.grey[400]};
  padding: 2px;
  max-width: 400px;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
const TextTitle = styled('span') `
  margin-left: ${({ theme }) => theme.spacing(1)};
  font-size: 10px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
const TextContent = styled('span') `
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  white-space: nowrap;
`;
const StyledIconBackground = styled(IconBackground) `
  background-color: transparent;
`;
const StyledImg = styled('img') `
  width: 28px;
  height: 28px;
  object-fit: contain;
`;
const IconButton = ({ variant, title, iconUrl, text, onClick, chevron, innerRef, disabled, size = 'sm', }) => {
    return (_jsxs(StyledButton, { variant: variant, onClick: onClick, ref: innerRef, disabled: disabled, buttonsize: size, children: [_jsxs(Flex, { flexDirection: "row", align: "center", gap: 0, children: [_jsx(StyledIconBackground, { size: size, children: iconUrl && _jsx(StyledImg, { src: iconUrl }) }), _jsxs(Flex, { flexDirection: "column", align: "flex-start", gap: 0, children: [title && _jsx(TextTitle, { children: title }), text && _jsx(TextContent, { children: text })] })] }), chevron === 'down' && _jsx(Chevron, { icon: "chevrondown", width: 16, height: 16 }), chevron === 'right' && _jsx(Chevron, { icon: "chevronright", width: 16, height: 16 })] }));
};
export default IconButton;
