import { ErrorWithCause } from '@helpers';
import axios from 'axios';
const deleteUserLinkedService = async (id) => {
    var _a;
    try {
        const response = await axios.delete(`/api/user/v1/linked-service/${id}`);
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default deleteUserLinkedService;
