import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { captureException } from '@sentry/react';
import GenericModal from '../../../GenericModal';
import Button from "@components/Buttons/Button";
import { ContentCarousel } from '../../../../UI';
import { ClaimEntry, EnterPostcode, WatchVideo, StepHeadings } from './components';
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: 'Montserrat',
    },
    videoModalBanner: {
        '& img': {
            minHeight: '160px',
            maxHeight: '240px',
            maxWidth: '100%',
            objectFit: 'cover',
        },
    },
    videoModalContent: {
        padding: '0',
        textAlign: 'center',
        '& h3': {
            fontSize: '26px',
            fontWeight: 'bold',
        },
        '& p': {
            color: '#1D2128',
            fontSize: '12px',
            fontWeight: '500',
            margin: '25px 0',
            lineHeight: '2.25em',
        },
        '& .textField': {
            marginBottom: '15px',
            textAlign: 'left',
            width: '100%',
            '& .input-group': {
                width: '100%',
                '& input': {
                    backgroundColor: '#f9f9f9',
                    borderRadius: '4px',
                },
            },
            '& .helperText.error p': {
                color: '#e72020',
                fontSize: '13px',
                margin: '4px 0 10px',
                textAlign: 'left',
            },
        },
        '& .buttonGroup': {
            display: 'inline-flex',
            width: '100%',
            '& button': {
                width: '50%',
                '& + button': {
                    marginLeft: '15px',
                },
            },
        },
        '& .modalPadding': {
            padding: theme.spacing(0, 6),
        },
    },
    divider: {
        margin: '14px 0',
    },
    carousel: {
        padding: theme.spacing(4, 0),
    },
    actionsWrapper: {
        marginBottom: theme.spacing(6),
    },
}));
export const VideoPostcodeModal = ({ entry, show, className, handleSuccess, handleClose, tournamentBanner, }) => {
    const { classes, cx } = useStyles();
    const [stepIndex, setStepIndex] = useState(0);
    const [watched, setWatched] = useState(false);
    const [postcodeValid, setPostcodeValid] = useState(false);
    const [showError, setShowError] = useState(false);
    const [postCodeValue, setPostCodeValue] = useState('');
    const [showAdvertisingConsent, setShowAdvertisingConsent] = useState(true);
    const [advertisementConsent, setAdvertisementConsent] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        const { value } = e.currentTarget;
        const isPostcodeValid = /^([\d]{5})$/i.test(value.toLowerCase());
        setPostCodeValue(value);
        setPostcodeValid(isPostcodeValid);
        setShowError(false);
    };
    const handleBlur = () => {
        // Regext to test for 4 or 5 digits as per US zipcodes
        const isPostcodeValid = /^([\d]{5})$/i.test(postCodeValue.toLowerCase());
        setPostcodeValid(isPostcodeValid);
        setShowError(true);
    };
    const handleVideoFinished = () => {
        setWatched(true);
    };
    useEffect(() => {
        var _a;
        const showConsent = ((_a = entry.instance_data) === null || _a === void 0 ? void 0 : _a.show_consent) !== undefined ? entry.instance_data.show_consent : true;
        setShowAdvertisingConsent(showConsent);
    }, [setShowAdvertisingConsent, entry]);
    const canEnter = () => {
        if (!showAdvertisingConsent) {
            return watched && !!postCodeValue && postcodeValid;
        }
        return watched && !!postCodeValue && postcodeValid && advertisementConsent;
    };
    const handleEnterClick = async () => {
        if (canEnter()) {
            setLoading(true);
            const requestName = 'user_change_zip';
            try {
                await axios.post('/api/user/change_zip/v1', { zip: postCodeValue });
                handleSuccess({ watched, postCodeValue, advertisementConsent });
            }
            catch (err) {
                setLoading(false);
                captureException(err, {
                    extra: {
                        request: requestName,
                        zip: postCodeValue,
                    },
                });
            }
        }
    };
    const buttonText = stepIndex === 0 ? 'Continue' : 'Enter Tournament';
    const helperText = showError && !postcodeValid ? 'Please enter a valid zip/post code ' : '';
    const stepHeadings = [
        { heading: 'Watch a Video', valid: watched },
        {
            heading: 'Zip Code',
            valid: canEnter(),
        },
        {
            heading: 'Claim Entry',
            valid: canEnter(),
        },
    ];
    const lastStep = stepHeadings.length - 1;
    return (_jsx(GenericModal, { className: cx(className), size: "large", show: show, onClose: handleClose, "data-testid": "video postcode modal", children: _jsxs("div", { className: classes.container, children: [_jsx("div", { className: classes.videoModalBanner, children: _jsx("img", { src: tournamentBanner, alt: "" }) }), _jsx(StepHeadings, { headings: stepHeadings, stepIndex: stepIndex, setStepIndex: setStepIndex }), _jsxs("div", { className: classes.videoModalContent, children: [_jsxs(ContentCarousel, { name: "video-postcode-modal", currentStep: stepIndex, className: classes.carousel, children: [_jsx(WatchVideo, { videoId: entry.instance_data.video_id, handleVideoFinished: handleVideoFinished }), _jsx(EnterPostcode, { handleBlur: handleBlur, handleChange: handleChange, helperText: helperText, postCodeValue: postCodeValue, postcodeValid: postcodeValid, showError: showError, setAdvertisementConsent: setAdvertisementConsent, showAdvertisingConsent: showAdvertisingConsent }), _jsx(ClaimEntry, {})] }), _jsxs("div", { className: cx(classes.actionsWrapper, 'modalPadding'), children: [stepIndex !== lastStep && (_jsxs("div", { className: "buttonGroup", children: [_jsx(Button, { type: "button", variant: "brand", size: "md", onClick: () => setStepIndex(stepIndex + 1), disable: !stepHeadings[stepIndex].valid, label: buttonText, children: buttonText }), _jsx(Button, { type: "button", size: "md", variant: "gray", onClick: handleClose, children: "Cancel" })] })), stepIndex === lastStep && (_jsx(Button, { type: "button", variant: "brand", size: "md", block: true, onClick: handleEnterClick, disable: loading, label: buttonText, children: "Claim your entry" }))] })] })] }) }));
};
export default VideoPostcodeModal;
