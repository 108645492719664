import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { captureException } from '@sentry/react';
import { TextInput } from '@components';
import Button from "@components/Buttons/Button";
import { ModalContent } from '@components/Modals/components';
import addSocialMediaAccount from '@helpers/SocialMedia/addSocialMediaAccount';
/**
 * This is one of the steps in the add social media account modal flow
 */
const ConnectYouTubeAccount = ({ existingUrl, onSuccess, onError }) => {
    const inputFieldId = 'youtube-url-field';
    const [url, setUrl] = useState(existingUrl || '');
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setUrl(e.target.value);
        setErrorMessage('');
        setHasError(false);
    };
    const handleAddYouTube = async () => {
        var _a, _b, _c, _d;
        if (!url) {
            setErrorMessage('Please enter a valid URL');
            setHasError(true);
        }
        else {
            try {
                setLoading(true);
                const response = await addSocialMediaAccount('youtube', {
                    url,
                });
                if (onSuccess && response) {
                    setLoading(false);
                    onSuccess(response === null || response === void 0 ? void 0 : response.data);
                }
                else if (onError) {
                    setLoading(false);
                    onError(_jsxs(_Fragment, { children: ["We are having trouble adding your ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " account. Please make sure it's a valid ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " channel url"] }));
                }
            }
            catch (e) {
                setLoading(false);
                captureException(e, {
                    extra: {
                        component: 'ConnectYouTubeAccount',
                        url,
                    },
                });
                if (onError) {
                    onError(_jsxs(_Fragment, { children: ["We are having trouble adding your ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " account. Please make sure it's a valid ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " channel url.", ' ', ((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error)
                                ? `Error code: ${(_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error}`
                                : ''] }));
                }
            }
        }
    };
    return (_jsxs(ModalContent, { children: [_jsxs("section", { children: [_jsxs("h3", { id: "connect-youtube-heading", children: ["Add ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " channel"] }), _jsxs("p", { children: ["Enter your ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " channel URL"] }), _jsx(TextInput, { id: inputFieldId, "data-ignore": true, placeholder: "i.e. https://www.youtube.com/channel/channelidasdf", onChange: handleChange, error: hasError, errorMessage: errorMessage, value: url, "aria-labelledby": "connect-youtube-heading" })] }), _jsxs("section", { children: [_jsxs("p", { children: ["Examples of valid ", _jsx("span", { "data-ignore": true, children: "YouTube" }), " channel URLs"] }), _jsxs("ul", { children: [_jsx("li", { children: "https://youtube.com/channel/UCSzCC0eFK..." }), _jsx("li", { children: "https://youtube.com/user/repeatgg" })] })] }), _jsx(Button, { type: "button", onClick: handleAddYouTube, size: "lg", variant: "palette", paletteName: "youtube", loading: loading, children: _jsxs("span", { children: ["Connect your ", _jsx("span", { "data-ignore": true, children: "YouTube" })] }) })] }));
};
export default ConnectYouTubeAccount;
