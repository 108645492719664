import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CheckboxField, TextField } from '../../../../../Fields';
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: 'Montserrat',
    },
    spacing: {
        margin: theme.spacing(3, 0),
    },
}));
function EnterPostcode({ handleChange, handleBlur, postCodeValue, postcodeValid, showError, helperText, setAdvertisementConsent, showAdvertisingConsent, }) {
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.container, children: [_jsxs("div", { className: "modalPadding", children: [_jsx("h3", { children: "One more thing!" }), _jsx("p", { children: "Because this tournament is for a specific region, we need your Zip/Post Code in order to validate that you're in the right region" })] }), _jsx(Divider, { light: true, className: classes.spacing }), _jsx("div", { className: "modalPadding", children: _jsx(TextField, { name: "postcode", onChange: handleChange, onBlur: handleBlur, value: postCodeValue, label: "Enter your Zip/Post code", placeholder: "Zip/Post code", hasError: !postcodeValid && showError, helperText: helperText }) }), showAdvertisingConsent && (_jsxs(_Fragment, { children: [_jsx(Divider, { light: true, className: classes.spacing }), _jsx("div", { className: "modalPadding", children: _jsx(CheckboxField, { label: "I accept that entering this tournament authorizes Repeat to share my details with the sponsor of this tournament.", onChange: (e) => setAdvertisementConsent(e.currentTarget.checked), value: false, name: "agreeToTerms" }) })] })), _jsx(Divider, { light: true, className: classes.spacing })] }));
}
export default EnterPostcode;
