import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NavLink } from 'react-router-dom';
const useStyles = makeStyles()((theme) => ({
    // below style does not work
    selected: {
        color: `${theme.palette.primary.main} !important`,
        background: 'transparent !important',
        [theme.breakpoints.up('md')]: {
            '&:after': {
                content: "''",
                display: 'block',
                position: 'absolute',
                height: '3px',
                bottom: '0',
                left: '0',
                width: '100%',
                background: theme.palette.primary.main,
            },
        },
        [theme.breakpoints.down('md')]: {
            borderRight: `6px solid ${theme.palette.primary.main} !important`,
            '& i, & span': {
                color: theme.palette.primary.main,
            },
        },
    },
    caret: {
        marginLeft: '10px',
    },
}));
const NavItem = ({ href = '', to = '', icon, linkText, className, iconStyles, button = false, selected = false, downCaret = false, ...props }) => {
    const { classes, cx } = useStyles();
    const Content = () => {
        return (_jsxs(_Fragment, { children: [icon && typeof icon === 'string' && (_jsx(ListItemIcon, { classes: { root: iconStyles && iconStyles }, "data-testid": "nav item icon", children: _jsx("i", { className: `fas fa-${icon}` }) })), icon && typeof icon !== 'string' && (_jsx(ListItemIcon, { classes: { root: iconStyles && iconStyles }, "data-testid": "nav item icon", children: icon })), _jsx(ListItemText, { primary: linkText }), downCaret && _jsx("i", { className: cx('fa fa-caret-down', classes.caret), "aria-hidden": "true" })] }));
    };
    if (button) {
        return (_jsx(ListItem, { button: true, ...props, classes: { selected: classes.selected, root: className && className }, children: _jsx(Content, {}) }));
    }
    if (href) {
        return (_jsx(ListItem, { component: "a", ...props, href: href, selected: selected, classes: { selected: classes.selected, root: className && className }, children: _jsx(Content, {}) }));
    }
    if (to || (!href && !button)) {
        return (_jsx(ListItem, { component: NavLink, to: to, activeClassName: "selected", ...props, exact: true, classes: { selected: classes.selected, root: className && className }, children: _jsx(Content, {}) }));
    }
    return _jsx(_Fragment, {});
};
export default NavItem;
