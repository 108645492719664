import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect } from 'react';
import { useQuery, useQueryClient, } from 'react-query';
import { captureException } from '@sentry/react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { clearEventContext, setEventContext } from '../components/Analytics/doTrackEvent';
import ErrorWithCause from '../helpers/ErrorWithCause';
import signOut from '../helpers/Auth/signOut';
import useSyncFirebaseState from '../Hooks/API/useSyncFirebaseState';
const UserStateContext = createContext(undefined);
const getUserDetails = async () => {
    try {
        const response = await axios.get('/api/user-details/v1');
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
const useGetUserDetails = () => {
    return useQuery('userDetails', getUserDetails, { suspense: true });
};
const UserProvider = ({ children }) => {
    var _a, _b, _c, _d, _e, _f;
    const auth = getAuth();
    const { data, isLoading, error, isFetching, refetch } = useGetUserDetails();
    useSyncFirebaseState(data === null || data === void 0 ? void 0 : data.userDetails).catch((e) => {
        const err = new ErrorWithCause(e);
        captureException(e, { extra: { function: 'useSyncFirebaseState' } });
        throw err;
    });
    // userHasGame function is added to the Provider value instead of the state
    const newState = {
        userDetails: (_a = data === null || data === void 0 ? void 0 : data.userDetails) !== null && _a !== void 0 ? _a : null,
        userProfile: (_b = data === null || data === void 0 ? void 0 : data.userProfile) !== null && _b !== void 0 ? _b : null,
        userGames: (_c = data === null || data === void 0 ? void 0 : data.userGames) !== null && _c !== void 0 ? _c : null,
        userFlags: (_d = data === null || data === void 0 ? void 0 : data.userFlags) !== null && _d !== void 0 ? _d : null,
        isLoading,
        isFetching,
        error: error && {
            error: error.message,
            errorCode: error.name,
        },
        authenticated: (_e = data === null || data === void 0 ? void 0 : data.authenticated) !== null && _e !== void 0 ? _e : false,
        registrationNotComplete: (_f = data === null || data === void 0 ? void 0 : data.registrationNotComplete) !== null && _f !== void 0 ? _f : false,
        refetch,
    };
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user === null && newState.authenticated && window.location.href !== '/logout') {
                await signOut().catch((err) => captureException(err));
                refetch().catch((e) => {
                    const err = new ErrorWithCause(e);
                    captureException(e, { extra: { function: 'unsubscribe', file: 'UserContext' } });
                    throw err;
                });
            }
        });
        return unsubscribe;
    }, []);
    // Update analytics when user changes
    useEffect(() => {
        if (!isLoading && !isFetching && window.Indicative) {
            if (newState.userDetails) {
                setEventContext({
                    ...newState.userDetails.segments,
                    userDateCreated: newState.userDetails.dateCreated,
                });
            }
            else {
                clearEventContext();
            }
        }
    }, [isLoading, isFetching]);
    const userHasGame = useCallback((gameKey) => { var _a, _b, _c; return ((_c = (_b = (_a = newState.userGames) === null || _a === void 0 ? void 0 : _a[gameKey]) === null || _b === void 0 ? void 0 : _b.regions) !== null && _c !== void 0 ? _c : []).length > 0; }, [newState.userGames]);
    return (_jsx(UserStateContext.Provider, { value: {
            ...newState,
            userHasGame,
        }, children: children }));
};
const useUserState = () => {
    const context = useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return context;
};
const updateUserDetailsInCache = (queryClient, userDetails) => {
    queryClient.setQueryData('userDetails', (prevState) => {
        const nextState = prevState !== null && prevState !== void 0 ? prevState : {
            userDetails: null,
            userProfile: null,
            userGames: null,
            userFlags: null,
            authenticated: false,
            registrationNotComplete: false,
        };
        if (nextState.userDetails) {
            nextState.userDetails = {
                ...nextState.userDetails,
                ...userDetails,
                firsts: {
                    ...nextState.userDetails.firsts,
                    ...userDetails.firsts,
                },
            };
        }
        return nextState;
    });
};
const useUpdateUserState = () => {
    const queryClient = useQueryClient();
    return {
        refresh: () => queryClient.invalidateQueries('userDetails'),
        setLastLogin: () => updateUserDetailsInCache(queryClient, { lastLogin: new Date().toISOString() }),
        setEnteredCustomEntryTournament: () => updateUserDetailsInCache(queryClient, { firsts: { enteredCustomEntryTournament: true } }),
        setUserDetailsCache: (userDetails) => updateUserDetailsInCache(queryClient, userDetails),
    };
};
export { UserProvider, useUserState, useUpdateUserState, UserStateContext };
