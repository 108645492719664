import { useState, useEffect } from 'react';
/**
 * Given an array of items, break it into a nested array of equal sized slices of the original array
 *
 * @param {any[]} arr The array to break into chunks
 * @param {number} chunkSize The size of each chunk
 * @returns {any[][]} The resulting array of chunks from arr
 */
function useChunkedArray(arr, chunkSize) {
    const [chunkedArray, setChunkedArray] = useState([]);
    const chunkify = () => Array.from({ length: Math.ceil(arr.length / chunkSize) }, (_, i) => arr.slice(i * chunkSize, i * chunkSize + chunkSize));
    useEffect(() => {
        if (arr) {
            setChunkedArray(chunkify());
        }
    }, [JSON.stringify(arr)]);
    return chunkedArray;
}
export default useChunkedArray;
