import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import * as Sentry from '@sentry/browser';
import { List, FormControlLabel, ClickAwayListener, Popper, Fade, Paper, Button, FormGroup, useTheme, useMediaQuery, Radio, } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CaretDown from "@components/Icons/CaretDown";
import { useLocalAppContext } from '@context/LocalAppContext';
import { SupportedLanguages } from '@Types';
const useStyles = makeStyles()((theme) => ({
    popper: {
        marginTop: '0px',
        zIndex: '9999999 !important', // 1 more 9 than GenericModal
    },
    label: {
        justifyContent: 'space-between',
    },
    groupWrapper: {
        paddingRight: '15px',
        width: '190px', // helps the popper calculate the correct position
    },
    languageSelectWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: theme.spacing(1),
        },
    },
    button: {
        padding: theme.spacing(0.5, 1),
        border: '1px solid #E4E4E4',
        color: theme.palette.grey[600],
        fontSize: '11px',
        fontWeight: 600,
        '& span': {
            margin: '0',
        },
    },
    buttonStartIcon: {
        '& img': {
            width: '19px',
            marginRight: theme.spacing(0.5),
        },
    },
    buttonEndIcon: {
        '& i': {
            marginLeft: theme.spacing(2),
            color: theme.palette.grey[200],
        },
        '@media (max-width: 400px)': {
            display: 'none',
        },
    },
    caret: {
        fill: theme.palette.grey[200],
        marginLeft: theme.spacing(2),
        transition: 'transform 0.2s, fill 0.2s',
        width: '10px',
        height: '20px',
        '&.rotate': {
            transform: 'rotate(180deg)',
            fill: theme.palette.grey[900],
        },
    },
}));
/*
The language Menu is for users to the range of international languages we support
*/
const LanguageMenu = ({ className, onChange }) => {
    const { classes, cx } = useStyles();
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const { selectedLanguage, setSelectedLanguage } = useLocalAppContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleLanguageClick = (event) => {
        setLanguageAnchorEl(event.currentTarget);
        setShowLanguageMenu(!showLanguageMenu);
        Sentry.addBreadcrumb({
            category: 'language',
            message: `Language selected: ${event.currentTarget}`,
            level: Sentry.Severity.Info,
        });
    };
    const handleLanguageMenuClose = () => {
        setLanguageAnchorEl(null);
        setShowLanguageMenu(false);
    };
    const handleLanguageSelection = (language) => {
        setSelectedLanguage(language);
        setShowLanguageMenu(false);
        if (onChange) {
            onChange(language);
        }
    };
    return (_jsx(ClickAwayListener, { onClickAway: () => handleLanguageMenuClose(), children: _jsxs("div", { className: className, children: [_jsx(Button, { type: "button", className: classes.button, startIcon: _jsx("img", { src: selectedLanguage.imgUrl, alt: `${selectedLanguage.label} country flag` }), endIcon: _jsx(CaretDown, { className: cx(classes.caret, showLanguageMenu && 'rotate') }), "aria-controls": "simple-menu", "aria-haspopup": "true", onClick: handleLanguageClick, "data-testid": "language-menu-button", classes: {
                        startIcon: classes.buttonStartIcon,
                        endIcon: classes.buttonEndIcon,
                    }, children: isMobile ? '' : selectedLanguage.abbrev }), showLanguageMenu && (_jsx(Popper, { placement: "bottom-end", transition: true, anchorEl: languageAnchorEl, open: showLanguageMenu, className: classes.popper, popperOptions: { strategy: 'fixed' }, children: ({ TransitionProps }) => (_jsx(Fade, { ...TransitionProps, timeout: 350, children: _jsx(Paper, { square: true, children: _jsx(List, { component: "nav", "aria-label": "language menu", "data-testid": "language-menu", children: _jsx(FormGroup, { "aria-label": "position", classes: { root: classes.groupWrapper }, children: SupportedLanguages.map((language) => (_jsx(FormControlLabel, { value: language.code, control: _jsx(Radio, { color: "primary", checked: language.code === selectedLanguage.code }), label: _jsxs("div", { className: classes.languageSelectWrapper, children: [_jsx("img", { src: language.imgUrl, alt: `${language.country} flag` }), " ", language.label] }), labelPlacement: "start", onClick: () => handleLanguageSelection(language), classes: {
                                            labelPlacementStart: classes.label,
                                        } }, language.code))) }) }) }) })) }))] }) }));
};
export default LanguageMenu;
