import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import classnames from 'classnames';
import CoreButton from "@components/Buttons/CoreButton";
import useCountdown from '@components/Hooks/useCountdown';
import GenericModal from '../GenericModal';
import ModalImageHeader from '../components/ModalImageHeader';
import ModalTextContent from '../components/ModalTextContent';
import { styled } from '@mui/system';
const Container = styled('div') `
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;

  & video {
    max-height: 154px;
    margin: 55px 0 0;
  }
`;
const DownloadModalBanner = styled(ModalImageHeader) `
  img {
    height: 160px;
  }
`;
const StyledButton = styled(CoreButton) `
  margin: 20px;
  align-self: stretch;
  background: ${(props) => props.buttonColor};
  border: 0px;

  & i {
    margin-right: 10px;
  }
`;
const BodyImage = styled('img') `
  margin-top: 40px;
  width: 154px;
  align-self: center;
`;
export const UrlTimeoutEntryModal = ({ entry, show, className, handleSuccess, handleClose }) => {
    var _a;
    const [nextInteraction, setNextInteraction] = useState('open_link');
    const onTimoutComplete = () => {
        if (nextInteraction === 'url_timeout') {
            setNextInteraction('confirm_entry');
        }
    };
    const countdown = useCountdown({
        startSeconds: 0,
        onCountdownComplete: onTimoutComplete,
    });
    const handleClick = () => {
        var _a;
        setNextInteraction('url_timeout');
        countdown.setRemainingSeconds(Number.parseInt((_a = entry.instance_data.timeoutSeconds) !== null && _a !== void 0 ? _a : '10', 10));
    };
    return (_jsx(GenericModal, { className: classnames(className), size: "medium", show: show, "data-testid": "app download modal", children: _jsxs(Container, { children: [_jsx(DownloadModalBanner, { imageUrl: entry.instance_data.bannerImageUrl, onClose: handleClose, altText: "banner" }), nextInteraction === 'open_link' && (_jsxs(_Fragment, { children: [_jsx(BodyImage, { src: entry.instance_data.bodyImageUrl, alt: "not installed" }), _jsx(ModalTextContent, { title: entry.instance_data.title, paragraph: entry.instance_data.text }), _jsxs(StyledButton, { buttonColor: entry.instance_data.color, to: entry.instance_data.url, onClick: handleClick, children: [_jsx("i", { className: "fa fa-external-link-alt" }), entry.name] })] })), nextInteraction === 'url_timeout' && (_jsxs(_Fragment, { children: [_jsx("video", { autoPlay: true, muted: true, loop: true, "aria-label": "scanning", children: _jsx("source", { src: "/img/browser-extensions/scanning-body.webm" }) }), _jsx(ModalTextContent, { title: (_a = entry.instance_data.verifyingText) !== null && _a !== void 0 ? _a : 'Verifying action', paragraph: "Come back here after you've finished with your other tab" })] })), nextInteraction === 'confirm_entry' && (_jsxs(_Fragment, { children: [_jsx(BodyImage, { src: "/img/browser-extensions/installed-body.png", alt: "not installed" }), _jsx(ModalTextContent, { title: "Congratulations", paragraph: _jsx(_Fragment, { children: "Please claim your free tournament entry!" }) }), _jsx(StyledButton, { buttonColor: entry.instance_data.color, onClick: () => handleSuccess({}), size: "md", children: "Join Tournament" })] }))] }) }));
};
export default UrlTimeoutEntryModal;
