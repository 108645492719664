import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/system';
import GameWordmark from '@components/UI/GameWordmark/GameWordmark';
import { getGamePalette } from '@helpers';
import getTileHeroImageByGameKey from '@helpers/getTileHeroImageByGameKey';
import useGamesList from '@hooks/API/useGamesList';
const shouldForwardProp = (prop) => !['gameKey'].includes(prop);
const GameLogo = styled(GameWordmark) `
  fill: ${({ theme }) => theme.palette.common.white};
  max-height: 40px;
  max-width: 170px;
`;
const buildStackedBackground = (gameKey, theme) => {
    const color = theme.palette[getGamePalette(gameKey)].main;
    const gradient = `linear-gradient(180deg, ${color}00 0%, ${color} 100%)`;
    const hero = `url(${getTileHeroImageByGameKey(gameKey)}) no-repeat bottom center/75%`;
    return `${gradient}, ${hero}, ${color}`;
};
const GameTile = styled('li', { shouldForwardProp }) `
  background: ${({ theme, gameKey }) => buildStackedBackground(gameKey, theme)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  display: flex;
  height: 296px;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2.5)};
  width: 244px;
`;
const Wrapper = styled('div') `
  display: flex;
  margin: auto auto 0;
  overflow-x: hidden;
  position: relative;
  bottom: -60px;
  width: 100%;

  &::before,
  &::after {
    content: '';
    height: 100%;
    position: absolute;
    width: 50px;
    z-index: 1;
  }
  &::before {
    left: 0;
    background: linear-gradient(90deg, #fff 0%, transparent 100%);
  }
  &::after {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, #fff 100%);
  }
`;
const StyledList = styled('ul') `
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-name: sliderMobile;
  animation-timing-function: ease-in-out;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  padding: 0;
  margin: 0;

  @media (prefers-reduced-motion) {
    animation-duration: 20s;
    animation-name: sliderSubduedMobile;
    animation-timing-function: linear;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    animation-duration: 12s;
    animation-name: slider;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) and (prefers-reduced-motion) {
    animation-duration: 20s;
    animation-name: sliderSubdued;
    animation-timing-function: linear;
  }

  @keyframes slider {
    0% {
      transform: translateX(0);
    }
    3% {
      transform: translateX(0);
    }
    13% {
      transform: translateX(
        calc(${({ theme }) => theme.layout.contentMaxWidth}px - ${({ theme }) => theme.spacing(6)} - 100% - 30px)
      );
    }
    25% {
      transform: translateX(
        calc(${({ theme }) => theme.layout.contentMaxWidth}px - ${({ theme }) => theme.spacing(6)} - 100%)
      );
    }
    50% {
      transform: translateX(
        calc(${({ theme }) => theme.layout.contentMaxWidth}px - ${({ theme }) => theme.spacing(6)} - 100%)
      );
    }
    60% {
      transform: translateX(30px);
    }
    72% {
      transform: translateX(0);
    }
  }

  @keyframes sliderMobile {
    0% {
      transform: translateX(0);
    }
    5% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(calc(100vw - ${({ theme }) => theme.spacing(10)} - 100% - 30px));
    }
    50% {
      transform: translateX(calc(100vw - ${({ theme }) => theme.spacing(10)} - 100%));
    }
    55% {
      transform: translateX(calc(100vw - ${({ theme }) => theme.spacing(10)} - 100%));
    }
    95% {
      transform: translateX(30px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes sliderSubdued {
    5% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(
        calc(${({ theme }) => theme.layout.contentMaxWidth}px - ${({ theme }) => theme.spacing(6)} - 100%)
      );
    }
    50% {
      transform: translateX(
        calc(${({ theme }) => theme.layout.contentMaxWidth}px - ${({ theme }) => theme.spacing(6)} - 100%)
      );
    }
    95% {
      transform: translateX(0);
    }
  }

  @keyframes sliderSubduedMobile {
    0% {
      transform: translateX(0);
    }
    5% {
      transform: translateX(0);
    }
    45% {
      transform: translateX(calc(100vw - ${({ theme }) => theme.spacing(10)} - 100%));
    }
    50% {
      transform: translateX(calc(100vw - ${({ theme }) => theme.spacing(10)} - 100%));
    }
    95% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
const GameTileCarousel = ({ className }) => {
    const { allGames, isLoading } = useGamesList();
    if (isLoading) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Wrapper, { className: className, children: _jsx(StyledList, { children: allGames.map((game) => (_jsx(GameTile, { gameKey: game.gameKey, children: _jsx(GameLogo, { gameKey: game.gameKey, height: 40, width: 170 }) }, game.gameKey))) }) }));
};
export default GameTileCarousel;
