function truncateDecimals(number, numDecimals) {
    const re = new RegExp(`^-?\\d+(?:.\\d{0,${numDecimals || -1}})?`);
    const matches = number.toString().match(re);
    return matches && matches.length > 0 ? matches[0] : '';
}
export const compactNumber = (number) => {
    let formattedNumber = `${number}`;
    if (number > 999999) {
        const showDigits = number % 1000000 === 0 ? 0 : 1;
        formattedNumber = `${truncateDecimals(number / 1000000, showDigits)} M`;
    }
    else if (number > 999) {
        const showDigits = number % 1000 === 0 ? 0 : 1;
        formattedNumber = `${truncateDecimals(number / 1000, showDigits)} k`;
    }
    return formattedNumber;
};
export default compactNumber;
