import { getGamePalette } from '@helpers';
const getMenuPalette = (gameKey) => {
    switch (gameKey) {
        case 'marketplace':
            return 'orange';
        case 'home':
            return 'primary';
        default:
            return getGamePalette(gameKey) === 'primary' ? undefined : getGamePalette(gameKey);
    }
};
export default getMenuPalette;
