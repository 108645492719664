import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useUpdateUserState } from '@context/UserContext';
import { isFirebaseAuthError } from '@helpers/Auth';
import { captureMessage } from '@sentry/react';
const useFirebaseAuthRequest = ({ onSuccess, onFail }) => {
    const [loading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const { setLastLogin } = useUpdateUserState();
    const parseErrorFromFirebase = (error) => {
        if (!isFirebaseAuthError(error)) {
            return 'An unknown error has occurred, please try logging in again or contact support if this error message persists';
        }
        switch (error.code) {
            case 'auth/invalid-email':
                return 'Invalid email address entered, please check and try again';
            case 'auth/user-disabled':
                return 'Your account has been disabled';
            case 'auth/requires-recent-login':
                return 'Please log out and log in again in order to perform this action';
            case 'auth/too-many-requests':
                return 'You have made too many unsuccessful attempts. Please wait before trying again, or reset your password';
            case 'auth/user-not-found':
            case 'auth/wrong-password':
            case 'auth/invalid-login-credentials':
            case 'auth/invalid-credential':
                return 'Email or password was incorrect, please check your login details and try again';
            default:
                captureMessage(`Unrecognised Firebase Auth Error code: ${error.code}`);
                return 'An unknown error has occurred, please try logging in again or contact support if this error message persists';
        }
    };
    const handleSuccess = (firebaseId) => {
        setLastLogin();
        if (onSuccess) {
            onSuccess(firebaseId);
        }
        setSuccess(true);
    };
    const handleError = (error) => {
        if (onFail) {
            const errorMessage = parseErrorFromFirebase(error);
            onFail(errorMessage);
        }
        setSuccess(false);
    };
    const onSubmit = async (form) => {
        // Prevent loading twice, or before tokens are available
        if (loading) {
            return;
        }
        setLoading(true);
        const signInWithFirebaseAndRetrieveToken = async () => {
            const userCredential = await firebase.auth().signInWithEmailAndPassword(form.email, form.password);
            const { user } = userCredential;
            let usersFirebaseIdToken = '';
            if (user === null || user === void 0 ? void 0 : user.getIdToken()) {
                usersFirebaseIdToken = await (user === null || user === void 0 ? void 0 : user.getIdToken());
            }
            return usersFirebaseIdToken;
        };
        try {
            const usersFirebaseIdToken = await signInWithFirebaseAndRetrieveToken();
            handleSuccess(usersFirebaseIdToken);
        }
        catch (error) {
            handleError(error);
        }
        finally {
            setLoading(false);
        }
    };
    return [
        {
            loading,
            isSuccess,
        },
        onSubmit,
    ];
};
export default useFirebaseAuthRequest;
