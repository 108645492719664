import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import ModalContent from '@components/Modals/components/ModalContent';
import TextInput from '@components/Fields/TextInput';
import SaveGameDetails from '../SaveGameDetails';
import useTrackEvent from '@hooks/useTrackEvent';
export const ContentConnectLOL = ({ stepName, onSubmit }) => {
    const [summonerName, setSummonerName] = useState('');
    const trackEvent = useTrackEvent();
    const handleChange = (e) => {
        setSummonerName(e.target.value);
    };
    const handleSubmit = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Connect',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onSubmit) {
            onSubmit(summonerName);
        }
    };
    return (_jsxs(ModalContent, { stepName: stepName, verticalAlign: "top", children: [_jsxs("section", { children: [_jsxs("h3", { children: ["Add your ", _jsx("span", { "data-ignore": true, children: "Summoner" }), " name"] }), _jsxs("ol", { children: [_jsxs("li", { children: ["Log into the ", _jsx("span", { "data-ignore": true, children: "League of Legends" }), " game client"] }), _jsxs("li", { children: ["Find your ", _jsx("span", { "data-ignore": true, children: "summoner" }), " name in the top right-hand corner. Note that this is different to your ", _jsx("span", { "data-ignore": true, children: "Riot" }), " username."] }), _jsx("li", { children: "Paste it below" })] }), _jsx(TextInput, { id: "game-lol-summoner-name", "data-ignore": true, placeholder: "Summoner name", value: summonerName, onChange: handleChange, "aria-labelledby": "lol-summoner-name" })] }), _jsx(SaveGameDetails, { lookupValue: summonerName, onClick: handleSubmit })] }));
};
export default ContentConnectLOL;
