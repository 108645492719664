import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useStyles from './useStyles';
const InstallExtensionLink = ({ installURL, entry, setInstallUrlVisited }) => {
    const { classes } = useStyles({ extensionColor: entry.instance_data.color });
    const handleClick = () => {
        setInstallUrlVisited();
    };
    const handleMouseUp = (e) => {
        if (e.button < 2) {
            setInstallUrlVisited();
        }
    };
    return (_jsxs("a", { href: installURL, target: "_blank", rel: "noopener noreferrer", className: classes.actionBtn, onClick: handleClick, onMouseUp: handleMouseUp, children: [_jsxs("span", { children: ["Get ", _jsx("span", { "data-ignore": true, children: entry.instance_data.displayName }), " Extension"] }), _jsx("i", { className: "fa fa-external-link-alt" })] }));
};
export default InstallExtensionLink;
