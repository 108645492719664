import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import axios from 'axios';
import { Grid, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { captureException } from '@sentry/react';
import CoreButton from '../Buttons/CoreButton';
import GenericModal from '../Modals/GenericModal';
import ErrorWithCause from '../../helpers/ErrorWithCause';
const useStyles = makeStyles()((theme) => ({
    container: {
        width: '430px',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6),
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: 600,
    },
    content: {
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '&>h2': {
            fontSize: '26px',
            fontWeight: 700,
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(4),
            color: theme.palette.grey[900],
        },
        '&>p': {
            marginBottom: theme.spacing(4),
            color: '#73787D',
            lineHeight: '23px',
            fontWeight: 500,
            fontSize: '12px',
        },
    },
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        fontSize: '26px',
    },
    inputButton: {
        '& .input-group': {
            width: '100%',
        },
        '& input': {
            height: '50px',
            borderRadius: '4px',
        },
        '& label': {
            position: 'absolute',
            width: '1px',
            height: '1px',
            padding: '0',
            margin: '-1px',
            overflow: 'hidden',
            clip: ' rect(0, 0, 0, 0)',
            whiteSpace: 'nowrap',
            border: 0,
        },
    },
}));
const VerifyEmailModal = ({ show, className, handleClose, action = 'resend' }) => {
    const { classes, cx } = useStyles();
    const [loading, setLoading] = useState(false);
    const handleResendVerification = async () => {
        setLoading(true);
        try {
            await axios.post('/resend_confirm_email');
            handleClose();
        }
        catch (error) {
            const e = new ErrorWithCause(error);
            captureException(e, { extra: { request: 'resend_confirm_email' } });
        }
        setLoading(false);
    };
    return (_jsx(GenericModal, { className: cx(className), size: "medium", show: show, children: _jsxs("div", { className: classes.container, children: [_jsx(IconButton, { "aria-label": "close", onClick: handleClose, className: classes.closeBtn, size: "large", children: _jsx("i", { className: "fal fa-times" }) }), _jsxs("div", { className: classes.content, children: [_jsx("img", { src: "/img/emails/branded-envelope-2.png", alt: "Envelope" }), _jsx("h2", { children: "Check your Email!" }), _jsxs("p", { children: ["You have been sent a verification email from ", _jsx("span", { "data-ignore": true, children: "Repeat" }), ". Please check spam folders if you cannot find it or raise a support ticket", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "here" }), "."] }), _jsx("p", { children: "If you do not verify your email within 2 weeks of creating your account it may be disabled." }), _jsx(Grid, { container: true, spacing: 2, children: _jsxs(Grid, { item: true, xs: 12, children: [action === 'resend' && (_jsx(CoreButton, { disabled: loading, block: true, onClick: handleResendVerification, children: "Resend Verification" })), action === 'close' && (_jsx(CoreButton, { disabled: loading, block: true, onClick: handleClose, children: "Close" }))] }) })] })] }) }));
};
export default VerifyEmailModal;
