import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as React from 'react';
import { ModalContent } from '@components/Modals';
import TextInput from '@components/Fields/TextInput';
import { useAddGameContext } from '../context/AddGameProvider';
import SaveGameDetails from '../SaveGameDetails';
import ABTestingBrancher from '@components/Logical/ABTestingBrancher';
import { useUserState } from '@context/UserContext';
import { useFeature } from 'flagged';
const ContentConnectFortnite = ({ className, stepName, onSubmit }) => {
    const { connectGameService } = useAddGameContext();
    const { userDetails } = useUserState();
    const fortniteABTestEnabled = Boolean(useFeature('test_fortnite_connection_methods'));
    const [epicId, setEpicId] = useState('');
    const handleChange = (e) => {
        setEpicId(e.target.value);
    };
    const macroGroup = userDetails === null || userDetails === void 0 ? void 0 : userDetails.segments.macroGroup;
    const enableTest = Boolean(fortniteABTestEnabled && macroGroup);
    const shouldSeeDisplayName = enableTest && macroGroup === 'B';
    const inputPlaceholderText = shouldSeeDisplayName ? 'Your Fortnite display name' : 'Your Epic account ID';
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(epicId);
        }
    };
    // Clears the username when game service changes.
    useEffect(() => {
        setEpicId('');
    }, [connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey]);
    return (_jsxs(ModalContent, { stepName: stepName, verticalAlign: "top", children: [_jsxs("section", { children: [_jsx("h3", { children: _jsx(ABTestingBrancher, { segment: "macroGroup", options: ['A', 'B'], children: [
                                _jsx(React.Fragment, { children: "Paste your Epic ID" }, "A"),
                                _jsx(React.Fragment, { children: "Paste your Fortnite display name" }, "B"),
                            ], forceOption: enableTest ? undefined : 'A' }) }), _jsxs("ol", { className: className, children: [shouldSeeDisplayName && (_jsx("li", { children: _jsx("strong", { children: "Please enter your display name, if that fails you can enter your Epic ID with the instructions below" }) })), _jsxs("li", { children: ["Go to", ' ', _jsx("a", { href: "https://epicgames.com", target: "_blank", rel: "noreferrer noopenner", "data-ignore": true, children: "epicgames.com" }), ' ', "and login to your ", _jsx("span", { "data-ignore": true, children: "Epic" }), " account."] }), _jsxs("li", { children: ["Click/hover on your username and click \"Account\" or", ' ', _jsx("a", { href: "https://www.epicgames.com/account/personal?productName=epicgames&lang=en", target: "_blank", rel: "noreferrer noopener", children: "go here" })] }), _jsx("li", { children: "Under \"Account ID\" copy the long string that appears after \"ID\"" }), _jsx("li", { children: "Paste the ID below" })] }), _jsx(TextInput, { id: "game-service-epic-id", "data-notranslate": true, placeholder: inputPlaceholderText, value: epicId, onChange: handleChange, "aria-label": "Epic ID" })] }), _jsx(SaveGameDetails, { lookupValue: epicId, onClick: handleSubmit, macroGroup: macroGroup })] }));
};
export default ContentConnectFortnite;
