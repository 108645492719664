import fetchNotifications from '@api/fetchNotifications';
import { useQuery, useQueryClient } from 'react-query';
import { useUserState } from '@context/UserContext';
import { displayMessageFromError } from '@helpers/errorHelper';
import { captureException } from '@sentry/react';
export const recentNotificationKey = 'recentNotifications';
const useFetchNotifications = ({ notificationQueryParams, enabled = true, onSuccess }) => {
    const { authenticated } = useUserState();
    const queryClient = useQueryClient();
    const updateNotificationCount = (totalNew, totalGlobal, totalUnopened) => {
        queryClient.setQueryData(recentNotificationKey, (data) => {
            if (data) {
                return {
                    ...data,
                    pages: data.pages.map((page) => {
                        return {
                            payload: page.payload,
                            meta: {
                                ...page.meta,
                                pagination: {
                                    total: page.meta.pagination.total,
                                },
                                notification: {
                                    ...page.meta.notification,
                                    totalNew,
                                    totalGlobal: totalGlobal !== null && totalGlobal !== void 0 ? totalGlobal : page.meta.notification.totalGlobal,
                                    totalUnopened: totalUnopened !== null && totalUnopened !== void 0 ? totalUnopened : page.meta.notification.totalUnopened,
                                },
                            },
                        };
                    }),
                };
            }
            return data;
        });
    };
    return {
        ...useQuery([recentNotificationKey, { ...notificationQueryParams }], () => fetchNotifications(notificationQueryParams), {
            enabled: authenticated && enabled,
            staleTime: 1000 * 60 * 1,
            onSuccess: (data) => {
                if (onSuccess) {
                    onSuccess(data);
                }
            },
            onError: (error) => {
                captureException(error, {
                    extra: {
                        request: recentNotificationKey,
                        filters: notificationQueryParams,
                        errorMessage: displayMessageFromError(error),
                    },
                });
            },
        }),
        updateNotificationCount,
    };
};
export default useFetchNotifications;
