import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import YouTube from 'react-youtube';
import useCountdown from '@components/Hooks/useCountdown';
import { captureException } from '@sentry/react';
const opts = {
    height: '360',
    width: '100%',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        controls: 0,
    },
};
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: 'Montserrat',
        '& .customEntryVideo': {
            height: '360px',
        },
    },
    videoCountdown: {
        background: '#000',
        color: theme.palette.common.white,
        padding: theme.spacing(1, 2),
        fontSize: '20px',
        textAlign: 'right',
    },
}));
const WatchVideo = ({ videoId, handleVideoFinished }) => {
    const { classes, cx } = useStyles();
    const [isPlaying, setIsPlaying] = useState(false);
    const [remainingDuration, setRemainingDuration] = useState(0);
    const { remainingSeconds, setRemainingSeconds } = useCountdown({
        startSeconds: 0,
    });
    const getCountdownDisplay = (seconds) => {
        const displayMin = Math.floor(seconds / 60);
        const displaySec = seconds % 60;
        return `${displayMin}:${displaySec < 10 ? '0' : ''}${displaySec}`;
    };
    const updateRemaining = (duration, progress) => {
        const remaining = Math.floor(duration - progress);
        setRemainingDuration(remaining);
        return remaining;
    };
    const handlePlay = (data) => {
        const remaining = updateRemaining(data.target.getDuration(), data.target.getCurrentTime());
        setIsPlaying(true);
        setRemainingSeconds(remaining);
    };
    const handlePause = (data) => {
        updateRemaining(data.target.getDuration(), data.target.getCurrentTime());
        setIsPlaying(false);
    };
    const handleVideoWatched = () => {
        setIsPlaying(false);
        setRemainingDuration(0);
        handleVideoFinished();
    };
    const handleError = (data) => {
        updateRemaining(data.target.getDuration(), data.target.getCurrentTime());
        setIsPlaying(false);
        captureException(data);
    };
    return (_jsxs("div", { className: cx(classes.container, 'modalPadding'), children: [_jsx("h3", { children: "Watch Video to Claim Free Entry" }), _jsx("p", { children: "In order to join this Tournament you have to watch a video, and once you finish watching your Free Entry will be available" }), isPlaying && remainingDuration > 0 && (_jsxs("div", { className: classes.videoCountdown, children: [getCountdownDisplay(remainingSeconds), " Remaining"] })), !isPlaying && remainingDuration > 0 && (_jsxs("div", { className: classes.videoCountdown, children: [getCountdownDisplay(remainingDuration), " Remaining"] })), _jsx(YouTube, { opts: opts, videoId: videoId, className: "customEntryVideo", onEnd: handleVideoWatched, onPlay: handlePlay, onPause: handlePause, onError: handleError })] }));
};
export default WatchVideo;
