import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterDuoIcon } from '@components/Icons';
import { styled } from '@mui/system';
import CoreButton from "@components/Buttons/CoreButton";
const FilterButtonStyle = styled(CoreButton) `
  margin-right: ${({ theme }) => theme.spacing(2)} !important;
  border-radius: 40px !important;
  height: 50px;
  padding: ${({ theme }) => theme.spacing(2)} !important;
  white-space: nowrap;
  font-size: 14px !important;
  width: 140px;
  justify-content: flex-start;

  span {
    display: block;
    margin-left: auto;
    padding: ${({ theme }) => theme.spacing(0.5)};
    color: ${({ theme }) => theme.palette.primary.main} !important;
    font-size: 10px;
    background: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  svg {
    fill: #fff;
    width: 17px;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;
const FilterButton = ({ filterCount, onClick }) => {
    return (_jsxs(FilterButtonStyle, { onClick: onClick, children: [_jsx(FilterDuoIcon, {}), " Filters ", _jsx("span", { "data-ignore": true, children: filterCount })] }));
};
export default FilterButton;
