import { useUserState } from '@context/UserContext';
import axios from 'axios';
/**
 * Used to update json_flags in the user table
 *
 * @returns {Object} containing setFlags which is used to update provided flag values
 */
const useSetUserFlags = () => {
    const { authenticated } = useUserState();
    return {
        setFlags: async (flags) => {
            // If a user is logged out, don't trigger DB updates.
            if (!authenticated) {
                return null;
            }
            return axios.request({
                method: 'PATCH',
                url: '/api/user/flag/v1',
                data: flags,
            });
        },
    };
};
export default useSetUserFlags;
