import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import useAppState from '@hooks/API/useAppState';
import { getTournamentBoxBannerImage, getTournamentColorPaletteName, getTournamentDefaultBanner, getTournamentState, } from '@domain/Tournament/helpers';
import useGame from '@hooks/API/useGame';
import useTournamentDetailsPageUrl from '@hooks/useTournamentDetailsPageUrl';
import { doTrackEvent, extractTournamentAnalyticsData } from '@components/Analytics/doTrackEvent';
import { useMemo } from 'react';
import TournamentContainer from '@domain/TournamentV2/TournamentTable/styled/TournamentContainer';
import BrandIcon from '@domain/TournamentV2/TournamentTable/styled/BrandIcon';
import TournamentHeading from '@domain/TournamentV2/TournamentTable/styled/TournamentHeading';
import StyledTournamentEligibility from '@domain/TournamentV2/TournamentTable/styled/StyledTournamentEligibility';
import StyledTournamentProgressBar from '@domain/TournamentV2/TournamentTable/styled/StyledTournamentProgressBar';
import StyledRankRangeColumn from '@domain/TournamentV2/TournamentTable/styled/StyledRankRangeColumn';
import StyledTournamentAwardRankCell from '@domain/TournamentV2/TournamentTable/styled/StyledTournamentAwardRankCell';
import StyledTournamentEntryFee from '@domain/TournamentV2/TournamentTable/styled/StyledTournamentEntryFee';
import rightBorder from '@domain/TournamentV2/TournamentTable/styled/RightBorder';
import Flex from '@components/Layout/Flex';
import useGameModes from '@hooks/API/useGameModes';
import { Tooltip } from '@mui/material';
import getLeagueOfLegendsGameModes from '@domain/TournamentV2/helpers/getLeagueOfLegendsGameModes';
import { StyledTag, TagContainer } from './style';
import getTournamentTags from './getTournamentTags';
function shouldForwardProp(prop) {
    return prop !== 'paletteName' && prop !== 'oddRow' && prop !== 'gameKey';
}
const StyledTournamentContainer = styled(TournamentContainer, { shouldForwardProp }) `
  grid-template-columns: 35% 20% 5% 10% 15% 15%;

  @media (min-width: 1200px) {
    grid-template-columns: 35% 20% 5% 10% 15% 15%;
  }
`;
const StyledGameModesColumn = styled('div') `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${(props) => rightBorder(props)}
`;
const StyledImage = styled('img') `
  height: 24px;
  margin: 3px;
`;
const LeagueOfLegendsRow = ({ tournament, section, listIndex }) => {
    var _a, _b, _c, _d, _e;
    const appState = useAppState();
    // eslint-disable-next-line eqeqeq
    const hasEntered = tournament.user_entry != undefined;
    const gameKey = tournament.score_template.game.game_key;
    const paletteName = getTournamentColorPaletteName(tournament);
    const tournamentState = getTournamentState(tournament, appState.registrationEndingMinutes);
    const gameData = useGame(gameKey);
    const detailsLink = useTournamentDetailsPageUrl(tournament.id, gameData);
    const allowedGameModes = ((_b = (_a = tournament.score_template.json.gameModes) !== null && _a !== void 0 ? _a : tournament.score_template.game_modes) !== null && _b !== void 0 ? _b : []).map((mode) => `${mode}`);
    const onRowClick = () => {
        doTrackEvent({
            ...extractTournamentAnalyticsData(tournament, appState.registrationEndingMinutes),
            type: 'open_tournament',
            section,
            listIndex: listIndex.toString(),
            viewType: 'list',
        });
    };
    const tournamentImageUrl = (_c = getTournamentBoxBannerImage(tournament)) !== null && _c !== void 0 ? _c : getTournamentDefaultBanner(gameKey, tournament, true);
    const tags = useMemo(() => {
        return getTournamentTags(tournament, gameData, true);
    }, [tournament, gameData]);
    const { gameModes } = useGameModes(gameKey);
    const { availableGameModes } = getLeagueOfLegendsGameModes(gameModes, allowedGameModes);
    return (_jsxs(StyledTournamentContainer, { oddRow: listIndex % 2 !== 0, paletteName: paletteName, to: detailsLink, onClick: onRowClick, "data-testid": "tournament row", children: [_jsxs(Flex, { children: [_jsx(BrandIcon, { "data-notranslate": true, src: tournamentImageUrl, alt: (_e = (_d = tournament.brand) === null || _d === void 0 ? void 0 : _d.name) !== null && _e !== void 0 ? _e : `Brand logo of ${tournament.name}` }), _jsx(TournamentHeading, { paletteName: paletteName, children: _jsxs("div", { children: [_jsx("p", { "data-notranslate": true, children: tournament.name }), _jsx(TagContainer, { children: tags.map((tag) => {
                                        var _a;
                                        return (_jsx(StyledTag, { tagData: tag }, (_a = tag.icon) !== null && _a !== void 0 ? _a : tag.label));
                                    }) })] }) })] }), hasEntered ? (_jsx(StyledTournamentEligibility, { tournament: tournament, align: "left" })) : (_jsx(StyledTournamentProgressBar, { maxPrizePositions: tournament.maximum_prize_positions, currentEntries: tournament.entries_count, maxEntries: tournament.slots, tournamentState: tournamentState })), _jsx(StyledGameModesColumn, { children: availableGameModes.map((gameMode) => (_jsx(Tooltip, { placement: "top", arrow: true, title: gameMode.name, children: _jsx(StyledImage, { src: gameMode.iconUrl, alt: gameMode.name }) }, gameMode.name))) }), _jsx(StyledRankRangeColumn, { lowestRank: tournament.allowed_ranks.lowest_rank, highestRank: tournament.allowed_ranks.highest_rank, iconOnly: true }), _jsx(StyledTournamentAwardRankCell, { withDollarSymbol: true, tournament: tournament, align: "left", withCaption: true }), _jsx(StyledTournamentEntryFee, { withDollarSymbol: true, align: "right", tournament: tournament })] }));
};
export default LeagueOfLegendsRow;
