import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMutation } from 'react-query';
import { useState } from 'react';
import resendConfirmEmail from '@api/resendConfirmEmail';
import { isAxiosError } from '@helpers';
import { useHistory } from 'react-router-dom';
import useLocalStorage from '@components/Hooks/useLocalStorage';
const useResendConfirmEmail = () => {
    const [errorMessage, setErrorMessage] = useState();
    const history = useHistory();
    const [completeRegistrationRedirectUrl] = useLocalStorage('complete-registration-initial-route', '/compete');
    const { mutate, isLoading, isError } = useMutation({
        mutationFn: () => {
            setErrorMessage(undefined);
            return resendConfirmEmail();
        },
        onError: (error) => {
            var _a;
            if (isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.result) === 'You have already been verified') {
                setErrorMessage('Email address has been verified, this page will automatically reload in 5 seconds, or you can manually reload this page');
                setTimeout(() => {
                    history.push(completeRegistrationRedirectUrl);
                }, 5000);
            }
            else {
                setErrorMessage(_jsxs(_Fragment, { children: ["An error has occurred. Please check your spam/junk, or", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }), ' ', "for assistance."] }));
            }
        },
    });
    return { resendConfirmEmail: mutate, isLoading, isError, errorMessage };
};
export default useResendConfirmEmail;
