import { jsx as _jsx } from "react/jsx-runtime";
import GameIcon from '@components/Icons/GameIcon';
import getGamePalette from '@helpers/getGamePalette';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import { styled } from '@mui/material/styles';
import { matchPath, useLocation } from 'react-router-dom';
import CoreButton from './CoreButton';
export const StyledCoreButton = styled(CoreButton) `
  height: 48px !important;
  width: 48px !important;
  padding: 0 !important;
  border: 0 !important;

  svg {
    width: 20px;
    fill: ${({ theme }) => theme.palette.grey[400]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light} !important;
    svg {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
  }

  &.active {
    background-color: ${({ theme, paletteName }) => paletteName ? theme.palette[paletteName].main : theme.palette.primary.main} !important;

    svg {
      fill: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
const GameIconButton = ({ game }) => {
    const { pathname } = useLocation();
    const { platformSlug } = useSelectedPlatform();
    const selectedPlatformSlug = platformSlug ? `${platformSlug}/` : '';
    const isSelected = matchPath(pathname, {
        path: [`/${game === null || game === void 0 ? void 0 : game.slug}`, `/${platformSlug}/${game === null || game === void 0 ? void 0 : game.slug}`],
    });
    return (_jsx(StyledCoreButton, { to: `/${selectedPlatformSlug}${game.slug}`, variant: "grey", appearance: "subdued", className: isSelected ? 'active' : undefined, paletteName: getGamePalette(game.gameKey), children: _jsx(GameIcon, { game: game.gameKey, title: game.name }) }, `connected-${game.gameKey}`));
};
export default GameIconButton;
