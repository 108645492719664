import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { captureException } from '@sentry/react';
import { Paper } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from 'tss-react/mui';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
import ChevronLeft from "@components/Icons/ChevronLeft";
import ErrorMessage from "@components/UI/ErrorMessage";
import SectionContainer from "@components/UI/Section/components/SectionContainer";
import SectionHead from "@components/UI/Section/components/SectionHead";
import SectionHeading from "@components/UI/Section/components/SectionHeading";
import SectionSubheading from "@components/UI/Section/components/SectionSubheading";
import LanguageMenu from '@domain/Header/components/Menus/LanguageMenu';
import { useUpdateUserState } from '@context/UserContext';
import { useLocalAppContext } from '@context/LocalAppContext';
import GenericModal from '../GenericModal';
import LoginPanel from './Components/LoginPanel';
import RegisterPanel from './Components/RegisterPanel';
import AuthMethodSelector from './Components/AuthMethodSelector';
import CoreButton from '@components/Buttons/CoreButton';
import SidelineText from '@components/UI/SidelineText';
import { getAccountLoginSocialMediaTypes } from '@domain/User/constants';
import { authenticateWithFacebook, authenticateWithGoogle, isFirebaseAuthError } from '@helpers/Auth';
import isMobileBrowser from '@helpers/isMobileBrowser';
import isMobileInAppBrowser from '@helpers/isMobileInAppBrowser';
import 'firebase/compat/auth';
import { useFeature } from 'flagged';
import { useHistory } from 'react-router-dom';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import useTrackUserFlow from '@hooks/useTrackUserFlow';
import useTrackEvent from '@hooks/useTrackEvent';
const isMessageError = (obj) => {
    return obj.message !== undefined;
};
const useStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '440px',
        },
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: 600,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerActionsWrapper: {
        display: 'flex',
    },
    headerClose: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    sidelineText: {
        color: theme.palette.grey[400],
        fontSize: '11px',
        margin: 0,
    },
    panelContainer: {
        marginTop: theme.spacing(1),
        fontFamily: theme.typography.fontFamily,
        background: '#fff',
        padding: theme.spacing(3),
    },
    languageMenu: {
        marginRight: theme.spacing(3),
        alignSelf: 'center',
        '& button': {
            background: theme.palette.grey[100],
        },
    },
    noAccountFooter: {
        fontSize: '11px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        color: theme.palette.grey[400],
        paddingBottom: theme.spacing(3),
    },
    createAccountLink: {
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    backButtonWrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    backButton: {
        alignSelf: 'stretch',
        justifyContent: 'start',
        fontWeight: 600,
        color: theme.palette.grey[500],
        fontSize: '12px',
        '& svg': {
            height: '15px',
            fill: theme.palette.grey[200],
            marginRight: theme.spacing(2),
        },
        '& span': {
            textTransform: 'capitalize',
            marginLeft: '4px',
        },
        padding: theme.spacing(1, 3),
    },
    selectAuthBody: { margin: theme.spacing(3, 3, 0, 3) },
    errorText: {
        textAlign: 'center',
    },
}));
const StyledAuthErrorMessage = styled('p') `
  text-align: center;
  font-size: 11px;
`;
const parseErrorFromFirebase = (errorCode) => {
    switch (errorCode) {
        case 'auth/user-disabled':
            return (_jsxs(_Fragment, { children: ["Your account has been disabled, if you believe this is an error please", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" })] }));
        case 'auth/too-many-requests':
            return 'You have made too many unsuccessful attempts. Please wait before trying again, or reset your password';
        case 'auth/user-cancelled':
            return 'Login was cancelled. You may need to accept the required extra permissions in the popup';
        case 'auth/account-exists-with-different-credential':
            return 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.';
        case 'auth/cancelled-popup-request':
            return (_jsxs(_Fragment, { children: ["Complete the authentication in the popup window. The window may be minimized, or on another screen.", _jsx(StyledAuthErrorMessage, { children: _jsx("a", { href: `/content/login-and-signup-troubleshooting`, target: "_blank", rel: "noreferrer noopener", children: "Click here to troubleshoot missing popup window" }) })] }));
        default:
            return (_jsxs(_Fragment, { children: ["unknown error has occurred, please try logging in again or", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }), ' ', "if this error message persists"] }));
    }
};
const AuthenticationError = ({ error }) => {
    let errorMessage = 'An unexpected error occurred. Please refresh the page, or try again later.';
    if (isFirebaseAuthError(error)) {
        errorMessage = parseErrorFromFirebase(error.code);
    }
    else if (isMessageError(error)) {
        errorMessage = error.message;
    }
    return (_jsx(_Fragment, { children: _jsx(ErrorMessage, { textAlign: "center", error: true, children: errorMessage }) }));
};
const AuthenticationModal = ({ show, view, handleSuccess, handleRegistered, handleClose, changeAuthenticationForm, className, }) => {
    const { classes, cx } = useStyles();
    const updateUserState = useUpdateUserState();
    const { setFirebaseSocialAuthUserCredential } = useLocalAppContext();
    const showOAuthPopupBlockedWarning = isMobileBrowser() && isMobileInAppBrowser();
    const [authError, setAuthError] = useState();
    const [isEmailAuthSelected, setIsEmailAuthSelected] = useState(false);
    const showEmailAuth = isEmailAuthSelected || getAccountLoginSocialMediaTypes().length == 0;
    const trackUserFlow = useTrackUserFlow();
    const { closeModal } = useLocalAppContext();
    const trackEvent = useTrackEvent();
    const history = useHistory();
    const isFacebookLoginDisabled = !!useFeature('disable_facebook_login');
    const isGoogleLoginDisabled = !!useFeature('disable_google_login');
    const isPsnLoginDisabled = !!useFeature('disable_psn_login');
    const eventName = 'account registration';
    const changeViewText = view === 'login' ? 'Create Account' : 'Log In';
    // track registration initialization
    useEffect(() => {
        if (view === 'register') {
            trackUserFlow('account_register_start');
        }
    }, [view, trackUserFlow]);
    const onLoginSuccess = () => {
        updateUserState.refresh();
        if (handleSuccess) {
            handleSuccess();
        }
        closeModal();
    };
    const toggleFormType = () => {
        setAuthError(undefined);
        changeAuthenticationForm(view === 'login' ? 'register' : 'login');
        setIsEmailAuthSelected(false);
        trackEvent({
            type: 'user_engagement',
            data: {
                eventName: 'select text link',
                label: changeViewText,
                moduleName: 'Text link',
                position: 1,
            },
        });
    };
    const onRegisterSuccess = (username) => {
        closeModal();
        updateUserState.refresh();
        handleRegistered(username);
    };
    const goToUrlAndCloseModal = (value) => {
        history.push(`/user/reset?${value}_auth_issue=true`);
        closeModal();
    };
    const onSelectAuthMethod = async (value) => {
        Sentry.addBreadcrumb({
            category: 'auth',
            message: `${view} method selected: ${value}`,
            level: Sentry.Severity.Info,
        });
        trackHotjarEvent(`Start ${view} ${value}`);
        try {
            switch (value) {
                case 'Email': {
                    setIsEmailAuthSelected(true);
                    trackEvent({
                        type: 'account_registration',
                        data: {
                            eventName,
                            label: 'Sign Up with Email',
                            moduleName: 'Button',
                            position: 4,
                        },
                    });
                    break;
                }
                case 'facebook': {
                    if (isFacebookLoginDisabled) {
                        goToUrlAndCloseModal(value);
                        return;
                    }
                    trackEvent({
                        type: 'account_registration',
                        data: {
                            eventName,
                            label: 'Sign Up with Facebook',
                            moduleName: 'Button',
                            position: 3,
                        },
                    });
                    const result = await authenticateWithFacebook({
                        authenticationAction: view,
                        scopes: ['user_birthday', 'user_location', 'email'],
                    });
                    setFirebaseSocialAuthUserCredential(result);
                    onLoginSuccess();
                    break;
                }
                case 'google': {
                    if (isGoogleLoginDisabled) {
                        goToUrlAndCloseModal(value);
                        return;
                    }
                    trackEvent({
                        type: 'account_registration',
                        data: {
                            eventName,
                            label: 'Sign Up with Google',
                            moduleName: 'Button',
                            position: 2,
                        },
                    });
                    const result = await authenticateWithGoogle({
                        authenticationAction: view,
                        scopes: view === 'register' // Request extra scopes if the user is signing up, so that we can read their date of birth
                            ? [
                                'https://www.googleapis.com/auth/userinfo.profile',
                                'https://www.googleapis.com/auth/user.birthday.read',
                                // TODO: Request this once we have a more reliable way of reading the user's country
                                // 'https://www.googleapis.com/auth/user.addresses.read',
                            ]
                            : [],
                    });
                    setFirebaseSocialAuthUserCredential(result);
                    onLoginSuccess();
                    break;
                }
                case 'psn': {
                    if (isPsnLoginDisabled) {
                        goToUrlAndCloseModal(value);
                        return;
                    }
                    trackEvent({
                        type: 'account_registration',
                        data: {
                            eventName,
                            label: 'Sign Up with PlayStation Network',
                            moduleName: 'Button',
                            position: 1,
                        },
                    });
                    window.location.assign(`/user/authenticate/psn?return_path=${window.location.pathname}`);
                    break;
                }
                default: {
                    throw new Error('Authentication method is not supported');
                }
            }
        }
        catch (error) {
            view === 'register' ? trackHotjarEvent('Sign up fail') : trackHotjarEvent('Login fail');
            setAuthError(error);
            if (!isFirebaseAuthError(error) && !isMessageError(error)) {
                captureException(error, {
                    extra: {
                        component: 'AuthenticationModal',
                        onSelectAuthMethod: value,
                        view,
                    },
                });
            }
        }
    };
    // reset modal state on open
    useEffect(() => {
        if (show) {
            setAuthError(undefined);
            setIsEmailAuthSelected(false);
        }
    }, [show, setIsEmailAuthSelected]);
    const handleLanguageChange = (language) => {
        trackEvent({
            type: 'account_registration',
            data: {
                eventName,
                moduleName: 'Button',
                label: language.label,
                position: 0,
            },
        });
    };
    const handleBackToAll = () => {
        setIsEmailAuthSelected(false);
        trackEvent({
            type: 'account_registration',
            data: {
                eventName,
                moduleName: 'Button',
                label: 'Back to all options',
                position: 1,
            },
        });
    };
    const headingText = {
        login: {
            main: 'Login',
            sub: _jsx(_Fragment, { children: "Welcome back to Repeat" }),
        },
        register: {
            main: 'Signup',
            sub: _jsx(_Fragment, { children: "Create new Repeat account" }),
        },
    };
    return (_jsx(GenericModal, { show: show, mobileBottomSheet: true, className: className, children: _jsxs(SectionContainer, { className: classes.container, children: [_jsxs(SectionHead, { bottomBorder: true, className: classes.header, children: [_jsxs("div", { children: [_jsx(SectionHeading, { children: headingText[view].main }), _jsx(SectionSubheading, { children: headingText[view].sub })] }), _jsxs("div", { className: classes.headerActionsWrapper, children: [_jsx(LanguageMenu, { className: classes.languageMenu, onChange: handleLanguageChange }), _jsx("div", { className: classes.headerClose, children: _jsx(CrossCloseIcon, { action: handleClose }) })] })] }), !showEmailAuth && (_jsxs("div", { className: classes.selectAuthBody, children: [authError && _jsx(AuthenticationError, { error: authError }), showOAuthPopupBlockedWarning && (_jsx(ErrorMessage, { textAlign: "center", error: true, errorMessage: "This browser doesn't support all login/signup options available on Repeat. For the best experience, please open the site in an external web browser." })), _jsx(SidelineText, { className: classes.sidelineText, children: `Choose how you want to ${view === 'login' ? 'log in' : 'sign up'}` }), _jsx(AuthMethodSelector, { authAction: view, onSelect: onSelectAuthMethod })] })), showEmailAuth && (_jsxs(_Fragment, { children: [getAccountLoginSocialMediaTypes().length > 0 && (_jsx(Paper, { elevation: 3, square: true, classes: {
                                root: classes.backButtonWrapper,
                            }, children: _jsxs(CoreButton, { appearance: "text", className: classes.backButton, onClick: handleBackToAll, children: [_jsx(ChevronLeft, {}), "Back to all options"] }) })), view === 'login' && (_jsx(LoginPanel, { className: classes.panelContainer, closeAction: handleClose, successAction: onLoginSuccess })), view === 'register' && _jsx(RegisterPanel, { onRegister: onRegisterSuccess, className: classes.panelContainer })] })), _jsxs("div", { className: cx(classes.noAccountFooter), children: [view === 'login' ? _jsx(_Fragment, { children: "Don't have an account?\u00A0" }) : _jsx(_Fragment, { children: "Already have an account?\u00A0" }), _jsx(CoreButton, { appearance: "text", type: "button", onClick: toggleFormType, className: classes.createAccountLink, children: changeViewText })] })] }) }));
};
export default AuthenticationModal;
