import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, _params, classes) => ({
    container: {
        fontFamily: 'Montserrat',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.06)',
        padding: theme.spacing(2, 3),
        [`& .${classes.stepButton}:last-child`]: {
            '&::after': {
                content: '""',
            },
        },
    },
    stepButton: {
        background: 'none',
        border: '0',
        fontWeight: 600,
        fontSize: '14px',
        color: theme.palette.grey[300],
        marginRight: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            '&.current': {
                display: 'block',
            },
        },
        '&::after': {
            fontFamily: "'Font Awesome 5 Pro'",
            fontWeight: 300,
            content: '"\\f054"',
            position: 'relative',
            display: 'inline',
            left: '25px',
        },
        '&.active': {
            color: theme.palette.primary.main,
        },
    },
}));
const StepHeadings = ({ stepIndex, headings, setStepIndex }) => {
    const { classes, cx } = useStyles();
    return (_jsx("div", { className: classes.container, children: headings.map(({ heading, valid }, idx) => (_jsx("button", { className: cx(classes.stepButton, idx <= stepIndex && 'active', idx === stepIndex && 'current'), type: "button", onClick: () => setStepIndex(idx), disabled: !valid, children: heading }, heading))) }));
};
export default StepHeadings;
