import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CoreButton from "@components/Buttons/CoreButton";
import useCountdown from '@components/Hooks/useCountdown';
import { styled } from '@mui/material';
const Wrapper = styled('div') `
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;
const Video = styled('video') `
  max-height: 154px;
`;
const Gif = styled('img') `
  max-height: 154px;
`;
const TextWrapper = styled('div') `
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing(2)};
`;
const Heading = styled('h2') `
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
`;
const Text = styled('p') `
  color: ${({ theme }) => theme.palette.grey[400]};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
`;
const StyledButton = styled(CoreButton) `
  background: ${(props) => props.buttonColor}!important;
  border-color: ${(props) => props.buttonColor}!important;
`;
const Verifying = ({ timeout, onComplete, title, buttonColor }) => {
    const { remainingSeconds } = useCountdown({ startSeconds: timeout, onCountdownComplete: onComplete });
    return (_jsxs(Wrapper, { children: [_jsx(Gif, { src: "/img/entry-types/verifying-static.gif" }), _jsxs(TextWrapper, { children: [_jsx(Heading, { children: title !== null && title !== void 0 ? title : 'Verifying' }), _jsx(Text, { children: "Come back here after you've finished with your other tab" })] }), _jsxs(StyledButton, { buttonColor: buttonColor, onClick: () => { }, disabled: true, block: true, size: "md", variant: "primary", children: ["Continue (", remainingSeconds, ")"] })] }));
};
export default Verifying;
