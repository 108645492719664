import { jsx as _jsx } from "react/jsx-runtime";
import { VideoModal } from './VideoModal';
import { PasswordModal } from './PasswordModal';
import { VideoPostcodeModal } from './components/VideoPostcodeModal';
import { AppDownloadModal } from './AppDownloadModal';
import InstallBrowserExtensionModal from './InstallBrowserExtensionModal/InstallBrowserExtensionModal';
import UrlTimeoutEntryModal from './UrlTimeoutEntryModal';
import LinkedAccountModal from './LinkedAccountModal';
import VideoLinkModal from './VideoLinkModal/VideoLinkModal';
export const CustomEntryTypeModal = ({ entryType, show, handleSuccess, handleClose, tournamentBanner, isLoading, }) => {
    var _a, _b;
    const modalBanner = (_b = (_a = entryType.instance_data) === null || _a === void 0 ? void 0 : _a.bannerImageUrl) !== null && _b !== void 0 ? _b : tournamentBanner;
    switch (entryType.type) {
        case 'video':
            return (_jsx(VideoModal, { entry: entryType, show: show, tournamentBanner: modalBanner, handleSuccess: handleSuccess, handleClose: handleClose }));
        case 'password':
        case 'individual_password':
            return _jsx(PasswordModal, { show: show, handleSubmit: handleSuccess, onClose: handleClose, isLoading: isLoading });
        case 'video_postcode':
            return (_jsx(VideoPostcodeModal, { entry: entryType, show: show, tournamentBanner: modalBanner, handleSuccess: handleSuccess, handleClose: handleClose }));
        case 'video_link':
            return (_jsx(VideoLinkModal, { entry: entryType, show: show, handleSuccess: handleSuccess, handleClose: handleClose, tournamentBanner: modalBanner }));
        case 'app_download':
            return (_jsx(AppDownloadModal, { entry: entryType, show: show, handleSuccess: handleSuccess, handleClose: handleClose, tournamentBanner: modalBanner }));
        case 'install_browser_extension':
            return (_jsx(InstallBrowserExtensionModal, { entry: entryType, show: show, handleSuccess: handleSuccess, handleClose: handleClose }));
        case 'url_timeout':
            return (_jsx(UrlTimeoutEntryModal, { entry: entryType, show: show, handleSuccess: handleSuccess, handleClose: handleClose }));
        case 'linked_account': {
            return (_jsx(LinkedAccountModal, { entry: entryType, show: show, handleSuccess: handleSuccess, handleClose: handleClose }));
        }
        default:
            return null;
    }
};
export default CustomEntryTypeModal;
