import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@components/Buttons/Button";
import { ModalContent, ModalStatusBanner } from '@components/Modals/components';
import { getSocialMediaServiceNameFromType } from '@helpers/SocialMedia';
const ConnectSocialMediaStatus = ({ status, errorMessage = 'An error has occurred while connecting your account.', socialMediaType, onConfirm, onRetry, }) => {
    const statusTitle = status === 'success' ? (_jsxs(_Fragment, { children: [_jsx("span", { "data-ignore": true, children: getSocialMediaServiceNameFromType(socialMediaType) }), " Connected!"] })) : ('Oops, we encountered an error');
    const statusMessage = status === 'success' ? (_jsxs(_Fragment, { children: ["You've successfully connected your\u00A0", _jsx("span", { "data-ignore": true, children: getSocialMediaServiceNameFromType(socialMediaType) }), "\u00A0account to your ", _jsx("span", { "data-ignore": true, children: "Repeat" }), " Profile"] })) : (errorMessage);
    const handleClick = () => {
        if (status === 'success') {
            onConfirm();
        }
        else if (onRetry) {
            onRetry();
        }
    };
    return (_jsxs(ModalContent, { align: "center", children: [_jsx(ModalStatusBanner, { status: status }), _jsx("h2", { children: statusTitle }), _jsx("section", { children: _jsx("p", { children: statusMessage }) }), _jsx(Button, { onClick: handleClick, variant: "palette", size: "lg", paletteName: socialMediaType, children: status === 'success' ? 'Close' : 'Retry' })] }));
};
export default ConnectSocialMediaStatus;
