import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { CheckCircle, Sparkle, CancelCircle } from '@components/Icons';
const useStyles = makeStyles()((theme) => ({
    banner: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    statusIcon: {
        marginTop: theme.spacing(1),
    },
    success: {
        fill: theme.palette.success.main,
    },
    error: {
        fill: theme.palette.error.main,
    },
    sparkleTopLeft: {
        margiRight: theme.spacing(1),
    },
    sparkletBottomRight: {
        alignSelf: 'flex-end',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
}));
export const ModalStatusBanner = ({ status = 'success', className }) => {
    const { classes, cx } = useStyles();
    return (_jsxs("div", { className: cx(classes.banner, className), children: [_jsx(Sparkle, { className: cx(classes.sparkleTopLeft, {
                    [classes.success]: status === 'success',
                    [classes.error]: status === 'error',
                }) }), status === 'success' ? (_jsx(CheckCircle, { className: cx(classes.statusIcon, classes.success), title: "Success" })) : (_jsx(CancelCircle, { className: cx(classes.statusIcon, classes.error), title: "Error" })), _jsx(Sparkle, { className: cx(classes.sparkletBottomRight, {
                    [classes.success]: status === 'success',
                    [classes.error]: status === 'error',
                }) })] }));
};
export default ModalStatusBanner;
