import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useUpdateUserState } from '@context/UserContext';
import { removeQueryParams } from '@helpers';
import messageReactNative from '@helpers/messageReactNative';
import { isServiceKey, serviceProperties } from '@Types/ServiceKey';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ErrorCodeModal from '../ErrorCodeModal';
import { LoadingModal } from '../LoadingModal';
import { isFirebaseAuthError } from '@helpers/Auth';
import { captureException } from '@sentry/react';
const handleServiceAuthError = (serviceKey, error) => {
    if (isFirebaseAuthError(error) && error.code === 'auth/user-disabled') {
        return {
            state: 'error',
            title: 'Unable to log in',
            content: (_jsxs("p", { children: ["Your account has been disabled. If you believe this is an error please", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }), "."] })),
            showContactSupport: false,
        };
    }
    else {
        captureException(error, { extra: { file: 'CustomAuthenticationModal', serviceKey } });
        const serviceDisplayName = serviceProperties[serviceKey].displayName;
        return {
            state: 'error',
            title: 'Unable to log in',
            content: `An error was encountered while authenticating with ${serviceDisplayName}.`,
        };
    }
};
const CustomAuthenticationModal = ({ children }) => {
    var _a;
    const auth = getAuth();
    const { search } = useLocation();
    const history = useHistory();
    const updateUserState = useUpdateUserState();
    const [state, setState] = useState({ state: 'initial' });
    const handleCloseModal = () => {
        const paramsToRemove = ['firebase_custom_token', 'service_key', 'error_code'];
        if (search.includes('custom_auth_error')) {
            paramsToRemove.push('action');
        }
        const newParams = removeQueryParams(paramsToRemove, search);
        history.replace({ search: newParams.search, hash: newParams.hash });
        setState({ state: 'closed' });
    };
    const authenticate = async () => {
        var _a, _b, _c;
        const urlSearchParams = new URLSearchParams(search);
        const customToken = urlSearchParams.get('firebase_custom_token');
        const serviceKey = (_a = urlSearchParams.get('service_key')) !== null && _a !== void 0 ? _a : undefined;
        const errorCode = (_b = urlSearchParams.get('error_code')) !== null && _b !== void 0 ? _b : undefined;
        const action = (_c = urlSearchParams.get('action')) !== null && _c !== void 0 ? _c : undefined;
        if (errorCode && action === 'custom_auth_error') {
            // custom_auth_error - show an error
            setState({
                state: 'error',
                title: 'Unable to log in',
                errorCode,
            });
        }
        else if (customToken) {
            // proceed with authentication flow
            setState({ state: 'authenticating', customToken, serviceKey });
            try {
                await signInWithCustomToken(auth, customToken);
                updateUserState.refresh();
                handleCloseModal();
            }
            catch (error) {
                if (serviceKey) {
                    setState(handleServiceAuthError(serviceKey, error));
                }
                else {
                    // Possibly a failed session transfer. Hide the modal and remain logged out.
                    messageReactNative({ flow: 'customAuth', event: 'error', errorCode });
                    handleCloseModal();
                }
            }
        }
        else {
            // not related to custom authentication - keep modal hidden
            setState({ state: 'closed' });
        }
    };
    // trigger once on initial render
    useEffect(() => {
        if (state.state === 'initial') {
            authenticate();
        }
    }, []);
    if (state.state === 'error') {
        return (_jsx(ErrorCodeModal, { onClose: handleCloseModal, title: state.title, content: state.content, errorCode: state.errorCode, showContactSupport: (_a = state.showContactSupport) !== null && _a !== void 0 ? _a : true }));
    }
    else if (state.state === 'authenticating') {
        let loadingText = 'Authenticating...';
        if (isServiceKey(state.serviceKey)) {
            const serviceDisplayName = serviceProperties[state.serviceKey].displayName;
            loadingText = `Authenticating with ${serviceDisplayName}...`;
        }
        return _jsx(LoadingModal, { "data-testid": "loadingModal", show: true, loadingText: loadingText });
    }
    else {
        // Hide during initial or closed state
        return children !== null && children !== void 0 ? children : null;
    }
};
export default CustomAuthenticationModal;
