import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import AddGameStatusBanner from '../../../../../AddGame/AddGameStatusBanner';
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: 'Montserrat',
        maxWidth: '335px',
        margin: '0px auto',
    },
    iconSpacing: {
        marginTop: theme.spacing(5),
    },
    headingSpacing: {
        margin: theme.spacing(5, 3),
    },
}));
function ClaimEntry() {
    const { classes, cx } = useStyles();
    return (_jsxs("div", { className: cx(classes.container), children: [_jsx(AddGameStatusBanner, { status: "success", className: classes.iconSpacing }), _jsx("h3", { className: classes.headingSpacing, children: "Claim your free Tournament Entry!" }), _jsx("p", { children: "Congrats! You've earned Free Tournament Entry, just click \"Claim your Entry\" and you'll automatically join Tournament" })] }));
}
export default ClaimEntry;
