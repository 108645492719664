import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@components/Buttons/Button';
import TextField from '@components/Fields/TextField';
import getVisiblePageNumbers from '@helpers/getVisiblePageNumbers';
const useStyles = makeStyles()((theme) => ({
    pagination: {
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        listStyle: 'none',
        margin: '0',
        padding: '0',
    },
    button: {
        background: '#FFFFFF',
        border: `1px solid ${theme.palette.grey[100]}`,
        color: theme.palette.grey[900],
        height: '36px',
        width: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '&.active': {
            background: theme.palette.primary.main,
            color: '#fff',
        },
        '&:disabled': {
            background: theme.palette.grey[100],
        },
        [theme.breakpoints.down('md')]: {
            height: '42px',
            width: '42px',
        },
    },
    pageSetter: {
        display: 'flex',
        alignItems: 'center',
        '& > p': {
            margin: '0px',
            fontWeight: 600,
        },
        '& > button': {
            borderRadius: '0px 4px 4px 0px',
            padding: '8px 12px',
            marginRight: theme.spacing(2),
        },
    },
    pageTextField: {
        marginLeft: theme.spacing(2),
        '& label': {
            display: 'none',
        },
        width: '42px',
    },
}));
const Pagination = ({ totalItems, itemsPerPage, currentPage, onPaginationClick, loading, className, showTextInput = false, }) => {
    const { classes, cx } = useStyles();
    const pages = Math.ceil(totalItems / itemsPerPage);
    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === pages - 1;
    const [pageInput, setPageInput] = useState('');
    // If the current page is not the first page, trigger first page
    const getFirstPage = () => (currentPage > 0 ? onPaginationClick(0) : null);
    // If the current page is not the first page, trigger previous page
    const getPrevPage = () => (currentPage > 0 ? onPaginationClick(currentPage - 1) : null);
    // If the current page is not the last page, trigger previous page
    const getNextPage = () => (currentPage < pages - 1 ? onPaginationClick(currentPage + 1) : null);
    // If the current page is not the last page, trigger last page
    const getLastPage = () => (currentPage < pages - 1 ? onPaginationClick(pages - 1) : null);
    const pagesRemaining = pages - (currentPage + 1);
    // Based on the current page, produce a set of relevant next / previous pages
    const spreadPageButtons = getVisiblePageNumbers(pages, currentPage).map((page) => (_jsx("li", { children: _jsx("button", { disabled: loading, type: "button", className: cx(classes.button, page === currentPage ? 'active' : ''), onClick: () => onPaginationClick(page), children: page + 1 }) }, page)));
    const onPageTextFieldSubmit = () => {
        if (Number.isInteger(Number(pageInput))) {
            const selectedPage = Math.max(0, Math.min(Number(pageInput) - 1, pages - 1));
            onPaginationClick(selectedPage);
        }
        setPageInput('');
    };
    return (_jsxs("ul", { className: cx(classes.pagination, className), "data-testid": "pagination", "data-notranslate": "true", children: [showTextInput && (_jsxs("li", { className: classes.pageSetter, children: [_jsx("p", { children: "Go to page" }), _jsx(TextField, { className: classes.pageTextField, type: "text", label: "page", placeholder: "", name: "page", value: pageInput, onChange: (e) => setPageInput(e.currentTarget.value) }), _jsx(Button, { onClick: onPageTextFieldSubmit, children: "Go" })] })), _jsx("li", { children: _jsxs("button", { disabled: loading || isFirstPage, type: "button", "aria-label": "first page", className: classes.button, onClick: getFirstPage, children: [_jsx("i", { className: "fal fa-chevron-left" }), _jsx("i", { className: "fal fa-chevron-left" })] }) }), _jsx("li", { children: _jsx("button", { disabled: loading || isFirstPage, type: "button", "aria-label": "previous page", className: classes.button, onClick: getPrevPage, children: _jsx("i", { className: "fal fa-chevron-left" }) }) }), spreadPageButtons, pagesRemaining >= 4 && (_jsx("li", { children: _jsx("button", { disabled: loading, type: "button", className: classes.button, onClick: () => onPaginationClick(currentPage + 3), "aria-label": "next range of pages", children: "..." }) }, "...")), _jsx("li", { children: _jsx("button", { disabled: loading || isLastPage, type: "button", "aria-label": "next page", className: classes.button, onClick: getNextPage, children: _jsx("i", { className: "fal fa-chevron-right" }) }) }), _jsx("li", { children: _jsxs("button", { disabled: loading || isLastPage, type: "button", "aria-label": "last page", className: classes.button, onClick: getLastPage, children: [_jsx("i", { className: "fal fa-chevron-right" }), _jsx("i", { className: "fal fa-chevron-right" })] }) })] }));
};
export default Pagination;
