import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import GenericModal from '../GenericModal';
import useUserLinkedServices from '@hooks/API/useUserLinkedServices';
import ConnectPlayStationAccount from '@domain/User/Profile/LoginAccounts/ConnectPlayStationAccount';
import ModalHeader from '../components/ModalHeader';
import ModalCarouselContent from '../components/ModalCarouselContent';
import Icon from '@components/Icons/Icon';
const providerToPageName = (provider) => {
    switch (provider) {
        case 'psn':
            return 'PlayStation Network';
    }
};
export const LinkedAccountModal = ({ entry, show, className, handleSuccess, handleClose, }) => {
    const selectedProvider = entry.instance_data.linked_account;
    const [pages, setPages] = useState([providerToPageName(selectedProvider)]);
    const currentPage = pages[pages.length - 1];
    const { getLinkedServices: { data: linkedServiceData = [] }, } = useUserLinkedServices();
    const hasLinkedService = useMemo(() => linkedServiceData.find((service) => service.serviceKey === selectedProvider), [linkedServiceData]);
    const showModal = show && !hasLinkedService;
    useEffect(() => {
        if (show && hasLinkedService) {
            handleSuccess({});
        }
    }, [show, hasLinkedService]);
    const onPop = (nextPageKeys) => {
        setPages(nextPageKeys);
    };
    const renderModal = (service) => {
        switch (service) {
            case 'PlayStation Network':
                return _jsx(ConnectPlayStationAccount, {});
        }
    };
    return (_jsxs(GenericModal, { className: classnames(className), size: "medium", show: showModal, "data-testid": "app download modal", children: [_jsx(ModalHeader, { title: currentPage, subtitle: "Connect your account", icon: _jsx(Icon, { icon: entry.instance_data.linked_account }), onClose: handleClose, paletteName: entry.instance_data.linked_account }), _jsx(ModalCarouselContent, { loading: false, name: "connect-login-account", pageKeys: pages, onPop: onPop, renderPage: renderModal })] }));
};
export default LinkedAccountModal;
