import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const getConnectedFirebaseAccounts = () => {
    const auth = firebase.auth();
    const socialProviders = [];
    if (auth.currentUser) {
        auth.currentUser.providerData.forEach((provider) => {
            var _a, _b, _c, _d;
            if ((provider === null || provider === void 0 ? void 0 : provider.providerId) === 'google.com') {
                socialProviders.push({
                    type: 'google',
                    username: (_a = provider.email) !== null && _a !== void 0 ? _a : 'Not specified',
                    name: (_b = provider.displayName) !== null && _b !== void 0 ? _b : undefined,
                    id: provider.uid,
                });
            }
            else if ((provider === null || provider === void 0 ? void 0 : provider.providerId) === 'facebook.com') {
                socialProviders.push({
                    type: 'facebook',
                    username: (_c = provider.email) !== null && _c !== void 0 ? _c : 'Not specified',
                    name: (_d = provider.displayName) !== null && _d !== void 0 ? _d : undefined,
                    id: provider.uid,
                });
            }
        });
    }
    return socialProviders;
};
export default getConnectedFirebaseAccounts;
