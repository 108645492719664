import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { displayMessageFromError, getMakeStatsPublicGuide } from '../../../helpers';
const isRankNotAllowedErrorDataType = (maybeRankNotAllowedErrorDataType) => {
    const rankNotAllowedErrorDataType = maybeRankNotAllowedErrorDataType;
    return ((rankNotAllowedErrorDataType === null || rankNotAllowedErrorDataType === void 0 ? void 0 : rankNotAllowedErrorDataType.userGameRank) !== undefined ||
        (rankNotAllowedErrorDataType === null || rankNotAllowedErrorDataType === void 0 ? void 0 : rankNotAllowedErrorDataType.lowestAllowedGameRank) !== undefined ||
        (rankNotAllowedErrorDataType === null || rankNotAllowedErrorDataType === void 0 ? void 0 : rankNotAllowedErrorDataType.highestAllowedGameRank) !== undefined);
};
const allowedRanksMessage = (errorData) => {
    var _a, _b;
    if ((errorData === null || errorData === void 0 ? void 0 : errorData.lowestAllowedGameRank) &&
        (errorData === null || errorData === void 0 ? void 0 : errorData.highestAllowedGameRank) &&
        errorData.highestAllowedGameRank.gameRankKey === errorData.lowestAllowedGameRank.gameRankKey) {
        return errorData.highestAllowedGameRank.displayName;
    }
    if (!!(errorData === null || errorData === void 0 ? void 0 : errorData.lowestAllowedGameRank) && !!(errorData === null || errorData === void 0 ? void 0 : errorData.highestAllowedGameRank)) {
        return `${(_a = errorData.lowestAllowedGameRank) === null || _a === void 0 ? void 0 : _a.displayName} to ${(_b = errorData === null || errorData === void 0 ? void 0 : errorData.highestAllowedGameRank) === null || _b === void 0 ? void 0 : _b.displayName}`;
    }
    if (errorData === null || errorData === void 0 ? void 0 : errorData.lowestAllowedGameRank) {
        return `${errorData.lowestAllowedGameRank.displayName} or higher`;
    }
    if (errorData === null || errorData === void 0 ? void 0 : errorData.highestAllowedGameRank) {
        return `${errorData.highestAllowedGameRank.displayName} or lower`;
    }
    return '';
};
const errorDisplayRankNotAllowedError = (error, rankNotAllowedErrorData) => {
    var _a;
    return ({
        title: displayMessageFromError(error),
        content: (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Allowed ranks:", _jsx("br", {}), allowedRanksMessage(rankNotAllowedErrorData)] }), (rankNotAllowedErrorData === null || rankNotAllowedErrorData === void 0 ? void 0 : rankNotAllowedErrorData.userGameRank) && _jsxs("p", { children: ["You are: ", (_a = rankNotAllowedErrorData === null || rankNotAllowedErrorData === void 0 ? void 0 : rankNotAllowedErrorData.userGameRank) === null || _a === void 0 ? void 0 : _a.displayName] })] })),
        showContactSupport: false,
    });
};
const isPrivateSettingErrorDataType = (errorDataType) => {
    const rankNotAllowedErrorDataType = errorDataType;
    return (rankNotAllowedErrorDataType === null || rankNotAllowedErrorDataType === void 0 ? void 0 : rankNotAllowedErrorDataType.privateSettingError) && !!(rankNotAllowedErrorDataType === null || rankNotAllowedErrorDataType === void 0 ? void 0 : rankNotAllowedErrorDataType.gameKey);
};
const errorDisplayPrivateSettingError = (error, errorData) => {
    var _a, _b, _c;
    const link = getMakeStatsPublicGuide(errorData.gameKey);
    return {
        title: 'Unable to join tournament',
        content: (_jsxs(_Fragment, { children: [_jsx("p", { children: displayMessageFromError(error) }), _jsxs("p", { children: ["If you believe this is an error,", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }), "and we will review your case for you."] }), link && (_jsx("p", { children: _jsx("a", { href: link, children: "Click here for a guide on how to make your results public." }) }))] })),
        showContactSupport: false,
        errorCode: (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.errorCode,
    };
};
const isAgeRestrictedErrorDataType = (maybeAgeRestrictedErrorDataType) => {
    const ageRestrictedErrorDataType = maybeAgeRestrictedErrorDataType;
    return (ageRestrictedErrorDataType.gameAgeRating !== undefined ||
        ageRestrictedErrorDataType.gameKey !== undefined ||
        ageRestrictedErrorDataType.gameName !== undefined);
};
const errorDisplayAgeRestrictedError = (error, ageRestrictedErrorData) => {
    let content;
    if (ageRestrictedErrorData.gameKey === 'cod-warzone-2') {
        content = (_jsxs(_Fragment, { children: [_jsxs("p", { children: ["Activision have a strict +", ageRestrictedErrorData.gameAgeRating, " policy that Repeat must adhere to when running tournaments."] }), _jsx("p", { children: "Users found to be in violation of this policy will forfeit all Warzone tournament winnings and risk their Repeat account being banned." }), _jsx("p", { children: _jsx("a", { rel: "noreferrer", target: "_blank", href: "https://support.repeat.gg/hc/en-us/articles/18905617889691-Activision-Age-restricted-tournaments", children: "Read the knowledge base article here" }) })] }));
    }
    return {
        title: `You do not meet the age requirements for competing in ${ageRestrictedErrorData.gameName} tournaments`,
        content,
        showContactSupport: false,
    };
};
const isTitledErrorDataType = (maybeTitledErrorDataType) => {
    const titledErrorDataType = maybeTitledErrorDataType;
    return !!titledErrorDataType.title;
};
const errorTitledErrorDataType = (error, titledErrorDataType) => {
    return {
        title: titledErrorDataType.title,
        content: displayMessageFromError(error),
        showContactSupport: !titledErrorDataType.hideContactSupport,
    };
};
const tournamentEntryErrorDisplay = (error) => {
    var _a, _b, _c, _d, _e;
    const errorData = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data;
    if (errorData && isRankNotAllowedErrorDataType(errorData)) {
        return errorDisplayRankNotAllowedError(error, errorData);
    }
    if (errorData && isPrivateSettingErrorDataType(errorData)) {
        return errorDisplayPrivateSettingError(error, errorData);
    }
    if (errorData && isAgeRestrictedErrorDataType(errorData)) {
        return errorDisplayAgeRestrictedError(error, errorData);
    }
    if (errorData && isTitledErrorDataType(errorData)) {
        return errorTitledErrorDataType(error, errorData);
    }
    return {
        title: 'Unable to join tournament',
        content: displayMessageFromError(error),
        showContactSupport: true,
        errorCode: (_e = (_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.errorCode,
    };
};
export default tournamentEntryErrorDisplay;
