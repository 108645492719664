import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ModalContent } from '@components/Modals';
import { TextInput } from '@components/Fields';
import { useAddGameContext } from '../context/AddGameProvider';
import SaveGameDetails from '../SaveGameDetails';
const ContentConnectBrawlStars = ({ stepName, onSubmit }) => {
    const { connectGameService } = useAddGameContext();
    const [playerTag, setPlayerTag] = useState('');
    const handleChange = (e) => {
        setPlayerTag(e.target.value);
    };
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(playerTag);
        }
    };
    // Clears the username when game service changes.
    useEffect(() => {
        setPlayerTag('');
    }, [connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey]);
    return (_jsxs(ModalContent, { stepName: stepName, verticalAlign: "top", children: [_jsxs("section", { children: [_jsxs("h3", { children: ["Enter your ", _jsx("span", { "data-ignore": true, children: "Brawl Stars" }), " account id"] }), _jsxs("ol", { children: [_jsxs("li", { children: ["Login to ", _jsx("span", { "data-ignore": true, children: "Brawl Stars" }), " on your phone"] }), _jsx("li", { children: "From the main menu, click on your player tag" }), _jsx("li", { children: "Under your player icon, you should see your account id, it starts with a #" }), _jsx("li", { children: "Enter the ID below" })] }), _jsx(TextInput, { id: "game-service-brawl-stars-id", placeholder: "Your Brawl Stars account id", value: playerTag, onChange: handleChange, "aria-label": "Account ID" })] }), _jsx(SaveGameDetails, { lookupValue: playerTag, onClick: handleSubmit })] }));
};
export default ContentConnectBrawlStars;
