import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
import 'firebase/compat/auth';
import useDetectAdsenseAdsBlocked from '@hooks/useDetectAdsenseAdsBlocked';
import getLanguageFromStorage from '../helpers/getLanguageFromStorage';
const defaultState = {
    adsenseAdBlockerDetected: false,
    selectedLanguage: getLanguageFromStorage(),
    setSelectedLanguage: () => {
        throw new Error('Language not initialized');
    },
    selectedProduct: { type: 'empty' },
    setSelectedProduct: () => {
        throw new Error('LocalAppContext not initialized');
    },
    modalData: null,
    openModal: () => {
        throw new Error('LocalAppContext not initialized');
    },
    closeModal: () => {
        throw new Error('LocalAppContext not initialized');
    },
    setRefresh: () => {
        throw new Error('LocalAppContext not initialized');
    },
    setFirebaseSocialAuthUserCredential: () => {
        throw new Error('LocalAppContext not initialized');
    },
};
/** *
 * This is a home for any state that
 * - needs to persist between react-navigation events
 * - does not need to persist on a page refresh
 * - does not already have a home somewhere else
 * - does not come from the server (?)
 *
 * Examples of stuff that might belong here:
 * - detecting & remembering things as soon as the page has loaded
 * - track how a user arrived at certain pages
 * - which modal is currently showing
 *
 * All this is up for debate, but this at least gives a starting point.
 */
export const LocalAppContext = createContext(defaultState);
const LocalAppContextProvider = (props) => {
    const adsenseAdBlockerDetected = useDetectAdsenseAdsBlocked();
    const [selectedLanguage, setSelectedLanguageState] = useState(getLanguageFromStorage());
    const [selectedProduct, setSelectedProduct] = useState({ type: 'empty' });
    const [modalData, setModalData] = useState(null);
    const [firebaseSocialAuthUserCredential, setFirebaseSocialAuthUserCredential] = useState();
    // Used to refresh state on an event
    const [, setRefresh] = useState(0);
    const setSelectedLanguage = (language) => {
        setSelectedLanguageState(language);
        window.Localize.setLanguage(language.code);
    };
    const { children, ...overrideProps } = props;
    const localAppState = {
        adsenseAdBlockerDetected,
        selectedLanguage,
        setSelectedLanguage,
        selectedProduct,
        setSelectedProduct,
        modalData,
        openModal: setModalData,
        closeModal: () => setModalData(null),
        setRefresh,
        firebaseSocialAuthUserCredential,
        setFirebaseSocialAuthUserCredential,
        ...overrideProps,
    };
    return _jsx(LocalAppContext.Provider, { value: localAppState, children: children });
};
export const useLocalAppContext = () => {
    const context = useContext(LocalAppContext);
    if (context === undefined) {
        throw new Error('useLocalAppContext must be used within a LocalAppContextProvider');
    }
    return context;
};
export const useModal = () => {
    const { openModal, closeModal } = useContext(LocalAppContext);
    return { openModal, closeModal };
};
export default LocalAppContextProvider;
