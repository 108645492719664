import axios from 'axios';
import { ErrorWithCause } from '@helpers';
const loadGameData = async () => {
    try {
        const { data } = await axios.get('/api/game/list/v1');
        return data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default loadGameData;
