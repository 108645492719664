import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import YouTube from 'react-youtube';
import useCountdown from '@components/Hooks/useCountdown';
import { captureException } from '@sentry/react';
import GenericModal from '../GenericModal';
import { CheckboxField } from '../..';
import Button from "@components/Buttons/Button";
const useStyles = makeStyles()((theme) => ({
    videoModalBanner: {
        '& img': {
            minHeight: '160px',
            maxHeight: '240px',
            maxWidth: '100%',
            objectFit: 'cover',
        },
    },
    videoModalContent: {
        padding: '0 30px 30px',
        textAlign: 'center',
        '& p': {
            color: '#1D2128',
            fontFamily: 'Montserrat',
            fontSize: '16px',
            fontWeight: '500',
            margin: '25px 0',
        },
        '& .customEntryVideo': {
            height: '360px',
        },
    },
    videoCountdown: {
        background: '#000',
        color: theme.palette.common.white,
        padding: theme.spacing(1, 2),
        fontSize: '20px',
        textAlign: 'right',
    },
    videoWrapper: {
        marginBottom: theme.spacing(2.5),
    },
    videoPlaceholder: {
        height: '360px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover i': {
            color: theme.palette.primary.main,
        },
        '& i': {
            color: '#B4B4B4',
            fontSize: '60px',
        },
    },
}));
export const VideoModal = ({ entry, show, className, handleSuccess, handleClose, tournamentBanner, }) => {
    const { classes, cx } = useStyles();
    const [watched, setWatched] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAdvertisingConsent, setShowAdvertisingConsent] = useState(true);
    const [buttonText, setButtonText] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [remainingDuration, setRemainingDuration] = useState(0);
    const { remainingSeconds, setRemainingSeconds } = useCountdown({
        startSeconds: 0,
    });
    const getCountdownDisplay = (seconds) => {
        const displayMin = Math.floor(seconds / 60);
        const displaySec = seconds % 60;
        return `${displayMin}:${displaySec < 10 ? '0' : ''}${displaySec}`;
    };
    useEffect(() => {
        var _a;
        const showConsent = ((_a = entry.instance_data) === null || _a === void 0 ? void 0 : _a.show_consent) !== undefined ? entry.instance_data.show_consent : true;
        setShowAdvertisingConsent(showConsent);
    }, [setShowAdvertisingConsent, entry]);
    const handleVideoFinished = () => {
        setIsPlaying(false);
        setRemainingDuration(0);
        setWatched(true);
    };
    const canEnter = () => (watched && showAdvertisingConsent && agreed) || (watched && !showAdvertisingConsent);
    const handleEnterClick = () => {
        if (canEnter()) {
            setLoading(true);
            handleSuccess({ watched, agreed });
        }
    };
    const updateRemaining = (duration, progress) => {
        const remaining = Math.floor(duration - progress);
        setRemainingDuration(remaining);
        return remaining;
    };
    const handlePlay = (data) => {
        const remaining = updateRemaining(data.target.getDuration(), data.target.getCurrentTime());
        setIsPlaying(true);
        setRemainingSeconds(remaining);
    };
    const handlePause = (data) => {
        updateRemaining(data.target.getDuration(), data.target.getCurrentTime());
        setIsPlaying(false);
    };
    const handleError = (data) => {
        updateRemaining(data.target.getDuration(), data.target.getCurrentTime());
        setIsPlaying(false);
        captureException(data);
    };
    const opts = {
        height: '360',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            controls: 0,
        },
    };
    useEffect(() => {
        if (!showAdvertisingConsent) {
            return setButtonText(watched ? 'Claim your free entry' : 'Watch Video to Continue');
        }
        const buttonAgreedText = agreed ? 'Claim your free entry' : 'Agree to Continue';
        return setButtonText(watched ? buttonAgreedText : 'Watch Video to Continue');
    }, [agreed, showAdvertisingConsent, setButtonText, watched]);
    return (_jsx(GenericModal, { className: cx(className), size: "large", show: show, onClose: handleClose, "data-testid": "video modal", children: _jsxs("div", { children: [_jsx("div", { className: classes.videoModalBanner, children: _jsx("img", { src: tournamentBanner, alt: "" }) }), _jsxs("div", { className: classes.videoModalContent, children: [_jsx("p", { children: "Watch this video to claim your free tournament entry" }), watched ? (_jsx(Button, { variant: "transparent", type: "button", className: classes.videoPlaceholder, onClick: () => setWatched(false), children: _jsx("i", { className: "far fa-play-circle" }) })) : (_jsxs("div", { className: cx(!showAdvertisingConsent && classes.videoWrapper), children: [isPlaying && remainingDuration > 0 && (_jsxs("div", { className: classes.videoCountdown, children: [getCountdownDisplay(remainingSeconds), " Remaining"] })), !isPlaying && remainingDuration > 0 && (_jsxs("div", { className: classes.videoCountdown, children: [getCountdownDisplay(remainingDuration), " Remaining"] })), _jsx(YouTube, { videoId: entry.instance_data.video_id, 
                                    // used by cypress
                                    className: "customEntryVideo", opts: opts, onEnd: handleVideoFinished, onPlay: handlePlay, onPause: handlePause, onError: handleError })] })), showAdvertisingConsent && (_jsx(CheckboxField, { label: "I accept that entering this tournament authorizes Repeat to share my details with the sponsor of this tournament.", onChange: () => {
                                setAgreed(!agreed);
                            }, value: agreed, name: "agreeEnterTournament" })), _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Button, { type: "button", size: "md", block: true, onClick: handleEnterClick, disable: !watched || (showAdvertisingConsent && !agreed), label: buttonText, loading: loading, variant: canEnter() ? 'brand' : 'gray', "data-testid": "confirm tournament entry button", children: buttonText }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(Button, { type: "button", size: "md", variant: "gray", block: true, onClick: handleClose, children: "Cancel" }) })] })] })] }) }));
};
export default VideoModal;
