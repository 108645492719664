import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from "@components/Buttons/Button";
import GenericModal from "@components/Modals/GenericModal";
import Icon from "@components/Icons/Icon";
import TextInput from "@components/Fields/TextInput";
import ContentCarousel from "@components/UI/ContentCarousel";
import { ModalHeader, ModalContent, ModalStatusBanner, ModalBreadcrumb } from '@components/Modals';
import { captureException } from '@sentry/react';
import { useUserState } from '@context/UserContext';
import { displayMessageFromError, isAxiosError } from '@helpers';
import useGetBalance from "@components/Hooks/useGetBalance";
import postProcessVoucher from '@api/postProcessVoucher';
const useStyles = makeStyles()(() => ({
    content: {
        width: '430px',
        maxWidth: '100%',
    },
    status: {
        textAlign: 'center',
    },
}));
const CouponModal = ({ open, onClose }) => {
    var _a;
    const [error, setError] = useState(null);
    const [working, setWorking] = useState(false);
    const [code, setCode] = useState('');
    const user = useUserState();
    const isSuspended = (_a = user.userDetails) === null || _a === void 0 ? void 0 : _a.isSuspended;
    const { classes } = useStyles();
    const [currentStep, setCurrentStep] = useState(0);
    const { refetch } = useGetBalance();
    const reset = () => {
        setCurrentStep(0);
        setWorking(false);
        setCode('');
    };
    const close = () => {
        if (working) {
            return;
        }
        reset();
        onClose();
    };
    const breadcrumbs = [
        {
            id: 'redeem',
            component: 'Redeem',
            onClick: () => reset(),
        },
        {
            id: 'result',
            component: 'Result',
        },
    ];
    const handleCodeChange = (e) => setCode(e.currentTarget.value);
    const handleSubmit = async () => {
        setWorking(true);
        try {
            const result = await postProcessVoucher(code.trim());
            if (result.data.success) {
                setError(null);
                setCurrentStep(1);
                setWorking(false);
                refetch();
            }
        }
        catch (err) {
            setError(displayMessageFromError(err));
            setCurrentStep(1);
            setWorking(false);
            if (isAxiosError(err) && err.response && err.response.status > 499) {
                captureException(err, {
                    extra: {
                        request: 'process_voucher',
                    },
                });
            }
        }
    };
    return (_jsx(GenericModal, { show: open, onClose: close, children: _jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Redeem coupon", subtitle: "Enter your code below", onClose: close, icon: _jsx(Icon, { icon: "repeat" }) }), _jsx(ModalBreadcrumb, { currentIndex: currentStep, breadcrumbs: breadcrumbs, isVisible: true }), !isSuspended && (_jsxs(ContentCarousel, { className: classes.content, currentStep: currentStep, name: "Redeem Coupon Code", children: [_jsxs(ModalContent, { children: [_jsxs("div", { children: [_jsx("h3", { id: "redeem-coupon-heading", children: "Redeem your coupon code" }), _jsx("p", { children: "Enter your coupon code below." })] }), _jsxs("div", { children: [_jsx(TextInput, { id: "coupon", "aria-labelledby": "redeem-coupon-heading", onChange: handleCodeChange, value: code }), _jsx(Button, { type: "button", size: "md", variant: "brand", block: true, onClick: handleSubmit, children: "Redeem Coupon" })] })] }), _jsxs(ModalContent, { align: "center", children: [_jsx(ModalStatusBanner, { status: error ? 'error' : 'success' }), _jsx("h2", { children: error ? 'Something went wrong' : 'Coupon successfully redeemed' }), error && _jsx("p", { children: error }), _jsx(Button, { type: "button", size: "md", variant: "brand", block: true, onClick: error ? reset : close, children: error ? 'Retry' : 'Close' })] })] })), isSuspended && (_jsxs(ModalContent, { className: classes.content, children: [_jsx(ModalStatusBanner, { status: "error" }), _jsx("h3", { children: "Sorry, you cannot redeem coupons when you are suspended" }), _jsx(Button, { type: "button", size: "md", variant: "danger", block: true, onClick: close, children: "Close" })] }))] }) }));
};
export default CouponModal;
