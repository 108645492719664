import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useReducer, useState } from 'react';
import 'firebase/compat/auth';
import { GenericModal, Icon } from '@components';
import { ModalHeader, ModalContent, ModalBody, ModalBreadcrumb } from '@components/Modals/components';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import { ConnectableSocialMediaTypes } from '@domain/User/constants';
import modalStateReducer from '@components/Modals/reducer/modalStateReducer';
import getDefaultModalState from '@components/Modals/helpers/getDefaultModalState';
import useGetUserSocialMediaAccounts from "@components/Hooks/useGetUserSocialMediaAccounts";
import removeQueryParams from '@helpers/removeQueryParams';
import { useUserState } from '@context/UserContext';
import ConnectSocialMediaAccounts from './ConnectSocialMediaAccounts';
import ConnectSocialMediaCarousel from './ConnectSocialMediaCarousel';
import { getSocialMediaServiceNameFromType } from '@helpers/SocialMedia';
const useStyles = makeStyles()((theme) => ({
    carousel: {},
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: theme.spacing(4),
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: 600,
        '&>h2': {
            alignContent: 'center',
            fontSize: '26px',
            fontWeight: 700,
            color: theme.palette.grey[900],
        },
        '&>span': {
            textAlign: 'center',
            fontSize: '12px',
            color: '#73787D',
            padding: theme.spacing(3, 8, 0),
        },
    },
    content: {
        overflowY: 'auto',
    },
    closeBtn: {
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        display: 'inline-flex',
        height: '30px',
        justifyContent: 'center',
        lineHeight: '14px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        width: '30px',
        '& .fal::before': {
            fontSize: '20px',
        },
        color: '#a1a1a1',
    },
    headerCloseBtn: {
        color: 'white',
    },
}));
function getErrorStatusMessage(errorCode, socialMediaType) {
    if (errorCode === 'duplicate_account' && socialMediaType === 'twitch') {
        return (_jsxs(_Fragment, { children: ["This ", _jsx("span", { "data-ignore": true, children: "Twitch" }), " account is already connected to another ", _jsx("span", { "data-ignore": true, children: "Repeat" }), ' ', "account", _jsx("br", {}), _jsx("br", {}), "If you believe this is an error,", ' ', _jsx("a", { href: "https://support.repeat.gg/hc/en-us/requests/new", rel: "noopener noreferrer", target: "_blank", children: "contact support" }), ' ', "and we'll review your case for you."] }));
    }
    return 'There was an error connecting your account, please try again';
}
/**
 * Modal for adding a social media account
 *
 * @param open boolean - determines whether the modal is open or not.
 * @param onClose Invoked anytime the modal is dismissed.
 */
const ConnectSocialMediaAccountsModal = ({ socialMedia, open, onClose, onAddSuccess, }) => {
    const { classes } = useStyles();
    const [selectedSocialMediaType, setSelectedSocialMediaType] = useState();
    const [shouldBeOpen, setShouldBeOpen] = useState(open);
    const [addedSocialMediaAccount, setAddedSocialMediaAccount] = useState(false);
    const [addSocialMediaAccountError, setAddSocialMediaAccountError] = useState();
    const history = useHistory();
    const { userProfile } = useUserState();
    // Used to force a step change in the content carousel
    const { data: socialMediaAccounts, refetch } = useGetUserSocialMediaAccounts(userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle);
    const headerDefaultState = {
        title: null,
        subtitle: null,
        isVisible: false,
    };
    const getExistingUserAccount = useCallback((socialMediaAccountType) => {
        if (socialMediaAccounts) {
            const userAccount = socialMediaAccounts.reduce((accountName, socialMediaAccount) => {
                if ((socialMediaAccount === null || socialMediaAccount === void 0 ? void 0 : socialMediaAccount.type) === socialMediaAccountType) {
                    return socialMediaAccount.username;
                }
                return accountName;
            }, '');
            return userAccount;
        }
        return '';
    }, [socialMediaAccounts]);
    // Modal State is agnostic of the social media information.
    const [modalState, modalDispatch] = useReducer(modalStateReducer, {
        ...getDefaultModalState(),
        header: {
            ...headerDefaultState,
        },
    });
    const { header, breadcrumbs, breadcrumbIndex, breadCrumbsVisible } = modalState;
    const removeAddSocialParams = () => {
        if (history) {
            const { search, hash } = removeQueryParams(['added_social', 'add_social_error']);
            history.replace({ search, hash });
        }
    };
    const showBreadcrumbs = () => {
        modalDispatch({
            type: 'setBreadcrumbsVisible',
            breadcrumbsVisible: true,
        });
    };
    const hideBreadcrumbs = () => {
        modalDispatch({
            type: 'setBreadcrumbsVisible',
            breadcrumbsVisible: false,
        });
    };
    const resetModalState = () => {
        setSelectedSocialMediaType(socialMedia);
        setAddedSocialMediaAccount(false);
        setAddSocialMediaAccountError(undefined);
        modalDispatch({
            type: 'setHeader',
            header: {
                ...headerDefaultState,
            },
        });
        hideBreadcrumbs();
    };
    const showConnectSocialMediaAccount = (socialMediaType) => {
        setSelectedSocialMediaType(socialMediaType);
        // Set header
        modalDispatch({
            type: 'setHeader',
            header: {
                repeatPalette: socialMediaType,
                icon: _jsx(Icon, { icon: socialMediaType }),
                title: _jsx("span", { "data-ignore": true, children: getSocialMediaServiceNameFromType(socialMediaType) }),
                subtitle: `Connect your Account`,
                isVisible: true,
            },
        });
        // Set breadcrumb
        const allowsSelection = !socialMedia;
        modalDispatch({
            type: 'setBreadcrumbs',
            breadcrumbs: [
                ...(allowsSelection
                    ? [
                        {
                            id: 'back-to-select',
                            component: 'Select',
                            onClick: () => resetModalState(),
                        },
                    ]
                    : []),
                {
                    id: `connect-${socialMediaType}`,
                    component: 'Connect',
                },
            ],
        });
        // Set breadcrumb index to connect page
        modalDispatch({
            type: 'setBreadcrumbIndex',
            breadcrumbIndex: allowsSelection ? 1 : 0,
        });
    };
    const checkForInitialSelection = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const isOauthCallback = queryParams.has('added_social');
        const socialMediaType = isOauthCallback ? queryParams.get('added_social') : undefined;
        const isOauthCallbackSuccess = !queryParams.has('add_social_error');
        removeAddSocialParams();
        if (isOauthCallback) {
            // If we are returning from an OAuth handover, show the dialog with success or error.
            setShouldBeOpen(true);
            hideBreadcrumbs();
            setAddedSocialMediaAccount(true);
            if (!isOauthCallbackSuccess) {
                const errorMessage = getErrorStatusMessage(queryParams.get('add_social_error'), socialMediaType);
                setAddSocialMediaAccountError(errorMessage);
            }
            showConnectSocialMediaAccount(socialMediaType);
        }
        else if (socialMedia) {
            // Otherwise, see if this dialog is locked to one particular social media type
            setAddedSocialMediaAccount(false);
            setAddSocialMediaAccountError(undefined);
            showConnectSocialMediaAccount(socialMedia);
            showBreadcrumbs();
        }
    };
    /**
     * First step in connecting social accounts.
     * If it's twitter, it'll go to the next step, and add a breadcrumb.
     * @param socialMediaType
     */
    const handleConnectSocialMediaAccountSelect = (socialMediaType) => {
        showConnectSocialMediaAccount(socialMediaType);
        showBreadcrumbs();
    };
    /**
     * Handles modal close. Supports two optional parameters, if the close
     * event comes from modal success
     * @param socialMediaType undefined until it has a connection status
     * @param status undefined until it has a connection
     */
    const handleClose = () => {
        resetModalState();
        setShouldBeOpen(false);
        if (onClose) {
            onClose();
        }
    };
    const handleConnectAccountSuccess = () => {
        refetch();
        hideBreadcrumbs();
        if (onAddSuccess) {
            onAddSuccess();
        }
    };
    const handleConnectAccountError = () => {
        hideBreadcrumbs();
    };
    const handleConnectAccountRetry = () => {
        showBreadcrumbs();
    };
    const renderConnectSocial = useCallback(() => {
        switch (selectedSocialMediaType) {
            case 'twitch':
            case 'twitter':
            case 'youtube':
            case 'instagram':
            case 'google':
            case 'psn':
                return (_jsx(ConnectSocialMediaCarousel, { socialMediaType: selectedSocialMediaType, onSuccess: handleConnectAccountSuccess, onError: handleConnectAccountError, onRetry: handleConnectAccountRetry, username: getExistingUserAccount(selectedSocialMediaType), onConfirm: handleClose, className: classes.carousel, addedSocialMediaAccount: addedSocialMediaAccount, addSocialMediaAccountError: addSocialMediaAccountError }));
            default:
                return null;
        }
    }, [selectedSocialMediaType, addedSocialMediaAccount, addSocialMediaAccountError]);
    useEffect(() => {
        setShouldBeOpen(open);
    }, [open]);
    useEffect(() => {
        checkForInitialSelection();
    }, [setShouldBeOpen]);
    return (_jsx(GenericModal, { show: shouldBeOpen, size: "small", disableBackdropClick: false, onClose: handleClose, mobileFullScreen: true, children: _jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: (header === null || header === void 0 ? void 0 : header.title) || '', subtitle: (header === null || header === void 0 ? void 0 : header.subtitle) || '', isVisible: header === null || header === void 0 ? void 0 : header.isVisible, icon: header === null || header === void 0 ? void 0 : header.icon, paletteName: (header === null || header === void 0 ? void 0 : header.repeatPalette) || 'primary', onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, isVisible: breadCrumbsVisible }), _jsxs(ModalBody, { children: [!selectedSocialMediaType && shouldBeOpen && (_jsxs(ModalContent, { align: "center", children: [_jsx("h2", { children: "Social Media" }), _jsx("span", { children: "Please select the Social Media channel that you want to connect to your account" }), _jsx(ConnectSocialMediaAccounts, { availableTypes: ConnectableSocialMediaTypes, onSelect: handleConnectSocialMediaAccountSelect })] })), renderConnectSocial()] })] }) }));
};
export default ConnectSocialMediaAccountsModal;
