import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { IconButton } from '@mui/material';
/** Following are imported separately otherwise it'll break test */
import { getBrowserExtensionEnvironment } from '@helpers/getBrowserExtensionEnvironment';
import { isBrowserExtensionInstalled } from '@helpers/isBrowserExtensionInstalled';
import useInterval from '@hooks/useInterval';
import GenericModal from '../../GenericModal';
import ExtensionNotDetectablePage from './ExtensionNotDetectablePage';
import ExtensionInstalledPage from './ExtensionInstalledPage';
import ExtensionNotInstalledPage from './ExtensionNotInstalledPage';
import useStyles from './useStyles';
const InstallBrowserExtensionModal = ({ entry, show, className, handleSuccess, handleClose, }) => {
    const { classes } = useStyles({ extensionColor: entry.instance_data.color });
    const [extensionInstalled, setExtensionInstalled] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { extensionDetectableBrowser, isMobile } = getBrowserExtensionEnvironment();
    const { extensionFingerPrints } = entry.instance_data;
    const browserExtensionData = extensionDetectableBrowser ? extensionFingerPrints[extensionDetectableBrowser] : null;
    useEffect(() => {
        if (!extensionDetectableBrowser || !browserExtensionData) {
            return;
        }
        isBrowserExtensionInstalled(browserExtensionData).then((isInstalled) => {
            setExtensionInstalled(isInstalled);
        });
    }, []);
    const onSuccess = () => {
        handleSuccess({ extensionInstalled });
    };
    useEffect(() => {
        if (show && !extensionInstalled && !openModal) {
            setOpenModal(true);
        }
        if (show && extensionInstalled && !openModal) {
            onSuccess();
        }
        if (!show) {
            setOpenModal(false);
        }
    }, [show, extensionInstalled]);
    useInterval(async () => {
        if (!openModal || !extensionDetectableBrowser || extensionInstalled || !browserExtensionData) {
            return;
        }
        const isInstalled = await isBrowserExtensionInstalled(browserExtensionData);
        setExtensionInstalled(isInstalled);
    }, 2000);
    return (_jsx(GenericModal, { className: classnames(className), size: "small", show: openModal, children: _jsxs("div", { className: classes.container, children: [_jsx("div", { className: classes.banner, children: _jsx("img", { src: entry.instance_data.bannerImageUrl, alt: "banner" }) }), _jsxs("div", { className: classes.content, children: [_jsx(IconButton, { "aria-label": "close", onClick: handleClose, className: classes.closeBtn, size: "large", children: _jsx("i", { className: "fal fa-times-circle" }) }), !extensionDetectableBrowser && (_jsx(ExtensionNotDetectablePage, { isMobile: isMobile, entry: entry, handleClose: handleClose })), extensionDetectableBrowser && extensionInstalled && (_jsx(ExtensionInstalledPage, { entry: entry, handleSuccess: onSuccess })), extensionDetectableBrowser && !extensionInstalled && browserExtensionData && (_jsx(ExtensionNotInstalledPage, { entry: entry, installURL: browserExtensionData.extensionInstallUrl }))] })] }) }));
};
export default InstallBrowserExtensionModal;
