import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import ChevronDown from '@components/Icons/ChevronDown';
import SROnly from '@components/UI/SROnly';
const useStyles = makeStyles()((theme) => ({
    selectContainer: {
        position: 'relative',
        '& select': {
            padding: theme.spacing(1, 2),
            border: `1px solid ${theme.palette.grey[200]}`,
            borderRadius: theme.shape.borderRadius,
            background: 'transparent',
            appearance: 'none',
            width: '100%',
            margin: theme.spacing(2, 0),
        },
        '& select:hover': {
            boxShadow: `0 0 0 1px ${theme.palette.grey[300]}`,
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        '& select:focus': {
            boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    label: {
        position: 'relative',
        width: '100%',
    },
    placeholder: {
        color: theme.palette.grey[400],
    },
    error: {
        '& select': {
            boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
            border: `1px solid ${theme.palette.error.main}`,
        },
    },
    errorMessage: {
        color: theme.palette.error.main,
        fontWeight: 'bold',
    },
    chevron: {
        position: 'absolute',
        fill: theme.palette.grey[200],
        width: `10px`,
        right: theme.spacing(1),
        top: 0,
        bottom: 0,
        margin: 'auto',
    },
    chevronTopOffset: {
        top: `20px`, // offset by label height if label present
    },
    disabledInput: {
        '& select': {
            background: theme.palette.grey[100],
            color: theme.palette.grey[400],
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        '& select:hover': {
            boxShadow: `0 0 0 0px ${theme.palette.grey[300]}`,
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        '& select:focus': {
            boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
            border: `1px solid ${theme.palette.grey[100]}`,
        },
    },
}));
const NativeSelect = ({ label, placeholder, className, id, options, onSelect, name, error, errorMessage, dataTestId, hideLabel = false, selectedValue, disabled = false, }) => {
    const { classes, cx } = useStyles();
    const LabelWrapper = hideLabel ? SROnly : React.Fragment;
    const handleChange = (e) => {
        e.preventDefault();
        onSelect(e.currentTarget.value, e);
    };
    // Specifying labels via data-localize can help give context while approving phrases
    // https://help.localizejs.com/docs/labels#system-generated-vs-user-created-labels
    const renderedOptions = options === null || options === void 0 ? void 0 : options.map((option, idx) => (_jsx("option", { disabled: option.disabled, value: option.value, "data-localize": id, children: option.label }, `${option.value}-${idx}`)));
    return (_jsxs("div", { className: cx(classes.selectContainer, className, {
            [classes.error]: error,
            [classes.disabledInput]: disabled,
        }), children: [_jsxs("label", { htmlFor: id, className: classes.label, children: [_jsx(LabelWrapper, { children: label }), _jsx(ChevronDown, { className: cx(classes.chevron, {
                            [classes.chevronTopOffset]: !hideLabel,
                        }) }), _jsxs("select", { id: id, value: selectedValue !== null && selectedValue !== void 0 ? selectedValue : '', onChange: handleChange, name: name, className: cx({
                            [classes.placeholder]: !selectedValue || selectedValue === '',
                        }), "data-testid": dataTestId, disabled: disabled, children: [placeholder && (_jsx("option", { value: "", disabled: true, hidden: true, children: placeholder })), renderedOptions] })] }), error && _jsx("span", { className: classes.errorMessage, children: errorMessage })] }));
};
export default NativeSelect;
