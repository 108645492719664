import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CrossCloseIcon from '@components/UI/CrossCloseIcon';
const useStyles = makeStyles()((theme, { color }) => {
    return {
        header: {
            background: color,
            color: theme.palette.common.white,
            display: 'flex',
            fontFamily: theme.typography.fontFamily,
            height: '95px',
            padding: theme.spacing(3),
            position: 'relative',
            width: '100%',
            '& h2': {
                fontSize: '16px',
                fontWeight: 700,
                margin: 0,
                lineHeight: '30px',
                textAlign: 'left',
            },
            '& h6': {
                fontSize: '12px',
                margin: 0,
                textAlign: 'left',
            },
        },
        headerHidden: {
            position: 'absolute',
            height: 'auto',
            width: '100%',
            background: 'transparent',
        },
        icon: {
            width: '55px',
            height: '55px',
            background: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            marginRight: theme.spacing(2),
            '& svg': {
                fill: color,
                width: '100%',
            },
            '& img': {
                width: '100%',
            },
        },
        hidden: {
            display: 'none',
        },
        closeButton: {
            alignItems: 'center',
            background: 'transparent',
            border: 'none',
            color: 'currentColor',
            display: 'inline-flex',
            height: '30px',
            justifyContent: 'center',
            lineHeight: '14px',
            position: 'absolute',
            right: '10px',
            top: '10px',
        },
    };
});
const ModalHeader = ({ title, subtitle, icon, className, color, paletteName = 'primary', isVisible = true, onClose = () => undefined, }) => {
    const theme = useTheme();
    // If an overriding color is passed in, use the color, otherwise use a RepeatTheme palette
    const backgroundColor = color !== null && color !== void 0 ? color : theme.palette[paletteName].main;
    const { classes, cx } = useStyles({ color: backgroundColor });
    return (_jsxs("header", { className: cx({
            [classes.header]: isVisible,
            [classes.headerHidden]: !isVisible,
        }), children: [_jsx("div", { className: cx(classes.icon, className, {
                    [classes.hidden]: !isVisible,
                }), children: icon }), _jsxs("div", { className: cx({
                    [classes.hidden]: !isVisible,
                }), children: [_jsx("h2", { children: title }), _jsx("h6", { children: subtitle })] }), _jsx(CrossCloseIcon, { className: classes.closeButton, action: onClose, fillColor: 'white' })] }));
};
export default ModalHeader;
