import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ModalContent } from '@components/Modals';
import Button from '@components/Buttons/Button';
import { styled } from '@mui/system';
import useTrackEvent from '@hooks/useTrackEvent';
const StyledText = styled('h2') `
  text-align: center;
`;
const ContentInstruction = ({ stepName, onContinue }) => {
    const trackEvent = useTrackEvent();
    const handleClick = () => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: 'Connect Brawl Stars',
                moduleName: 'Button',
                position: 0,
            },
        });
        if (onContinue) {
            onContinue();
        }
    };
    return (_jsxs(ModalContent, { stepName: stepName, children: [_jsxs("section", { children: [_jsxs(StyledText, { children: ["Connect your ", _jsx("span", { "data-ignore": true, children: "Brawl Stars" }), " account"] }), _jsx(StyledText, { as: "p", children: "Add your Game ID to the Repeat profile so you can start competing in the tournaments, our connect process is simple and take about 5 minutes to complete" })] }), _jsx(Button, { type: "button", onClick: handleClick, size: "lg", variant: "palette", paletteName: "brawlStars", children: _jsxs("span", { children: ["Connect ", _jsx("span", { "data-ignore": true, children: " Brawl Stars" })] }) })] }));
};
export default ContentInstruction;
