import { jsx as _jsx } from "react/jsx-runtime";
// Needs to be retargetted.
const tournamentsTourSteps = [
    {
        target: '#top-right-menu .dropdown.p-5',
        content: (_jsx("p", { children: "This is your current balance. Deposit cash or buy coins for your account which can be used to enter tournaments." })),
        title: _jsx("p", { style: { fontSize: '120%' }, children: "View Your Balance" }),
        disableBeacon: true,
    },
    {
        target: '#tournament-list-filters',
        content: _jsx("p", { children: "Filter all tournaments by Featured or by specific game" }),
        title: _jsx("p", { style: { fontSize: '120%' }, children: "Filter tournaments" }),
    },
    {
        target: '.tournament-table-wrapper-filter .ibox-content',
        content: (_jsx("p", { children: "Filter your tournament view even further by currency type, hide full tournaments or sort by min/max entry for each currency." })),
        title: _jsx("p", { style: { fontSize: '120%' }, children: "Advanced Filters" }),
    },
    {
        target: '.tournament-table tbody tr:first-of-type',
        content: (_jsx("p", { children: "See how many people have entered, entry fee, prize pool, status, and days left to enter all in one row!" })),
        title: _jsx("p", { style: { fontSize: '120%' }, children: "Tournament Info" }),
    },
    {
        target: '.tournament-table tbody tr:first-of-type .btn.btn-brand.btn-xs',
        content: _jsx("p", { children: "Remember, you can enter as many tournaments as you like at the same time." }),
        title: _jsx("p", { style: { fontSize: '120%' }, children: "Enter a Tournament now! " }),
    },
];
export default tournamentsTourSteps;
