import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/system';
import SectionContainer from "@components/UI/Section/components/SectionContainer";
import SectionHead from "@components/UI/Section/components/SectionHead";
import SectionHeading from "@components/UI/Section/components/SectionHeading";
import GameIcon from "@components/Icons/GameIcon";
import LoadingLogo from "@components/Modals/LoadingModal/Components/LoadingLogo";
import { getTournamentMessageKey } from '../../helpers/getTournamentMessageKey';
import TournamentMessage from '../TournamentMessage';
import TournamentBox from './TournamentBox/TournamentBox';
import TournamentBoxlette from './TournamentBox/TournamentBoxlette';
import { useGetTournaments } from '@hooks';
const StyledDiv = styled('div') `
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  p {
    margin: 0;
  }
`;
const useStyles = makeStyles()((theme) => ({
    root: { flexGrow: 1 },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: theme.spacing(3),
        gridRowGap: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'minmax(0, 1fr)',
        },
    },
    tournamentBody: {
        '& > div, & > a': {
            boxShadow: `0px -1px 0px ${theme.palette.grey[100]} inset`, // boxShadow displays inside element, due to table collpasing borders
        },
    },
    gameIcon: {
        padding: theme.spacing(3),
        maxHeight: '90px',
        fill: theme.palette.grey[300],
    },
}));
export const TournamentBoxContainer = ({ tournaments, error = false, gameData, isMyTournaments, tournamentDisplayMode, hasFiltersApplied, isTournamentsLoading, }) => {
    const { classes, cx } = useStyles();
    const messageKey = getTournamentMessageKey({ tournaments, gameData, isMyTournaments, error });
    const gameKey = gameData === null || gameData === void 0 ? void 0 : gameData.gameKey;
    const filters = {
        gameKey,
        active: false,
    };
    const fetchFinishedTournaments = !isTournamentsLoading && tournaments.length === 0 && !hasFiltersApplied;
    const { data: finishedTournaments, isLoading } = useGetTournaments(filters, 0, 4, {
        enabled: fetchFinishedTournaments,
    });
    const showRecentlyFinishedTournaments = !isLoading && fetchFinishedTournaments && messageKey === 'coming_soon' && gameKey !== 'fortnite';
    if (messageKey !== null) {
        return _jsxs(_Fragment, { children: [_jsxs(StyledDiv, { className: 'tournament-table', children: [gameKey && _jsx(GameIcon, { game: gameKey, className: classes.gameIcon }), _jsx(TournamentMessage, { messageKey: messageKey, hasFiltersApplied: hasFiltersApplied }), isLoading && fetchFinishedTournaments && _jsx(LoadingLogo, {})] }), showRecentlyFinishedTournaments && (_jsxs(SectionContainer, { className: cx('tournament-table'), children: [_jsx(SectionHead, { children: _jsx(SectionHeading, { children: "Recently Finished Tournaments" }) }), _jsx("div", { className: cx(classes.root), children: tournamentDisplayMode === 'compact' ? (_jsx("div", { className: cx('tournament-compact', classes.root), children: (finishedTournaments === null || finishedTournaments === void 0 ? void 0 : finishedTournaments.tournaments.length) &&
                                    (finishedTournaments === null || finishedTournaments === void 0 ? void 0 : finishedTournaments.tournaments.map((tournament, index) => (_jsx(TournamentBoxlette, { gameKey: gameKey, listIndex: index, section: "Page", tournament: tournament }, tournament.id)))) })) : (_jsx("div", { className: cx('tournament-grid', classes.root), children: _jsx("div", { className: classes.gridContainer, children: (finishedTournaments === null || finishedTournaments === void 0 ? void 0 : finishedTournaments.tournaments.length) &&
                                        (finishedTournaments === null || finishedTournaments === void 0 ? void 0 : finishedTournaments.tournaments.map((tournament, index) => (_jsx(TournamentBox, { listIndex: index, section: "Page", tournament: tournament }, tournament.id)))) }) })) })] }))] });
    }
    return _jsx(_Fragment, { children: tournamentDisplayMode === 'compact' ? (_jsx("div", { className: cx('tournament-compact', classes.root), children: tournaments.length &&
                tournaments.map((tournament, index) => (_jsx(TournamentBoxlette, { gameKey: gameKey, listIndex: index, section: "Page", tournament: tournament }, tournament.id))) })) : (_jsx("div", { className: cx('tournament-grid', classes.root), children: _jsx("div", { className: classes.gridContainer, children: tournaments.length &&
                    tournaments.map((tournament, index) => (_jsx(TournamentBox, { listIndex: index, section: "Page", tournament: tournament }, tournament.id))) }) })) });
};
export default TournamentBoxContainer;
