import { ErrorWithCause } from '@helpers';
import axios from 'axios';
const checkRegistrationAvailability = async ({ userHandle, userEmail, }) => {
    try {
        return await axios.get('/api/user/availability/v1', {
            params: { userHandle, userEmail },
        });
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default checkRegistrationAvailability;
