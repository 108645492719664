import { ErrorWithCause } from '@helpers';
import axios from 'axios';
const getUserLinkedServices = async () => {
    var _a;
    try {
        const response = await axios.get('/api/user/v1/linked-service');
        return (_a = response.data) !== null && _a !== void 0 ? _a : [];
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getUserLinkedServices;
