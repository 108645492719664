import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from 'classnames';
import CoreButton from "@components/Buttons/CoreButton";
import Flex from '@components/Layout/Flex';
import { useMediaQuery, useScrollTrigger } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import trackHotjarEvent from '@components/Analytics/trackHotjarEvent';
import ExclusiveFilterBox from '../../../../components/UI/ExclusiveFilterBox';
import useTournamentFilterState from '../useTournamentFilterState';
const Container = styled('div') `
  background: ${({ theme }) => theme.palette.grey[50]};
  padding: ${({ theme }) => theme.spacing(1)};

  &.stickyContainer {
    position: sticky;
    top: 71px; // main filter top (71) + main filter height (62)
    z-index: 1098; // 1 less than appBar -> https://mui.com/customization/z-index/
  }

  &.stickyContainerDesktop {
    top: ${({ theme }) => `calc(62px + ${theme.layout.headerDesktopHeight}px)`};
  }
`;
const HelperText = styled('div') `
  padding-left: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
const LinkButton = styled(CoreButton) `
  text-decoration: underline;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
export const tournamentsListSubFilterOptions = [
    { id: 'all', displayValue: 'All' },
    { id: 'daily', displayValue: 'Daily' },
    { id: 'weekly', displayValue: 'Weekly' },
    { id: 'monthly', displayValue: 'Monthly' },
    { id: 'featured', displayValue: 'Featured' },
];
const TournamentsListSubFilters = ({ hiddenTournamentCount = 0 }) => {
    var _a;
    const theme = useTheme();
    const { filterState, updateFilterState } = useTournamentFilterState();
    // This must match the desktop threshold inside Header.tsx
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isScrollingDownPastThreshold = useScrollTrigger({
        threshold: 430,
    });
    const shouldBeSticky = !isMobile || !isScrollingDownPastThreshold;
    return (_jsx(Container, { id: "tournament-category-list-filters", className: classnames({
            stickyContainer: shouldBeSticky,
            stickyContainerDesktop: !isMobile && shouldBeSticky,
        }), children: _jsxs(Flex, { flexDirection: "row", align: "center", gap: 0, wrap: "wrap", children: [_jsx(ExclusiveFilterBox, { options: tournamentsListSubFilterOptions, selectedId: ((_a = filterState.requiringHiddenCount) === null || _a === void 0 ? void 0 : _a.category) || 'all', onSelect: (id) => {
                        const selectedOption = tournamentsListSubFilterOptions.find((o) => o.id === id);
                        if (selectedOption) {
                            if (selectedOption.id !== 'all') {
                                updateFilterState({ requiringHiddenCount: { category: selectedOption.id } });
                            }
                            else {
                                updateFilterState({ requiringHiddenCount: undefined });
                            }
                            trackHotjarEvent(`Search tournament ${selectedOption.id}`);
                        }
                    } }), hiddenTournamentCount > 0 && (_jsxs(HelperText, { children: [_jsx("span", { children: `${hiddenTournamentCount} tournaments hidden by this filter` }), _jsx(LinkButton, { appearance: "text", onClick: () => {
                                updateFilterState({ requiringHiddenCount: undefined });
                            }, children: "View All" })] }))] }) }));
};
export default TournamentsListSubFilters;
