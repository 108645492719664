/**
 * See: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 * See: https://gist.github.com/babakness/faca3b633bc23d9a0924efb069c9f1f5
 */
import { useEffect, useRef } from 'react';
const useInterval = (callback, delay) => {
    const savedCallback = useRef(null);
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback.current !== null) {
                savedCallback.current();
            }
        }
        const id = setInterval(tick, delay);
        return () => {
            savedCallback.current = null;
            clearInterval(id);
        };
    }, [delay]);
};
export default useInterval;
