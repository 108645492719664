import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    imageStyle: {
        height: '10px',
        alignSelf: 'center',
    },
}));
const PodiumIcon = ({ className, optimise = false, title }) => {
    const { classes, cx } = useStyles();
    return optimise ? (_jsx("img", { className: cx(classes.imageStyle, className), src: "/img/icons/podiumIcon.svg", alt: title || 'podium' })) : (_jsxs("svg", { viewBox: "0 0 17 10", xmlns: "http://www.w3.org/2000/svg", className: className, children: [title && _jsx("title", { children: title }), _jsx("path", { d: "M10.506 0H5.603a.35.35 0 00-.35.35V.7a.35.35 0 00.35.35h4.903a.35.35 0 00.35-.35V.35a.35.35 0 00-.35-.35zm-.35 1.926H5.953a.35.35 0 00-.35.35V9.28a.35.35 0 00.35.35h4.203a.35.35 0 00.35-.35V2.276a.35.35 0 00-.35-.35zM8.667 7.004a.438.438 0 01-.875 0v-1.73a.438.438 0 01-.35-.802 1.25 1.25 0 00.437-.371.438.438 0 01.778.276l.01 2.627zM4.728 4.027H.7a.35.35 0 00-.35.35V9.28a.35.35 0 00.35.35h4.028a.175.175 0 00.175-.175V4.202a.175.175 0 00-.175-.175zM3.502 8.58H1.751a.434.434 0 01-.308-.746c.09-.091.22-.207.375-.35.35-.305 1.173-1.02 1.127-1.356-.031-.213-.329-.203-.35-.2-.021.004-.284.029-.287.218a.448.448 0 01-.455.424.438.438 0 01-.42-.452 1.107 1.107 0 011.116-1.05 1.145 1.145 0 011.261.973c.084.606-.396 1.194-.917 1.684h.609a.438.438 0 010 .876V8.58zM4.728 2.1H.35a.35.35 0 00-.35.35v.35a.35.35 0 00.35.35h4.378a.175.175 0 00.175-.174v-.7a.175.175 0 00-.175-.176zm11.03 0h-4.377a.175.175 0 00-.175.175v.7a.175.175 0 00.175.176h4.378a.35.35 0 00.35-.35v-.35a.35.35 0 00-.35-.35zm-.35 1.926h-4.027a.175.175 0 00-.175.175v5.253a.175.175 0 00.175.175h4.028a.35.35 0 00.35-.35V4.377a.35.35 0 00-.35-.35zm-.875 4.027a1.432 1.432 0 01-1.141.533 1.66 1.66 0 01-.263-.021 1.18 1.18 0 01-.963-.802.437.437 0 11.837-.256.32.32 0 00.273.193.599.599 0 00.553-.168.406.406 0 00.028-.456.298.298 0 00-.325-.136.438.438 0 01-.42-.7l.223-.302h-.486a.438.438 0 110-.875h1.376a.438.438 0 01.35.7l-.35.459c.159.098.292.233.389.392a1.282 1.282 0 01-.08 1.44z" })] }));
};
export default PodiumIcon;
