import initFirebase from '@helpers/initFirebase';
import { captureException } from '@sentry/react';
import firebase from 'firebase/compat/app';
/**
 * Returns the default firebase.auth() instance.
 * If the default firebase.app is not initialized,
 * this initializes firebase fires.
 *
 * @deprecated - 18/10/21 - Preferred to use the library directly
 *
 * @returns {firebase.auth.Auth} The initialized firebase auth instance
 * @throws A FirebaseError, see: https://firebase.google.com/docs/reference/js/firebase.FirebaseError
 */
const getFirebaseAuthInstance = () => {
    try {
        return firebase.auth();
    }
    catch (error) {
        const { code } = error;
        if (code && code === 'app/no-app') {
            const app = initFirebase();
            return firebase.auth(app);
        }
        captureException(error);
        throw error;
    }
};
export default getFirebaseAuthInstance;
