import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import GoToPageIcon from './GoToPageIcon';
import ErrorIcon from './ErrorIcon';
import TrophyIcon from './TrophyIcon';
import TrophyRibbonIcon from './TrophyRibbonIcon';
import HeartOutlineIcon from './HeartOutlineIcon';
import HeartFilledIcon from './HeartFilledIcon';
import HourGlassIcon from './HourGlassIcon';
import StarIcon from './StarIcon';
import ChevronRight from './ChevronRight';
import ChevronLeft from './ChevronLeft';
import ReloadIcon from './ReloadIcon';
import LoadingIcon from './LoadingIcon';
import PodiumIcon from './PodiumIcon';
import SwordIcon from './SwordIcon';
import Coin from './Coin';
import GemBag from './GemBag';
import MapPin from './MapPin';
import MapPinDropped from './MapPinDropped';
import PlayIcon from './PlayIcon';
import LongArrowRight from './LongArrowRight';
import CancelCircle from './CancelCircle';
import CheckCircle from './CheckCircle';
import Sparkle from './Sparkle';
import SteamLogo from './SteamLogo';
import EyeIcon from './EyeIcon';
import Controller from './Controller';
import GiftBox from './GiftBox';
import Ribbon from './Ribbon';
import Server from './Server';
import ModernController from './ModernController';
import Twitch from './Twitch';
import DeleteIcon from './DeleteIcon';
import MobileIcon from './MobileIcon';
import { PcIcon, PSNIcon, XboxOneIcon } from './Platforms';
import { CallOfDuty, Dota2, Fortnite, Globe, LeagueOfLegends, Valorant, Profile, BrawlStars, GoalScored, Destiny2, RocketLeague, NintendoSwitch, } from '.';
import ListViewIcon from './ListViewIcon';
import GridViewIcon from './GridViewIcon';
import Twitter from './Twitter';
import YouTube from './YouTube';
import KeyboardAndMouse from './KeyboardAndMouse';
import Touch from './Touch';
import RepeatLogo from './Brand/RepeatLogo';
import RepeatText from './Brand/RepeatText';
import Instagram from './Instagram';
import Battlenet from './Battlenet';
import Coins from './Coins';
import Dollar from './Dollar';
import CheckShield from './CheckShield';
import Padlock from './Padlock';
import CartDuoColour from './CartDuoColour';
import SearchIcon from './SearchIcon';
import Google from './Google';
import StarOutline from './StarOutlineIcon';
import Bell from './Bell';
import Danger from './Danger';
import Discovery from './Discovery';
import Wallet from './Wallet';
import Scan from './Scan';
import Message from './Message';
import Facebook from './Facebook';
import Pubg from './Pubg';
import VerifiedCheckmark from '../../components/UI/UserVerificationBadge/VerifiedCheckmark';
import Clock from './Clock';
import AvatarCosmetic from './AvatarCosmetic';
import BackgroundCosmetic from './BackgroundCosmetic';
import BorderCosmetic from './BorderCosmetic';
import CallOfDutyWarzone2 from './CallOfDutyWarzone2';
import MLBTheShow23 from './MLBTheShow23';
/** @deprecated - use symfony/assets/js/react/components/UI/Icon/Icon.tsx */
const Icon = ({ icon, className = '', optimise = false, title, color }) => {
    switch (icon.toLowerCase()) {
        case 'avatarcosmetic':
            return _jsx(AvatarCosmetic, { className: className, title: title, color: color });
        case 'backgroundcosmetic':
            return _jsx(BackgroundCosmetic, { className: className, title: title, color: color });
        case 'bell':
            return _jsx(Bell, { className: className, optimise: optimise, title: title });
        case 'bordercosmetic':
            return _jsx(BorderCosmetic, { className: className, title: title, color: color });
        case 'cartduo':
            return _jsx(CartDuoColour, { className: className });
        case 'clock':
            return _jsx(Clock, { className: className, optimise: optimise, title: title, color: color });
        case 'danger':
            return _jsx(Danger, { className: className, optimise: optimise, title: title, color: color });
        case 'discovery':
            return _jsx(Discovery, { className: className, color: color, optimise: optimise });
        case 'padlock':
            return _jsx(Padlock, { className: className });
        case 'checkshield':
            return _jsx(CheckShield, { className: className, title: title });
        case 'gotopage':
            return _jsx(GoToPageIcon, { className: className });
        case 'error':
            return _jsx(ErrorIcon, { className: className });
        case 'trophy':
            return _jsx(TrophyIcon, { className: className, title: title, optimise: optimise, color: color });
        case 'trophyribbon':
            return _jsx(TrophyRibbonIcon, { className: className, title: title, optimise: optimise });
        case 'heartoutline':
            return _jsx(HeartOutlineIcon, { className: className });
        case 'heartfilled':
            return _jsx(HeartFilledIcon, { className: className, title: title, optimise: optimise });
        case 'hourglass':
            return _jsx(HourGlassIcon, { className: className, color: color });
        case 'star':
            return _jsx(StarIcon, { className: className });
        case 'chevronright':
            return _jsx(ChevronRight, { className: className });
        case 'chevronleft':
            return _jsx(ChevronLeft, { className: className, title: title });
        case 'reload':
            return _jsx(ReloadIcon, { className: className });
        case 'loading':
            return _jsx(LoadingIcon, { className: className });
        case 'podium':
            return _jsx(PodiumIcon, { className: className, title: title, optimise: optimise });
        case 'gembag':
            return _jsx(GemBag, { className: className, title: title, optimise: optimise });
        case 'sword':
            return _jsx(SwordIcon, { className: className });
        case 'coin':
            return _jsx(Coin, { className: className, color: color });
        case 'mappin':
            return _jsx(MapPin, { className: className, title: title, optimise: optimise });
        case 'mappindropped':
            return _jsx(MapPinDropped, { className: className });
        case 'message':
            return _jsx(Message, { className: className, title: title, optimise: optimise, color: color });
        case 'play':
            return _jsx(PlayIcon, { className: className });
        case 'longArrowRight':
            return _jsx(LongArrowRight, { className: className });
        case 'scan':
            return _jsx(Scan, { className: className, title: title, optimise: optimise, color: color });
        case 'search':
            return _jsx(SearchIcon, { className: className, title: title });
        case 'staroutline':
            return _jsx(StarOutline, { className: className, color: color });
        case 'steam':
            return _jsx(SteamLogo, { className: className, title: title });
        case 'sparkle':
            return _jsx(Sparkle, { className: className, title: title });
        case 'checkcircle':
            return _jsx(CheckCircle, { className: className, title: title });
        case 'cancelcircle':
            return _jsx(CancelCircle, { className: className, title: title });
        case 'eye':
            return _jsx(EyeIcon, { className: className, title: title, optimise: optimise });
        case 'controller':
            return _jsx(Controller, { className: className, title: title, optimise: optimise });
        case 'giftbox':
            return _jsx(GiftBox, { className: className });
        case 'globe':
            return _jsx(Globe, { className: className, title: title, optimise: optimise });
        case 'keyboardandmouse':
            return _jsx(KeyboardAndMouse, { className: className, title: title, optimise: optimise });
        case 'ribbon':
            return _jsx(Ribbon, { className: className, title: title, optimise: optimise });
        case 'server':
            return _jsx(Server, { className: className, title: title, optimise: optimise });
        case 'moderncontroller':
            return _jsx(ModernController, { className: className, title: title, optimise: optimise });
        case 'touch':
            return _jsx(Touch, { className: className, title: title });
        case 'twitch':
            return _jsx(Twitch, { className: className });
        case 'twitter':
            return _jsx(Twitter, { className: className });
        case 'youtube':
            return _jsx(YouTube, { className: className });
        case 'instagram':
            return _jsx(Instagram, { className: className });
        case 'google':
            return _jsx(Google, { className: className });
        case 'facebook':
            return _jsx(Facebook, { className: className, title: title });
        case 'delete':
            return _jsx(DeleteIcon, { className: className });
        case 'mobile':
            return _jsx(MobileIcon, { className: className, title: title });
        case 'pc':
            return _jsx(PcIcon, { className: className, title: title });
        case 'ps4':
        case 'psn':
        case 'playstation':
            return _jsx(PSNIcon, { className: className, title: title });
        case 'profile':
        case 'verify':
            return _jsx(Profile, { className: className, color: color });
        case 'xboxone':
        case 'xbox':
            return _jsx(XboxOneIcon, { className: className, title: title });
        case 'fortnite':
            return _jsx(Fortnite, { className: className, title: title });
        case 'cod-warzone':
            return _jsx(CallOfDuty, { className: className, title: title });
        case 'cod-warzone-2':
            return _jsx(CallOfDutyWarzone2, { className: className, title: title });
        case 'pc-dota_2':
            return _jsx(Dota2, { className: className, title: title });
        case 'league_of_legends':
            return _jsx(LeagueOfLegends, { className: className, title: title });
        case 'valorant':
            return _jsx(Valorant, { className: className, title: title });
        case 'pubg':
            return _jsx(Pubg, { className: className, title: title, color: color });
        case 'brawl-stars':
            return _jsx(BrawlStars, { className: className, title: title });
        case 'rocket-league':
            return _jsx(RocketLeague, { className: className, title: title });
        case 'mlb-the-show-23':
            return _jsx(MLBTheShow23, { className: className, title: title });
        case 'destiny-2':
            return _jsx(Destiny2, { className: className, title: title });
        case 'listview':
            return _jsx(ListViewIcon, { className: className, title: title });
        case 'gridview':
            return _jsx(GridViewIcon, { className: className, title: title });
        case 'battlenet':
            return _jsx(Battlenet, { className: className, title: title });
        case 'repeat':
            return _jsx(RepeatLogo, { className: className });
        case 'repeatText':
            return _jsx(RepeatText, { className: className });
        case 'coins':
            return _jsx(Coins, { className: className });
        case 'dollar':
            return _jsx(Dollar, { className: className });
        case 'verifiedusercheckmark':
        case 'badge':
            return _jsx(VerifiedCheckmark, { className: className, variant: "verified" });
        case 'verifiedpartnercheckmark':
            return _jsx(VerifiedCheckmark, { className: className, variant: "partner" });
        case 'wallet':
            return _jsx(Wallet, { className: className, optimise: optimise, title: title, color: color });
        case 'goalscored':
            return _jsx(GoalScored, { className: className, title: title });
        case 'nintendo-switch':
        case 'switch':
            return _jsx(NintendoSwitch, { className: className, title: title });
        default:
            return _jsx(_Fragment, {});
    }
};
export default Icon;
