import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 *
 * This looks like an envelope. Often used for email
 * ✉️
 */
const Message = ({ title, className, optimise, color }) => {
    return optimise ? (_jsx("img", { className: className, src: "/img/icons/message.svg", alt: title || 'Message' })) : (_jsxs("svg", { className: className, viewBox: "0 0 20 19", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [title && _jsx("title", { children: title }), _jsx("path", { d: "M14.8288 6.30603L10.9188 9.45411C10.1789 10.0342 9.14163 10.0342 8.40168 9.45411L4.45813 6.30603", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.31392 1.20831H13.9562C15.2023 1.22229 16.3883 1.74908 17.238 2.666C18.0878 3.58292 18.527 4.80992 18.4535 6.06125V12.0451C18.527 13.2965 18.0878 14.5235 17.238 15.4404C16.3883 16.3573 15.2023 16.8841 13.9562 16.8981H5.31392C2.63734 16.8981 0.833374 14.7206 0.833374 12.0451V6.06125C0.833374 3.38581 2.63734 1.20831 5.31392 1.20831Z", stroke: color, strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })] }));
};
export default Message;
