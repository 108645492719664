import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Icon from '@components/Icons/Icon';
import { Tooltip } from '@mui/material';
function shouldForwardProp(propName) {
    return propName !== 'palette';
}
const TagContainer = styled('div', { shouldForwardProp }) `
  display: flex;
  background: ${({ theme, palette }) => (palette ? theme.palette[palette].main : theme.palette.grey[100])};
  border-radius: 36px;
  padding: ${(props) => props.theme.spacing(0.5, 1)};
  margin-right: ${(props) => props.theme.spacing(0.5)};
  margin-top: ${(props) => props.theme.spacing(0.5)};
  & > * + * {
    padding-left: ${(props) => props.theme.spacing(0.5)};
  }
`;
const StyledIcon = styled(Icon) `
  text-align: end;
  width: 12px;
  max-height: 12px;
  margin-right: 0px;
  fill: ${(props) => props.theme.palette.grey[700]};
  margin-top: auto;
  margin-bottom: auto;
`;
const Label = styled('span', { shouldForwardProp }) `
  font-weight: 600;
  color: ${({ theme, palette }) => (palette ? theme.palette.common.white : theme.palette.grey[700])};
  text-transform: capitalize;
`;
const Tag = ({ tagData: { icon, label, palette, tooltip = '' }, className }) => (_jsx(Tooltip, { arrow: true, placement: "top", title: tooltip, children: _jsxs(TagContainer, { className: className, palette: palette, children: [icon && _jsx(StyledIcon, { icon: icon, optimise: true }), label && _jsx(Label, { palette: palette, children: label })] }) }));
export default Tag;
