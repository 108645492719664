function isErrorWithMessage(error) {
    return (typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof error.message === 'string');
}
function toErrorWithMessage(maybeError) {
    if (isErrorWithMessage(maybeError))
        return maybeError;
    try {
        return new Error(JSON.stringify(maybeError));
    }
    catch {
        // fallback in case there's an error stringifying the maybeError
        // like with circular references for example.
        return new Error(String(maybeError));
    }
}
export const getErrorMessage = (error) => {
    return toErrorWithMessage(error).message;
};
/**
 * Indicates whether or not the given object is an Axios error.
 *
 * @param error The object to test
 * @returns True if the object is an Axios error, otherwise false.
 */
export function isAxiosError(error) {
    return error === null || error === void 0 ? void 0 : error.isAxiosError;
}
export const displayMessageFromError = (error) => {
    var _a, _b, _c, _d, _e, _f;
    if (isAxiosError(error)) {
        return (_f = (_c = (_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.result) !== null && _c !== void 0 ? _c : (_e = (_d = error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.message) !== null && _f !== void 0 ? _f : error.message;
    }
    return getErrorMessage(error);
};
