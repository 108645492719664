import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import getGameName from '@helpers/getGameName';
import getGamePalette from '@helpers/getGamePalette';
import useAddGameBreadcrumbManager from '@hooks/useAddGameBreadcrumbManager';
import { ModalBody, ModalBreadcrumb, ModalHeader } from '@components/Modals';
import GameIcon from '@components/Icons/GameIcon';
import ContentCarousel from '../../UI/ContentCarousel';
import ContentInstruction from './ContentInstruction';
import { useAddGameContext } from '../context/AddGameProvider';
import AddGameConnectStatus from '../AddGameConnectStatus';
import AddGameInterstitial from '../AddGameInterstitial';
import AddGameOAuthConnectContent from '../AddGameOAuthConnectContent';
import useRemoveAddGameParams from '../../../Hooks/useRemoveAddGameParams';
import useHandleAddGameEvents from '../hooks/useHandleAddGameEvents';
import useSetDefaultGameService from '../hooks/useSetDefaultGameService';
import getAnalyticsFlowStep from '@domain/Analytics/helpers/getAnalyticsFlowStep';
const gameKey = 'rocket-league';
const gameStepBreadcrumbs = [
    {
        id: 'public-profile',
        component: 'Public',
        analyticsFlowStep: 'update profile settings',
    },
    {
        id: 'oauth-connect',
        component: 'Connect',
        analyticsFlowStep: 'connect game',
    },
];
const AddRocketLeague = ({ onStepChange, onConnectConfirm, shouldShowSelectBreadcrumb = true, onBackToSelect, successButtonLabel = 'Continue', failButtonLabel = 'Retry', onClose, }) => {
    const breadcrumbStepOffset = shouldShowSelectBreadcrumb ? 1 : 0;
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const removeAddGameParams = useRemoveAddGameParams();
    const addGameContext = useAddGameContext();
    const { requireAgePrompt, dispatch, availableGameServices, isProviderReady, gameConnectionStatus, fetched } = addGameContext;
    // NOTE: Age check for breadcrumb is managed by the hook below.
    const { breadcrumbIndex, breadcrumbs, breadcrumbIsVisible, setBreadcrumbIsVisible, displayBreadcrumbsUpTo } = useAddGameBreadcrumbManager({
        gameStepBreadcrumbs,
        startingIndex: breadcrumbStepOffset,
        includeSelectBreadcrumb: shouldShowSelectBreadcrumb,
        requireAgePrompt,
    });
    useHandleAddGameEvents({
        state: addGameContext,
        onAddGameError: () => setCurrentStepIndex(-1),
        onAddGameSuccess: () => setCurrentStepIndex(-1),
    });
    const handleBreadcrumbClick = (idx, breadcrumb) => {
        if (breadcrumb.id === 'select') {
            // Invoke onBackToSelect to dismiss current view
            if (onBackToSelect) {
                onBackToSelect(gameKey);
            }
        }
        else if (idx >= breadcrumbStepOffset) {
            setCurrentStepIndex(idx - breadcrumbStepOffset);
        }
    };
    const handleStepChange = (changedStepIndex, stepName) => {
        if (stepName === 'game-connect-status') {
            setBreadcrumbIsVisible(false);
        }
        else {
            setBreadcrumbIsVisible(true);
            displayBreadcrumbsUpTo(stepName);
        }
        if (onStepChange) {
            const analyticsFlowStep = getAnalyticsFlowStep(gameConnectionStatus, changedStepIndex, gameStepBreadcrumbs);
            onStepChange(changedStepIndex, stepName, analyticsFlowStep, gameStepBreadcrumbs.length);
        }
    };
    const handleContinue = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const handleConnectSuccessClick = () => {
        if (onConnectConfirm) {
            onConnectConfirm();
        }
    };
    const handleConnectRetryClick = () => {
        removeAddGameParams();
        setCurrentStepIndex(-2); // Second last step. ContentCarousel accepts negative index, which goes from the back.
    };
    const handleClose = () => {
        const isSuccessPage = breadcrumbIndex === -1;
        if (onClose) {
            onClose(gameConnectionStatus === 'success', isSuccessPage);
        }
    };
    useSetDefaultGameService();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: _jsx("span", { "data-ignore": true, children: getGameName(gameKey) }), icon: _jsx(GameIcon, { game: gameKey, showGameColor: true }), subtitle: "Connect your account", paletteName: getGamePalette(gameKey), onClose: handleClose }), _jsx(ModalBreadcrumb, { currentIndex: breadcrumbIndex, breadcrumbs: breadcrumbs, onClick: handleBreadcrumbClick, isVisible: breadcrumbIsVisible }), _jsxs(ModalBody, { children: [isProviderReady && (_jsxs(ContentCarousel, { name: "rocket-league", currentStep: currentStepIndex, onStepChange: handleStepChange, children: [_jsx(ContentInstruction, { stepName: "public-profile", onContinue: handleContinue }), _jsx(AddGameOAuthConnectContent, { stepName: "oauth-connect", paletteName: "rocketLeague" }), _jsx(AddGameConnectStatus, { onConfirm: handleConnectSuccessClick, onRetry: handleConnectRetryClick, status: gameConnectionStatus || 'error', gameKey: gameKey, successButtonLabel: successButtonLabel, failButtonLabel: failButtonLabel, errorMessage: (fetched === null || fetched === void 0 ? void 0 : fetched.isError) ? fetched.errorMessage : undefined, stepName: "game-connect-status" })] })), !isProviderReady && _jsx(AddGameInterstitial, {})] })] }));
};
export default AddRocketLeague;
