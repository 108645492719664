import { useEffect, useRef } from 'react';
export const useTimeout = (callback, delay, deps) => {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef();
    const cancelTimeout = () => clearTimeout(timeoutRef.current);
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
    useEffect(() => {
        timeoutRef.current = setTimeout(callbackRef.current, delay);
        return () => clearTimeout(timeoutRef.current);
    }, deps);
    return cancelTimeout;
};
export default useTimeout;
