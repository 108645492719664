/**
 * Decides what kind of ads (if any) should be displayed in the Tournament Details Sidebar
 *
 * @param tournament The tournament
 * @param isNewUser Don't ever show external ads to new users
 * @param adsenseEnabled the adsense feature flag
 * @param adsterraEnabled the adsterra feature flag
 * @returns The type of ads that should be displayed
 */
const getTournamentSidebarAdType = (tournament, isNewUser, adsenseEnabled, adsterraEnabled) => {
    var _a;
    // Always try to show external ads for unbranded tournaments
    if (!((_a = tournament.brand) === null || _a === void 0 ? void 0 : _a.name) && !isNewUser) {
        if (adsenseEnabled) {
            return 'adsense';
        }
        if (adsterraEnabled) {
            return 'adsterra';
        }
    }
    const { ads = {} } = tournament;
    const tournamentDetailAds = Array.isArray(ads) ? {} : ads;
    // Insert our own banners on branded tournaments if on mobile
    if (tournamentDetailAds.left_a || tournamentDetailAds.left_b) {
        return 'internal';
    }
    return 'none';
};
export default getTournamentSidebarAdType;
