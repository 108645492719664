import useGamesList from '@hooks/API/useGamesList';
import { getSelectedGameData } from '@helpers';
import { useEffect, useRef } from 'react';
/**
 * Used by AddGameProvider - This custom hook sets the selected game data based on selected game key
 * @param callback - The callback is invoked when a valid game data is selected.
 * @param selectedGameKey - If not undefined and is a valid game key, it'll find the selected game and invoke the callback
 */
const useSelectGameBasedOnGameKey = (callback, selectedGameKey) => {
    const { games } = useGamesList();
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        if (selectedGameKey && games.length > 0) {
            const selectedGameData = getSelectedGameData(games, selectedGameKey);
            if (selectedGameData && isMountedRef.current) {
                callback(selectedGameData);
            }
        }
        return () => {
            isMountedRef.current = false;
        };
    }, [selectedGameKey, JSON.stringify(games)]);
};
export default useSelectGameBasedOnGameKey;
