import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Activision, ApexLegends, ArrowRight, Bag, Bank, Battlenet, Bell, BrawlStars, CallOfDuty, CancelCircle, CaretDown, Cart, CartDuoColour, Category, Chat, CheckCircle, CheckShield, ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Clock, Coin, Coins, CoinSparkle, Controller, ControllerXBox, Counterstrike, CreditCard, Danger, Delete, Discord, Discovery, Dollar, Dota2, Ellipsis, Ellipse, EpicGames, Error, ErrorFill, Eye, Facebook, Filter, FilterDuo, Fortnite, Fullscreen, Gear, GemBag, GiftBox, Globe, Google, GoToPage, GridView, HamburgerMenu, HeartFilled, Hearthstone, HeartOutline, Home, HourGlass, InfoCircle, Instagram, KeyboardAndMouse, LeagueOfLegends, ListView, Loading, LongArrowRight, MapPin, MapPinDropped, Message, Mobile, ModernController, Overwatch, Padlock, PartnerIcon, PayPal, Pc, Pin, Play, Podium, Profile, Playstation, Pubg, QuestionCircle, RainbowSix, Rectangle, RectangleStack, Reddit, Reload, RepeatLogo, RepeatText, Ribbon, Scan, Search, Server, Sort, SortActiveASC, SortActiveDESC, SortInactive, Sparkle, Star, StarOutline, StarTick, Steam, Stopwatch, Sword, Tab, Tick, Touch, Trophy, TrophyPersonSparkle, TrophyRibbon, Twitch, Twitter, Valorant, VerifiedIcon, Wallet, WorldOfTanks, XboxOne, YouTube, Destiny2, Bungie, Nintendo, NintendoSwitch, RocketLeague, } from './Icons';
import PubgMobile from './Icons/PubgMobile';
import RiotGames from './Icons/RiotGames';
import MLBTheShowService from './Icons/MLBTheShowService';
/** @deprecated - prefer importing the icon directly */
const Icon = ({ color = 'grey', height, icon, strength = 'main', width, title, className }) => {
    switch (icon.toLowerCase()) {
        case 'activision':
            return _jsx(Activision, { color: color, height: height, strength: strength, width: width });
        case 'apexlegends':
            return _jsx(ApexLegends, { color: color, height: height, strength: strength, width: width });
        case 'arrowright':
            return _jsx(ArrowRight, { color: color, height: height, strength: strength, width: width });
        case 'bag':
            return _jsx(Bag, { color: color, height: height, strength: strength, width: width });
        case 'bank':
            return _jsx(Bank, { color: color, height: height, strength: strength, width: width });
        case 'battlenet':
            return _jsx(Battlenet, { height: height, width: width });
        case 'bell':
            return _jsx(Bell, { color: color, height: height, strength: strength, width: width });
        case 'supercell':
        case 'brawlstars':
        case 'brawl-stars':
            return _jsx(BrawlStars, { color: color, height: height, strength: strength, width: width });
        case 'callofduty':
        case 'cod-warzone':
            return _jsx(CallOfDuty, { color: color, height: height, strength: strength, width: width });
        case 'cancelcircle':
            return _jsx(CancelCircle, { color: color, height: height, strength: strength, width: width, className: className });
        case 'caretdown':
            return _jsx(CaretDown, { color: color, height: height, strength: strength, width: width });
        case 'cart':
            return _jsx(Cart, { color: color, height: height, strength: strength, width: width });
        case 'cartduocolour':
            return _jsx(CartDuoColour, { color: color, height: height, strength: strength, width: width });
        case 'category':
            return _jsx(Category, { color: color, height: height, strength: strength, width: width });
        case 'chat':
            return _jsx(Chat, { color: color, height: height, strength: strength, width: width });
        case 'checkcircle':
            return _jsx(CheckCircle, { color: color, height: height, strength: strength, width: width });
        case 'checkshield':
            return _jsx(CheckShield, { color: color, height: height, strength: strength, width: width });
        case 'chevrondown':
            return _jsx(ChevronDown, { color: color, height: height, strength: strength, width: width, className: className });
        case 'chevronleft':
            return _jsx(ChevronLeft, { color: color, height: height, strength: strength, width: width });
        case 'chevronright':
            return _jsx(ChevronRight, { color: color, height: height, strength: strength, width: width, className: className });
        case 'chevronup':
            return _jsx(ChevronUp, { color: color, height: height, strength: strength, width: width });
        case 'clock':
            return _jsx(Clock, { color: color, height: height, strength: strength, width: width });
        case 'coin':
            return _jsx(Coin, { color: color, height: height, strength: strength, width: width });
        case 'coins':
            return _jsx(Coins, { color: color, height: height, strength: strength, width: width });
        case 'coinsparkle':
            return _jsx(CoinSparkle, { color: color, height: height, strength: strength, width: width });
        case 'controller':
            return _jsx(Controller, { color: color, height: height, strength: strength, width: width });
        case 'controllerxbox':
            return _jsx(ControllerXBox, { color: color, height: height, strength: strength, width: width });
        case 'counterstrike':
            return _jsx(Counterstrike, { color: color, height: height, strength: strength, width: width });
        case 'creditcard':
            return _jsx(CreditCard, { color: color, height: height, strength: strength, width: width });
        case 'danger':
            return _jsx(Danger, { color: color, height: height, strength: strength, width: width });
        case 'delete':
            return _jsx(Delete, { color: color, height: height, strength: strength, width: width });
        case 'destiny-2':
            return _jsx(Destiny2, { color: color, height: height, strength: strength, width: width });
        case 'discord':
            return _jsx(Discord, { color: color, height: height, strength: strength, width: width });
        case 'discovery':
            return _jsx(Discovery, { color: color, height: height, strength: strength, width: width });
        case 'dollar':
            return _jsx(Dollar, { color: color, height: height, strength: strength, width: width });
        case 'dota2':
        case 'pc-dota_2':
            return _jsx(Dota2, { color: color, height: height, strength: strength, width: width });
        case 'ellipse':
            return _jsx(Ellipse, { color: color, height: height, strength: strength, width: width, className: className });
        case 'ellipsis':
            return _jsx(Ellipsis, { color: color, height: height, strength: strength, width: width });
        case 'epic': // Alias for the epic game service key
        case 'epicgames':
            return _jsx(EpicGames, { color: color, height: height, strength: strength, width: width });
        case 'error':
            return _jsx(Error, { color: color, height: height, strength: strength, width: width });
        case 'errorfill':
            return _jsx(ErrorFill, { color: color, height: height, strength: strength, width: width });
        case 'eye':
            return _jsx(Eye, { color: color, height: height, strength: strength, width: width, title: title });
        case 'facebook':
            return _jsx(Facebook, { color: color, height: height, strength: strength, width: width });
        case 'filter':
            return _jsx(Filter, { color: color, height: height, strength: strength, width: width });
        case 'filterduo':
            return _jsx(FilterDuo, { color: color, height: height, strength: strength, width: width });
        case 'fortnite':
            return _jsx(Fortnite, { color: color, height: height, strength: strength, width: width });
        case 'fullscreen':
            return _jsx(Fullscreen, { color: color, height: height, strength: strength, width: width });
        case 'gear':
            return _jsx(Gear, { color: color, height: height, strength: strength, width: width });
        case 'gembag':
            return _jsx(GemBag, { color: color, height: height, strength: strength, width: width });
        case 'giftbox':
            return _jsx(GiftBox, { color: color, height: height, strength: strength, width: width });
        case 'globe':
            return _jsx(Globe, { color: color, height: height, strength: strength, width: width });
        case 'google':
            return _jsx(Google, { color: color, height: height, strength: strength, width: width });
        case 'gotopage':
            return _jsx(GoToPage, { color: color, height: height, strength: strength, width: width });
        case 'gridview':
            return _jsx(GridView, { color: color, height: height, strength: strength, width: width });
        case 'hamburgermenu':
            return _jsx(HamburgerMenu, { color: color, height: height, strength: strength, width: width });
        case 'heartfilled':
            return _jsx(HeartFilled, { color: color, height: height, strength: strength, width: width });
        case 'hearthstone':
            return _jsx(Hearthstone, { color: color, height: height, strength: strength, width: width });
        case 'heartoutline':
            return _jsx(HeartOutline, { color: color, height: height, strength: strength, width: width });
        case 'home':
            return _jsx(Home, { color: color, height: height, strength: strength, width: width });
        case 'hourglass':
            return _jsx(HourGlass, { color: color, height: height, strength: strength, width: width });
        case 'infocircle':
            return _jsx(InfoCircle, { color: color, height: height, strength: strength, width: width });
        case 'instagram':
            return _jsx(Instagram, { color: color, height: height, strength: strength, width: width });
        case 'keyboardandmouse':
            return _jsx(KeyboardAndMouse, { color: color, height: height, strength: strength, width: width });
        case 'leagueoflegends':
        case 'league_of_legends':
            return _jsx(LeagueOfLegends, { color: color, height: height, strength: strength, width: width });
        case 'listview':
            return _jsx(ListView, { color: color, height: height, strength: strength, width: width });
        case 'loading':
            return _jsx(Loading, { color: color, height: height, strength: strength, width: width });
        case 'longarrowright':
            return _jsx(LongArrowRight, { color: color, height: height, strength: strength, width: width });
        case 'mappin':
            return _jsx(MapPin, { color: color, height: height, strength: strength, width: width });
        case 'mappindropped':
            return _jsx(MapPinDropped, { color: color, height: height, strength: strength, width: width });
        case 'message':
            return _jsx(Message, { color: color, height: height, strength: strength, width: width });
        case 'mobile':
            return _jsx(Mobile, { color: color, height: height, strength: strength, width: width, title: title });
        case 'moderncontroller':
            return _jsx(ModernController, { color: color, height: height, strength: strength, width: width });
        case 'overwatch':
            return _jsx(Overwatch, { color: color, height: height, strength: strength, width: width });
        case 'padlock':
            return _jsx(Padlock, { color: color, height: height, strength: strength, width: width, title: title });
        case 'verifiedpartnercheckmark':
        case 'partnereduser':
        case 'partneruser':
        case 'partneredcheckmark':
        case 'partnercheckmark':
            return _jsx(PartnerIcon, { color: color, height: height, strength: strength, width: width });
        case 'paypal':
            return _jsx(PayPal, { color: color, height: height, strength: strength, width: width });
        case 'pc':
            return _jsx(Pc, { color: color, height: height, strength: strength, width: width, title: title });
        case 'pin':
            return _jsx(Pin, { color: color, height: height, strength: strength, width: width });
        case 'play':
            return _jsx(Play, { color: color, height: height, strength: strength, width: width });
        case 'podium':
            return _jsx(Podium, { color: color, height: height, strength: strength, width: width });
        case 'profile':
            return _jsx(Profile, { color: color, height: height, strength: strength, width: width });
        case 'psn': // Alias for the game service key
        case 'ps4':
        case 'playstation':
            return _jsx(Playstation, { strength: strength, height: height, width: width, title: title });
        case 'pubg':
            return _jsx(Pubg, { color: color, height: height, strength: strength, width: width });
        case 'pubg-mobile':
            return _jsx(PubgMobile, { color: color, height: height, strength: strength, width: width });
        case 'questioncircle':
            return _jsx(QuestionCircle, { color: color, height: height, strength: strength, width: width });
        case 'rainbowsix':
            return _jsx(RainbowSix, { color: color, height: height, strength: strength, width: width });
        case 'rectangle':
            return _jsx(Rectangle, { color: color, height: height, strength: strength, width: width });
        case 'rectanglestack':
            return _jsx(RectangleStack, { color: color, height: height, strength: strength, width: width });
        case 'reddit':
            return _jsx(Reddit, { color: color, height: height, strength: strength, width: width });
        case 'reload':
            return _jsx(Reload, { color: color, height: height, strength: strength, width: width });
        case 'repeat':
        case 'repeatlogo':
            return _jsx(RepeatLogo, { color: color, height: height, strength: strength, width: width });
        case 'repeattext':
            return _jsx(RepeatText, { color: color, height: height, strength: strength, width: width });
        case 'ribbon':
            return _jsx(Ribbon, { color: color, height: height, strength: strength, width: width });
        case 'scan':
            return _jsx(Scan, { color: color, height: height, strength: strength, width: width });
        case 'search':
            return _jsx(Search, { color: color, height: height, strength: strength, width: width });
        case 'server':
            return _jsx(Server, { color: color, height: height, strength: strength, width: width });
        case 'sort':
            return _jsx(Sort, { color: color, height: height, strength: strength, width: width });
        case 'sortactiveasc':
            return _jsx(SortActiveASC, { color: color, height: height, strength: strength, width: width });
        case 'sortactivedesc':
            return _jsx(SortActiveDESC, { color: color, height: height, strength: strength, width: width });
        case 'sortinactive':
            return _jsx(SortInactive, { color: color, height: height, strength: strength, width: width });
        case 'sparkle':
            return _jsx(Sparkle, { color: color, height: height, strength: strength, width: width });
        case 'riot':
            return _jsx(RiotGames, { color: color, height: height, strength: strength, width: width });
        case 'star':
            return _jsx(Star, { color: color, height: height, strength: strength, width: width });
        case 'staroutline':
            return _jsx(StarOutline, { color: color, height: height, strength: strength, width: width });
        case 'startick':
            return _jsx(StarTick, { color: color, height: height, strength: strength, width: width });
        case 'steam':
        case 'steamlogo':
            return _jsx(Steam, { height: height, width: width });
        case 'stopwatch':
            return _jsx(Stopwatch, { color: color, height: height, strength: strength, width: width });
        case 'sword':
            return _jsx(Sword, { color: color, height: height, strength: strength, width: width });
        case 'tab':
            return _jsx(Tab, { color: color, height: height, strength: strength, width: width });
        case 'tick':
            return _jsx(Tick, { color: color, height: height, strength: strength, width: width, title: title });
        case 'touch':
            return _jsx(Touch, { color: color, height: height, strength: strength, width: width });
        case 'trophy':
            return _jsx(Trophy, { color: color, height: height, strength: strength, width: width });
        case 'trophypersonsparkle':
            return _jsx(TrophyPersonSparkle, { color: color, height: height, strength: strength, width: width });
        case 'trophyribbon':
            return _jsx(TrophyRibbon, { color: color, height: height, strength: strength, width: width });
        case 'twitch':
            return _jsx(Twitch, { color: color, height: height, strength: strength, width: width });
        case 'twitter':
            return _jsx(Twitter, { color: color, height: height, strength: strength, width: width });
        case 'valorant':
            return _jsx(Valorant, { color: color, height: height, strength: strength, width: width });
        case 'verifiedusercheckmark':
        case 'verify':
        case 'verifieduser':
        case 'verifiedcheckmark':
            return _jsx(VerifiedIcon, { color: color, height: height, strength: strength, width: width });
        case 'wallet':
            return _jsx(Wallet, { color: color, height: height, strength: strength, width: width });
        case 'worldoftanks':
            return _jsx(WorldOfTanks, { color: color, height: height, strength: strength, width: width });
        case 'xboxone':
        case 'xboxlive': // Alias for the game service key
            return _jsx(XboxOne, { height: height, width: width, title: title });
        case 'youtube':
            return _jsx(YouTube, { color: color, height: height, strength: strength, width: width });
        case 'bungie':
            return _jsx(Bungie, { color: color, height: height, strength: strength, width: width });
        case 'rocket-league':
            return _jsx(RocketLeague, { color: color, height: height, strength: strength, width: width });
        case 'nintendo':
            return _jsx(Nintendo, { color: color, height: height, strength: strength, width: width });
        case 'nintendo-switch':
            return _jsx(NintendoSwitch, { color: color, height: height, strength: strength, width: width, title: title });
        case 'the-show':
            return _jsx(MLBTheShowService, { color: color, height: height, strength: strength, width: width });
        default:
            return _jsx(_Fragment, {});
    }
};
export default Icon;
