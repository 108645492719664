import isMobileBrowser from './isMobileBrowser';
const isChromiumPowered = () => !!window.chrome;
const isChrome = () => isChromiumPowered() && /Chrome/.test(navigator.userAgent);
const isEdge = () => isChromiumPowered() && /Edg/.test(navigator.userAgent);
const getSupportedBrowserName = () => {
    if (isEdge()) {
        return 'desktopChromiumEdge';
    }
    if (isChrome()) {
        return 'desktopChrome';
    }
    return undefined;
};
export const getBrowserExtensionEnvironment = () => {
    const isMobile = isMobileBrowser();
    const extensionDetectableBrowser = !isMobile ? getSupportedBrowserName() : undefined;
    return { extensionDetectableBrowser, isMobile };
};
export default getBrowserExtensionEnvironment;
