import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { extensionColor }) => ({
    container: {
        fontFamily: 'Montserrat',
        width: '100%',
        maxWidth: '430px',
    },
    closeBtn: {
        color: 'white',
        position: 'absolute',
        top: '0',
        right: '0',
        fontSize: '26px',
    },
    banner: {
        '& img': {
            minHeight: '160px',
            maxHeight: '240px',
            width: '100%',
            objectFit: 'cover',
        },
    },
    content: {
        padding: '0 30px 30px',
        textAlign: 'center',
        '& h2': {
            color: theme.palette.grey[900],
            fontSize: '26px',
            fontWeight: '700',
            margin: '33px 0',
            lineHeight: '33.51px',
        },
        '& p': {
            color: theme.palette.grey[900],
            fontSize: '12px',
            fontWeight: '600',
            margin: '24px 0',
            lineHeight: '23.21px',
        },
        '& img': {
            maxHeight: '154px',
            margin: '55px 0 0',
        },
        '& video': {
            maxHeight: '154px',
            margin: '55px 0 0',
        },
    },
    actionBtn: {
        borderRadius: '4px',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '45px',
        width: '100%',
        fontWeight: 600,
        fontSize: '12px',
        border: `1px solid currentColor`,
        margin: '31px 0',
        background: extensionColor,
        '&>span': {
            paddingRight: theme.spacing(0.5),
        },
    },
    successIconContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        margin: '55px 0 0',
    },
    statusIcon: {
        marginTop: theme.spacing(1),
    },
    success: {
        fill: theme.palette.success.main,
    },
    error: {
        fill: theme.palette.error.main,
    },
    sparkleTopLeft: {
        margiRight: theme.spacing(1),
        transform: 'rotate(180deg)',
    },
    sparkletBottomRight: {
        alignSelf: 'flex-end',
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
}));
export default useStyles;
