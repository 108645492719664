import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppBar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { RepeatText, RepeatLogo } from '@components/Icons/Brand';
import LogoutButton from '@components/Navigation/LogoutButton';
import Skeleton from 'react-loading-skeleton';
import LanguageMenu from '../../Header/components/Menus/LanguageMenu';
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: theme.typography.fontFamily,
        height: '55px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    navContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 2.5),
        height: '55px',
        '& .logo': {
            width: '32px',
            marginRight: theme.spacing(1),
            fill: theme.palette.primary.main,
        },
        '& .logoText': {
            fill: theme.palette.grey[900],
        },
    },
    navigationWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    logoutActions: {
        display: 'flex',
        padding: theme.spacing(1),
        '&.skeleton > span': {
            display: 'flex',
            '& > span:first-child': {
                marginRight: theme.spacing(1),
            },
        },
    },
    button: {
        whiteSpace: 'nowrap',
        borderRadius: '0px',
    },
    callToAction: {
        [theme.breakpoints.up('sm')]: {
            width: '100px',
        },
    },
    logoutButton: {
        border: `2px solid ${theme.palette.grey[100]}`,
        color: theme.palette.grey[300],
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5, 1.5),
        },
        '&:hover, &:active, &:focus': {
            color: theme.palette.primary.main,
            border: `2px solid ${theme.palette.primary.main}`,
        },
    },
    languageMenu: {
        display: 'flex',
        alignItems: 'center',
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        '& button': {
            height: '55px',
            padding: theme.spacing(0, 2),
            border: '0',
        },
    },
}));
const BasicHeader = ({ isLoadingUser }) => {
    const { classes, cx } = useStyles();
    return (_jsx(_Fragment, { children: _jsxs(AppBar, { position: "fixed", color: "inherit", elevation: 0, className: classes.container, children: [_jsx("nav", { className: classes.navContainer, children: _jsxs("div", { className: classes.logoWrapper, children: [_jsx(RepeatLogo, { className: "logo" }), _jsx(RepeatText, { className: "logoText" })] }) }), _jsxs("div", { className: classes.navigationWrapper, children: [_jsx(LanguageMenu, { className: classes.languageMenu }), isLoadingUser && (_jsx("div", { className: cx(classes.logoutActions, 'skeleton'), children: _jsx(Skeleton, { width: 100, height: 45, count: 2 }) })), !isLoadingUser && (_jsx(_Fragment, { children: _jsx("div", { className: classes.logoutActions, children: _jsx(LogoutButton, { className: cx(classes.button, classes.callToAction, classes.logoutButton), variant: "white", appearance: "outline", children: "Logout" }) }) }))] })] }) }));
};
export default BasicHeader;
