import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@components';
import { getGamePalette } from '@helpers';
import LinkedAccountRow from './LinkedAccountRow';
import PublicGameAccount from './PublicGameAccount';
const GameAccountItem = ({ userGameRegion, isCurrentUser, onActionPress }) => {
    var _a;
    const paletteName = getGamePalette(userGameRegion.game.gameKey);
    const subtext = userGameRegion.region + (userGameRegion.platform ? ` | ${userGameRegion.platform.name}` : '');
    if (!isCurrentUser) {
        return _jsx(PublicGameAccount, { userGameRegion: userGameRegion });
    }
    return (_jsx(LinkedAccountRow, { text: userGameRegion.handle, subtext: subtext, icon: userGameRegion.game.gameKey, iconColor: paletteName, badgeIcon: (_a = userGameRegion.platform) === null || _a === void 0 ? void 0 : _a.abbreviation, actionButtonContent: _jsx(Icon, { icon: "delete" }), actionButtonLabel: 'Delete Game', onActionButtonPress: onActionPress }, userGameRegion.id));
};
export default GameAccountItem;
