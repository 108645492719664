/* eslint-disable @typescript-eslint/ban-types */
import { useRef, useEffect } from 'react';
/*
  A hook that will run based on its dependencies after the first render (skips the first render)
*/
const useDidUpdateEffect = (fn, inputs) => {
    const didMountRef = useRef(false);
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
    }, inputs);
};
export default useDidUpdateEffect;
