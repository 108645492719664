import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classnames from 'classnames';
import { CheckCircle, Sparkle } from '../../..';
import ActionButton from './ActionButton';
import useStyles from './useStyles';
const ExtensionInstalledPage = ({ entry, handleSuccess }) => {
    const { classes } = useStyles({ extensionColor: entry.instance_data.color });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.successIconContainer, children: [_jsx(Sparkle, { className: classnames(classes.sparkleTopLeft, classes.success) }), _jsx(CheckCircle, { className: classnames(classes.statusIcon, classes.success), title: "Success" }), _jsx(Sparkle, { className: classnames(classes.sparkletBottomRight, classes.success) })] }), _jsx("h2", { children: "Congratulations" }), _jsxs("p", { children: ["The ", _jsx("span", { "data-ignore": true, children: entry.instance_data.displayName }), " extension was successfully installed. Please collect your free tournament entry!"] }), _jsx(ActionButton, { onClick: () => handleSuccess(), entry: entry, children: "Enter Tournament" })] }));
};
export default ExtensionInstalledPage;
