import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import SectionContainer from '@components/UI/Section/components/SectionContainer';
import CoreButton from '@components/Buttons/CoreButton';
import GameTile from '@components/Buttons/GameTile';
import useUserGamesListener from '@components/Hooks/useUserGamesListener';
import useLocalStorage from '@components/Hooks/useLocalStorage';
import Flex from '@components/Layout/Flex';
import FullLayout from '@components/Layout/FullLayout';
import CircleArrowRight from '@components/UI/Icon/Icons/CircleArrowRight';
import SectionContent from '@components/UI/Section/components/SectionContent';
import { useUserState } from '@context/UserContext';
import { useLocalAppContext } from '@context/LocalAppContext';
import PlatformSelectionMenu from '@domain/Header/components/Menus/PlatformSelectionMenu';
import SearchFilter from '@domain/Tournament/components/Filters/SearchFilter';
import { Grid, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/20/solid';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import usePlatformGamesList from '@hooks/usePlatformGamesList';
import ErrorPage from '@domain/Errors/components/ErrorPage';
import useGamesList from '@hooks/API/useGamesList';
import getMenuGames from '@helpers/getMenuGames';
import useConsumeActionQueryParam from '@hooks/useConsumeActionQueryParam';
import useTrackEvent from '@hooks/useTrackEvent';
import useTrackUserFlow from '@hooks/useTrackUserFlow';
const StyledFullLayout = styled(FullLayout) `
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-auto-flow: row;
  } ;
`;
const HeadingWrapper = styled(Flex) `
  ${({ theme }) => theme.breakpoints.down('header')} {
    padding: ${({ theme }) => theme.spacing(0, 2)};
  }
`;
const StyledSearchFilter = styled(SearchFilter) `
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
  display: flex;
  position: relative;

  input {
    all: unset;
    margin-left: ${({ theme }) => theme.spacing(2)};
    font-size: 15px;
    width: 100%;
  }
`;
const H1 = styled('h1') `
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.palette.grey[900]};
`;
const H2 = styled('h2') `
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.palette.grey[900]};
  margin: ${({ theme }) => theme.spacing(3, 0)};
`;
const ConnectedGamesH2 = styled(H2) `
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
const StyledCoreButton = styled(CoreButton) `
  color: ${({ theme }) => theme.palette.grey[400]};
  white-space: nowrap;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
  font-size: 14px !important;
  border-radius: 8px;
  svg {
    color: ${({ theme }) => theme.palette.grey[400]};
    width: 16px;
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;
const StyledPlatformSelectionMenu = styled(PlatformSelectionMenu) `
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
  background: white;
  width: 255px;
  border-radius: 8px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-grow: 1;
    width: auto;
  }
`;
const AddGameButton = styled(CoreButton) `
  background: ${({ theme }) => theme.palette.grey[100]} !important;
  color: ${({ theme }) => theme.palette.grey[400]} !important;
  border: 0 !important;
  min-width: ${({ isMobile }) => (isMobile ? '157px' : '244px')} !important;
  height: ${({ isMobile }) => (isMobile ? '153px' : '296px')} !important;
  width: 100%;
  height: 296px;
  flex-direction: column;

  svg {
    width: 28px;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;
const HeadingButtonsWrapper = styled(Flex) `
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;
const CompetePage = () => {
    const [_, setSelectedPlatformSlug] = useLocalStorage('selectedPlatformSlug', '');
    const { platformSlug } = useSelectedPlatform();
    const { allGames } = useGamesList();
    const { authenticated, userProfile } = useUserState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [gameSearchString, setGameSearchString] = useState('');
    const [showPlatformSelect, setShowPlatformSelect] = useState(false);
    const { userConnectedGames, userUnconnectedGames, error } = usePlatformGamesList();
    const { allUserGameRegions } = useUserGamesListener(userProfile === null || userProfile === void 0 ? void 0 : userProfile.handle);
    const userGameRegions = allUserGameRegions();
    const { openModal } = useLocalAppContext();
    const trackEvent = useTrackEvent();
    const trackUserFlow = useTrackUserFlow();
    // filter games by search term
    const filteredGames = ({ name, shortName }) => {
        var _a;
        const hasFilterString = Boolean(gameSearchString);
        const gameName = name.toLowerCase();
        const lowerShortName = shortName.toLowerCase();
        const searchTerm = (_a = gameSearchString === null || gameSearchString === void 0 ? void 0 : gameSearchString.toLowerCase()) !== null && _a !== void 0 ? _a : '';
        return !hasFilterString || gameName.includes(searchTerm) || lowerShortName.includes(searchTerm);
    };
    const usersConnectedUGRs = getMenuGames(allGames).filter((game) => userGameRegions.find((userGameRegion) => {
        var _a;
        return userGameRegion.game.gameKey === game.gameKey &&
            (!platformSlug || ((_a = userGameRegion.platform) === null || _a === void 0 ? void 0 : _a.slug) === platformSlug);
    }));
    const connectedGames = platformSlug ? userConnectedGames.filter(filteredGames) : usersConnectedUGRs;
    const unConnectedGames = platformSlug
        ? userUnconnectedGames.filter(filteredGames)
        : getMenuGames(allGames)
            .filter((game) => !usersConnectedUGRs.find((ugr) => ugr.gameKey === game.gameKey))
            .filter(filteredGames);
    const addGameButtonLabel = 'Add game';
    const handleShowAddGameModal = () => {
        openModal({ type: 'add-game', filterByPlatform: true, onClose: () => { } });
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'initiate game linking',
                label: addGameButtonLabel,
                moduleName: 'game linking',
                position: 1,
            },
        });
    };
    useConsumeActionQueryParam('show-add-game', () => {
        authenticated && handleShowAddGameModal();
    });
    if (error) {
        return _jsx(ErrorPage, { error: '' });
    }
    return (_jsxs(StyledFullLayout, { children: [_jsxs(HeadingWrapper, { justify: "space-between", align: isMobile ? 'flex-start' : 'center', flexDirection: isMobile ? 'column' : 'row', children: [_jsx(H1, { children: "Compete" }), _jsxs(HeadingButtonsWrapper, { flexDirection: isMobile ? 'row-reverse' : 'row', children: [platformSlug && (_jsxs(StyledCoreButton, { to: `/compete`, variant: "white", onClick: () => setSelectedPlatformSlug(''), children: ["All Games ", _jsx(CircleArrowRight, {})] })), _jsx(StyledPlatformSelectionMenu, { isOpen: showPlatformSelect, onClose: () => setShowPlatformSelect(false), onOpen: () => setShowPlatformSelect(true), onClickOutside: () => setShowPlatformSelect(false) })] })] }), _jsxs(SectionContainer, { children: [_jsx(StyledSearchFilter, { onSearch: setGameSearchString, noTranslate: true, placeholder: "Search..." }), _jsxs(SectionContent, { children: [authenticated && (_jsxs(_Fragment, { children: [_jsx(ConnectedGamesH2, { children: "Connected Games" }), _jsxs(Grid, { container: true, spacing: 2, children: [connectedGames.map((game) => (_jsx(Grid, { item: true, xs: 6, md: 4, lg: 3, xl: 2.4, children: _jsx(GameTile, { game: game, size: isMobile ? 'sm' : 'md', platform: platformSlug }) }, game.id))), _jsx(Grid, { item: true, xs: 6, md: 4, lg: 3, xl: 2.4, children: _jsxs(AddGameButton, { onClick: handleShowAddGameModal, isMobile: isMobile, children: [_jsx(PlusCircleIcon, {}), addGameButtonLabel] }) })] })] })), unConnectedGames.length > 0 && (_jsxs(_Fragment, { children: [_jsx(H2, { children: "Available Games" }), _jsx(Grid, { container: true, spacing: 2, children: unConnectedGames.map((game) => (_jsx(Grid, { item: true, xs: 6, md: 4, lg: 3, xl: 2.4, children: _jsx(GameTile, { game: game, size: isMobile ? 'sm' : 'md', platform: platformSlug }) }, game.id))) })] }))] })] })] }));
};
export default CompetePage;
