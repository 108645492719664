import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/system';
import SectionContainer from "@components/UI/Section/components/SectionContainer";
import SectionHead from "@components/UI/Section/components/SectionHead";
import SectionHeading from "@components/UI/Section/components/SectionHeading";
import SectionSubheading from "@components/UI/Section/components/SectionSubheading";
import GameIcon from "@components/Icons/GameIcon";
import LoadingLogo from "@components/Modals/LoadingModal/Components/LoadingLogo";
import TournamentRow from '@components/Tournament';
import { useGetTournaments } from '@hooks';
import TournamentMessage from './TournamentMessage';
import getTournamentMessageKey from '../helpers/getTournamentMessageKey';
import TournamentTableV2 from '../../TournamentV2/TournamentTable/TournamentTable';
import useTournamentsV2Designs from '../hooks/useTournamentV2Designs';
const StyledDiv = styled('div') `
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  p {
    margin: 0;
  }
`;
const useStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey[100]}`,
    },
    tournamentBody: {
        '& > div, & > a': {
            boxShadow: `0px -1px 0px ${theme.palette.grey[100]} inset`, // boxShadow displays inside element, due to table collpasing borders
        },
    },
    gameIcon: {
        padding: theme.spacing(3),
        maxHeight: '90px',
        fill: theme.palette.grey[300],
    },
}));
const TournamentTable = ({ tournaments, error = false, gameData, isMyTournaments, hasFiltersApplied, isTournamentsLoading, }) => {
    const { classes, cx } = useStyles();
    const gameKey = gameData === null || gameData === void 0 ? void 0 : gameData.gameKey;
    const filters = {
        gameKey,
        active: false,
    };
    const showTournamentsV2 = useTournamentsV2Designs(gameKey);
    const fetchFinishedTournaments = !isTournamentsLoading && tournaments && tournaments.length === 0 && !hasFiltersApplied;
    const { data: finishedTournaments, isLoading } = useGetTournaments(filters, 0, 4, {
        enabled: fetchFinishedTournaments,
    });
    const messageKey = getTournamentMessageKey({ tournaments, gameData, isMyTournaments, error });
    const showRecentlyFinishedTournaments = !isLoading && fetchFinishedTournaments && messageKey === 'coming_soon' && gameKey !== 'fortnite';
    if (messageKey !== null) {
        return _jsxs(_Fragment, { children: [_jsxs(StyledDiv, { className: 'tournament-table', children: [gameKey && _jsx(GameIcon, { game: gameKey, className: classes.gameIcon }), _jsx(TournamentMessage, { messageKey: messageKey, hasFiltersApplied: hasFiltersApplied }), isLoading && fetchFinishedTournaments && _jsx(LoadingLogo, {})] }), showRecentlyFinishedTournaments && (_jsxs(SectionContainer, { className: cx('tournament-table', classes.container), children: [_jsx(SectionHead, { bottomBorder: true, children: _jsx(SectionHeading, { children: "Recently Finished Tournaments" }) }), _jsx("div", { className: classes.tournamentBody, children: !isLoading &&
                                finishedTournaments &&
                                finishedTournaments.tournaments.length > 0 &&
                                finishedTournaments.tournaments.map((tournament, index) => (_jsx(TournamentRow, { gameKey: gameKey, section: "Page", listIndex: index, tournament: tournament }, tournament.id))) })] }))] });
    }
    if (showTournamentsV2) {
        return _jsx(TournamentTableV2, { tournaments: tournaments, gameKey: gameKey });
    }
    return (_jsxs(SectionContainer, { className: cx('tournament-table', classes.container), children: [_jsxs(SectionHead, { bottomBorder: true, children: [_jsx(SectionHeading, { children: "Browse Tournaments" }), _jsx(SectionSubheading, { children: "Find the perfect tournaments for you" })] }), _jsx("div", { className: classes.tournamentBody, children: tournaments.map((tournament, index) => (_jsx(TournamentRow, { gameKey: gameKey, section: "Page", listIndex: index, tournament: tournament }, tournament.id))) })] }));
};
export default TournamentTable;
