import { jsx as _jsx } from "react/jsx-runtime";
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from '.';
const Chevron = ({ className, title, direction }) => {
    switch (direction) {
        case 'up':
            return _jsx(ChevronUp, { className: className, title: title });
        case 'down':
            return _jsx(ChevronDown, { className: className, title: title });
        case 'left':
            return _jsx(ChevronLeft, { className: className, title: title });
        case 'right':
            return _jsx(ChevronRight, { className: className, title: title });
    }
};
export default Chevron;
