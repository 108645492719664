import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { ModalContent } from '@components/Modals/components';
import TextInput from '@components/Fields/TextInput';
import { useAddGameContext } from '../context/AddGameProvider';
import SaveGameDetails from '../SaveGameDetails';
const ContentCODConnect = ({ className, stepName, onSubmit }) => {
    const { connectGameService } = useAddGameContext();
    const [username, setUsername] = useState('');
    const usernameText = (connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey) === 'xboxlive' ? 'Gamertag' : 'Username';
    const handleChange = (e) => {
        setUsername(e.target.value);
    };
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(username);
        }
    };
    // Clears the username when game service changes.
    useEffect(() => {
        setUsername('');
    }, [connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.gameServiceKey]);
    return (_jsxs(ModalContent, { stepName: stepName, className: className, verticalAlign: "top", children: [_jsxs("section", { children: [_jsxs("h3", { children: ["How to connect a ", _jsx("span", { "data-ignore": true, children: connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.name }), " account"] }), _jsxs("ol", { children: [_jsxs("li", { children: ["Click/hover on your username and click \"Account\" or", ' ', _jsx("a", { href: "https://profile.callofduty.com/cod/profile", target: "_blank", rel: "noreferrer noopener", children: "go here" })] }), _jsx("li", { children: "Under \"Account\" copy the long string that appears after \"ID\"" }), _jsx("li", { children: "Paste your ID below" })] }), _jsx(TextInput, { id: "game-service-user-name", "data-ignore": true, placeholder: usernameText, value: username, onChange: handleChange, "aria-label": `${(connectGameService === null || connectGameService === void 0 ? void 0 : connectGameService.name) || ''} ${usernameText}` }), _jsxs("a", { href: "/content/how-to-add-warzone/", target: "_blank", children: ["Click here for a guide on how to find your ", usernameText, "."] })] }), _jsx(SaveGameDetails, { lookupValue: username, onClick: handleSubmit })] }));
};
export default ContentCODConnect;
