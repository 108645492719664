import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { ModalContent } from '@components/Modals';
import { TextInput } from '@components/Fields';
import SaveGameDetails from '../SaveGameDetails';
const useStyles = makeStyles()((theme) => ({
    valorantName: {
        display: 'flex',
        alignItems: 'center',
    },
    valorantHash: {
        margin: theme.spacing(0, 1),
        fontSize: 16,
    },
}));
const ContentConnectValorant = ({ stepName, onSubmit }) => {
    const [riotAccountName, setRiotAccountName] = useState('');
    const [riotTagline, setRiotTagline] = useState('');
    const { classes } = useStyles();
    const handleRiotAccountNameChange = (e) => {
        setRiotAccountName(e.target.value);
    };
    const handleRiotTagline = (e) => {
        setRiotTagline(e.target.value);
    };
    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit(riotAccountName);
        }
    };
    return (_jsxs(ModalContent, { stepName: stepName, verticalAlign: "top", children: [_jsxs("section", { children: [_jsxs("h3", { children: ["Add your ", _jsx("span", { "data-ignore": true, children: "Riot" }), " account name"] }), _jsxs("ol", { children: [_jsxs("li", { children: ["Log into the ", _jsx("span", { "data-ignore": true, children: "Valorant" }), " game client"] }), _jsxs("li", { children: ["Find your ", _jsx("span", { "data-ignore": true, children: "Riot" }), " game name in the top left-hand corner."] }), _jsx("li", { children: "Enter your Riot ID in the Riot ID field below. (Should be highlighted in white)" }), _jsxs("li", { children: ["Enter the text behind the ", _jsx("strong", { children: "#" }), " in the tagline below. (Enter without the hash)"] })] }), _jsxs("div", { className: classes.valorantName, children: [_jsx(TextInput, { label: "Riot game name", isLabelSROnly: true, id: "game-valorant-game-name", "data-ignore": true, placeholder: "Riot ID", value: riotAccountName, onChange: handleRiotAccountNameChange }), _jsx("span", { className: classes.valorantHash, children: "#" }), _jsx(TextInput, { label: "Riot game tagline", isLabelSROnly: true, id: "game-valorant-tagline", "data-ignore": true, placeholder: "Tagline", value: riotTagline, onChange: handleRiotTagline })] })] }), _jsx(SaveGameDetails, { lookupValue: riotAccountName, additionalDetails: {
                    tagline: riotTagline.trim(),
                }, onClick: handleSubmit })] }));
};
export default ContentConnectValorant;
