import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import shouldForwardProp from '@emotion/is-prop-valid';
import getGamePalette from '@helpers/getGamePalette';
import GameIcon from '../../../Icons/GameIcon';
const StyledHeader = styled('header', { shouldForwardProp }) `
  background: ${({ theme, paletteName }) => theme.palette[paletteName].main};
  display: flex;
  padding: 30px;

  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & h2 {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      text-align: left;
    }

    & p {
      color: #fff;
      font-size: 12px;
      margin: 0;
      text-align: left;
    }
  }
`;
const StyledGameIcon = styled(GameIcon) `
  fill: #fff;
  width: 55px;
  margin-right: 20px;
`;
const GameConnectBanner = ({ gameKey, gameName, className = '', subtitle = 'Connect your account', }) => {
    return (_jsxs(StyledHeader, { className: className, paletteName: getGamePalette(gameKey), children: [_jsx(StyledGameIcon, { game: gameKey }), _jsxs("div", { children: [_jsx("h2", { "data-ignore": true, children: gameName }), _jsx("p", { children: subtitle })] })] }));
};
export default GameConnectBanner;
