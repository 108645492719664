import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import ModalStatusBanner from '@components/Modals/components/ModalStatusBanner';
import CoreButton from "@components/Buttons/CoreButton";
const Wrapper = styled('div') `
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};
  width: 100%;
`;
const TextWrapper = styled('div') `
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing(2)};
`;
const Heading = styled('h2') `
  color: ${({ theme }) => theme.palette.grey[900]};
  font-size: 26px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
`;
const Text = styled('p') `
  color: ${({ theme }) => theme.palette.grey[400]};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 600;
`;
const StyledButton = styled(CoreButton) `
  background: ${(props) => props.buttonColor}!important;
  border-color: ${(props) => props.buttonColor}!important;
`;
const Confirmation = ({ onComplete, buttonColor }) => {
    return (_jsxs(Wrapper, { children: [_jsx(ModalStatusBanner, { status: "success" }), _jsxs(TextWrapper, { children: [_jsx(Heading, { children: "Congratulations" }), _jsx(Text, { children: "Please claim your tournament entry" })] }), _jsx(StyledButton, { buttonColor: buttonColor, onClick: onComplete, block: true, size: "md", variant: "primary", children: "Join Tournament" })] }));
};
export default Confirmation;
