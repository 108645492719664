import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/system';
const Container = styled('span') `
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
const GamesPlayedSpan = styled('span') `
  color: ${({ theme }) => theme.palette.primary.main};
`;
const Divider = styled('span') `
  font-size: 14;
  color: ${({ theme }) => theme.palette.grey[400]};
`;
const GamesPlayed = ({ gamesPlayed, requiredGames, className }) => {
    return (_jsxs(Container, { className: className, children: [_jsx(GamesPlayedSpan, { "data-ignore": true, children: gamesPlayed }), _jsx(Divider, { "data-ignore": true, children: "\u00A0/\u00A0" }), _jsxs("span", { "data-ignore": true, children: [requiredGames, "\u00A0"] }), _jsx("span", { children: "Games played" })] }));
};
export default GamesPlayed;
