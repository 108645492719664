import getSocialMediaServiceBaseUrlFromType from './getSocialMediaServiceBaseUrlFromType';
const getSocialMediaAccountDisplayName = (account) => {
    var _a;
    if (account.type === 'google') {
        return account.username;
    }
    if (account.type === 'facebook') {
        return (_a = account.name) !== null && _a !== void 0 ? _a : account.username;
    }
    return `${getSocialMediaServiceBaseUrlFromType(account.type)}/${account.username}`;
};
export default getSocialMediaAccountDisplayName;
