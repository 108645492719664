import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import axios from 'axios';
import { captureException } from '@sentry/react';
import SectionContainer from '@components/UI/Section/components/SectionContainer';
import SectionHead from '@components/UI/Section/components/SectionHead';
import { SectionHeading } from '@components/UI/Section/components/SectionHeading';
import SectionSubheading from '@components/UI/Section/components/SectionSubheading';
import Icon from '@components/Icons/Icon';
import useUserGamesListener from '@components/Hooks/useUserGamesListener';
import ConfirmModal from '@components/Modals/ConfirmModal';
import { useUpdateUserState, useUserState } from '@context/UserContext';
import getGamePalette from '@helpers/getGamePalette';
import { useLocalAppContext } from '@context/LocalAppContext';
import GameAccountsBody from './GameAccountsBody';
import useTrackEvent from '@hooks/useTrackEvent';
const useStyles = makeStyles()((theme, { selectedPalette }) => ({
    deleteIcon: {
        height: '86px',
        width: '86px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette[selectedPalette].main,
        '& svg': {
            height: '40px',
            fill: theme.palette.common.white,
        },
    },
}));
const GameAccounts = ({ className, isCurrentUser, userHandle }) => {
    var _a, _b;
    const { loading, allUserGameRegions, triggerReload: reloadUserGames } = useUserGamesListener(userHandle);
    const [deleting, setDeleting] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const gameColor = getGamePalette((_a = confirmDelete === null || confirmDelete === void 0 ? void 0 : confirmDelete.game.gameKey) !== null && _a !== void 0 ? _a : 'fortnite');
    const { classes, cx } = useStyles({ selectedPalette: gameColor });
    const { userDetails } = useUserState();
    const { refresh: refreshUserState } = useUpdateUserState();
    const allData = allUserGameRegions();
    const { openModal } = useLocalAppContext();
    const trackEvent = useTrackEvent();
    const onConfirmDelete = async () => {
        try {
            setDeleting(true);
            await axios.delete(`/api/user_games/region/${confirmDelete === null || confirmDelete === void 0 ? void 0 : confirmDelete.id}`);
            setDeleting(false);
            setConfirmDelete(null);
            refreshUserState();
            reloadUserGames();
        }
        catch (e) {
            captureException(e, {
                extra: {
                    request: 'region_delete',
                },
            });
            setDeleting(false);
        }
    };
    const onDeleteAccountClick = (ugr) => {
        if (isCurrentUser) {
            setConfirmDelete(ugr);
        }
    };
    const handleShowAddGameModal = (addGameButtonLabel) => {
        openModal({ type: 'add-game', onClose: () => { } });
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'initiate game linking',
                label: addGameButtonLabel,
                moduleName: 'game linking',
                position: 1, // position 0 is the sidebar add game button
            },
        });
    };
    return (_jsxs(SectionContainer, { className: cx(className), children: [_jsxs(SectionHead, { children: [_jsx(SectionHeading, { children: isCurrentUser ? 'Game IDs' : 'My Games' }), _jsx(SectionSubheading, { children: "Game accounts I've connected" })] }), _jsx(GameAccountsBody, { loading: loading, userGames: allData, onConnectAccountsClick: handleShowAddGameModal, onDeleteAccountClick: onDeleteAccountClick, isCurrentUser: isCurrentUser, userHandle: userHandle }), isCurrentUser && (_jsx(ConfirmModal, { show: confirmDelete != null, icon: _jsx("div", { className: classes.deleteIcon, children: _jsx(Icon, { icon: (_b = confirmDelete === null || confirmDelete === void 0 ? void 0 : confirmDelete.game.gameKey) !== null && _b !== void 0 ? _b : '' }) }), heading: `Delete ${confirmDelete === null || confirmDelete === void 0 ? void 0 : confirmDelete.handle}?`, description: _jsxs(_Fragment, { children: ["By clicking delete, you will be removed from all ongoing tournaments using this Game ID (other games will not effected).", _jsx("br", {}), _jsx("br", {}), " You will also need to wait 30 days before you can connect a different Game ID in the same region.", (userDetails === null || userDetails === void 0 ? void 0 : userDetails.isSuspended) ? (_jsxs("strong", { children: [_jsx("br", {}), _jsx("br", {}), " This Game ID has been suspended and will not be able to be added to other Repeat accounts.", _jsx("br", {}), _jsx("br", {}), " If you are attempting to circumnavigate your recent suspension by adding this Game ID to another accounts you risk both accounts being banned."] })) : null] }), loading: deleting, confirmText: "Delete", confirmColor: gameColor, handleConfirm: onConfirmDelete, handleClose: () => setConfirmDelete(null) }))] }));
};
export default GameAccounts;
