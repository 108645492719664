import { useQueryString } from '@hooks';
import { useParams } from 'react-router';
import { getGameKeyFromSlug } from '@domain/Tournament/helpers';
import { useState } from 'react';
const useTournamentFilterState = () => {
    const queryString = useQueryString();
    const { gameSlug } = useParams();
    const gameKey = getGameKeyFromSlug(gameSlug);
    const filterState = {
        gameKey,
        search: queryString.getString('search'),
        rankKey: queryString.getString('rank_key'),
        USD: queryString.getBoolean('cash') ? {} : undefined,
        PM: queryString.getBoolean('coins') ? {} : undefined,
        gameModeCode: queryString.getStringArray('game_mode'),
        requiringHiddenCount: queryString.getString('category')
            ? {
                category: queryString.getString('category'),
            }
            : undefined,
    };
    const [localFilterState, setLocalFilterState] = useState(filterState);
    const updateFilterState = (newState, isLocalState) => {
        var _a;
        setLocalFilterState({
            ...localFilterState,
            ...newState,
        });
        if (!isLocalState) {
            const newFilterState = {
                ...filterState,
                ...newState,
            };
            queryString.update({
                search: newFilterState.search,
                rank_key: newFilterState.rankKey,
                cash: !!newFilterState.USD || undefined,
                coins: !!newFilterState.PM || undefined,
                category: (_a = newFilterState.requiringHiddenCount) === null || _a === void 0 ? void 0 : _a.category,
                game_mode: newFilterState.gameModeCode,
            });
        }
    };
    const resetFilterState = () => {
        queryString.update({});
        setLocalFilterState({ gameModeCode: [] });
    };
    return {
        filterState,
        localFilterState,
        updateFilterState,
        resetLocalFilterState: () => setLocalFilterState(filterState),
        resetFilterState,
    };
};
export default useTournamentFilterState;
