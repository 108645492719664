import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlatformIcon } from '@components/UI/Icon';
import IconBackground from '@components/UI/Icon/IconBackground';
import { usePlatformSelectAnimation } from '@context/PlatformSelectAnimationContext';
import useSelectedPlatform from '@hooks/useSelectedPlatform';
import { keyframes, styled } from '@mui/system';
const animationTimeMs = 2000;
const loadingBarAnimationTimeMs = 500;
const animationFadeOutDelayMs = 300;
const animationContainerFadeInOutTimeMs = animationTimeMs + animationFadeOutDelayMs;
const animationIconSize = 36;
const animationIconOffset = animationIconSize / 2;
const shouldForwardProp = (prop) => prop !== 'endPosition';
const loadingBarKeyframes = keyframes `
   from {
      width: 0%;
    }
    to {
      width: 100%;
    }
`;
const loadingContainerKeyframes = keyframes `
  0% {
      opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`;
const containerFadeInOutKeyframes = keyframes `
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;
const LoadingIndicator = styled('div', { shouldForwardProp }) `
  background-color: #00a2f0;
  height: 100%;
  border-radius: 17px;
  width: 0%;
  animation-fill-mode: forwards;
  animation-name: ${loadingBarKeyframes};
  animation-duration: ${loadingBarAnimationTimeMs}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 0;
  animation-timing-function: linear;
`;
const LoadingIndicatorContainer = styled('div') `
  height: 5px;
  width: 235px;
  background-color: rgba(17, 18, 22, 0.1);
  border-radius: 17px;
  margin-bottom: 15px;
`;
const LoadingContentContainer = styled('div', { shouldForwardProp }) `
  margin: auto auto 0;
  text-align: center;
  padding-bottom: 20px;
  animation: ${loadingContainerKeyframes} ${animationTimeMs}ms linear 1 normal forwards;
`;
const AnimationContainer = styled('div', { shouldForwardProp }) `
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  top: 0;
  opacity: 0;

  animation-name: ${containerFadeInOutKeyframes};
  animation-duration: ${animationContainerFadeInOutTimeMs}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 0;
  animation-timing-function: linear;
  animation-play-state: running;
`;
const Background = styled('div') `
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.layout.headerDesktopHeight}px);
  background-color: white;
  position: absolute;
  top: 55px;
  left: 0;
  display: flex;
`;
const StyledIconBackground = styled(IconBackground, { shouldForwardProp }) `
  position: absolute;
  top: calc(50% - ${animationIconOffset}px);
  left: calc(50% - ${animationIconOffset}px);
  z-index: 1200;

  animation-name: IconAnimation;
  animation-duration: ${animationTimeMs}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: 0;
  animation-timing-function: ease-in-out;
  animation-play-state: running;

  @keyframes IconAnimation {
    0% {
      scale: 0.8;
      top: calc(50% - ${animationIconOffset}px);
      left: calc(50% - ${animationIconOffset}px);
    }

    20% {
      scale: 1.5;
      top: calc(50% - ${animationIconOffset}px);
      left: calc(50% - ${animationIconOffset}px);
    }

    40% {
      scale: 0.8;
      top: calc(50% - ${animationIconOffset}px);
      left: calc(50% - ${animationIconOffset}px);
    }

    60% {
      scale: 1.5;
      top: calc(50% - ${animationIconOffset}px);
      left: calc(50% - ${animationIconOffset}px);
    }

    80% {
      scale: 1.5;
      top: calc(50% - ${animationIconOffset}px);
      left: calc(50% - ${animationIconOffset}px);
    }

    100% {
      scale: 1;
      top: ${(props) => props.endPosition.x}px;
      left: ${(props) => props.endPosition.y}px;
    }
  }
`;
const PlatformSelectAnimation = () => {
    const { platformSlug } = useSelectedPlatform();
    const { endAnimationIconPosition, shouldAnimate, stopAnimation } = usePlatformSelectAnimation();
    const unmount = (event) => {
        if (event.animationName === containerFadeInOutKeyframes.name) {
            stopAnimation();
        }
    };
    if (!shouldAnimate || !endAnimationIconPosition) {
        return null;
    }
    return (_jsxs(AnimationContainer, { onAnimationEnd: unmount, children: [_jsx(Background, { children: _jsxs(LoadingContentContainer, { children: [_jsx(LoadingIndicatorContainer, { children: _jsx(LoadingIndicator, {}) }), _jsx("div", { children: "We're preparing your experience" })] }) }), _jsx(StyledIconBackground, { size: "md", endPosition: endAnimationIconPosition, platformSlug: platformSlug, children: _jsx(PlatformIcon, { platform: platformSlug, color: platformSlug }) })] }));
};
export default PlatformSelectAnimation;
