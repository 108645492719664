import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import GenericModal from '../GenericModal';
import { CombinedTextSubmit } from '../..';
const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Montserrat',
        alignItems: 'center',
        padding: '40px',
    },
    form: {
        width: '100%',
    },
    icon: {
        marginTop: '30px',
        fontSize: '100px',
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '26px',
        marginTop: '32px',
    },
    text: {
        fontSize: '12px',
        color: '#a4a4a4',
        marginTop: '20px',
        textAlign: 'center',
    },
    field: {
        width: '100%',
        marginTop: '32px',
    },
    closeBtn: {
        position: 'absolute',
        top: '0',
        right: '0',
        fontSize: '26px',
    },
}));
export const PasswordModal = ({ show, className, handleSubmit, onClose, isLoading }) => {
    const { classes, cx } = useStyles();
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitText, setSubmitText] = useState('Join');
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setSubmitText('Joining...');
        handleSubmit({ password: password.trim() });
    };
    const handleChange = (e) => setPassword(e.currentTarget.value);
    useEffect(() => {
        if (!isLoading) {
            setPassword('');
            setLoading(false);
            setSubmitText('Join');
        }
    }, [isLoading]);
    const handleClose = () => {
        setPassword('');
        onClose();
    };
    return (_jsx(GenericModal, { className: cx(className), show: show, size: "medium", onClose: handleClose, children: _jsxs("div", { className: classes.container, children: [_jsx(IconButton, { onClick: handleClose, className: classes.closeBtn, "data-testid": "password entry close modal button", size: "large", children: _jsx("i", { className: "fal fa-times-circle" }) }), _jsx("div", { children: _jsx("img", { src: "/img/password-lock.svg", alt: "padlock", className: classes.icon }) }), _jsx("h2", { className: classes.heading, children: "Password Required" }), _jsx("p", { className: classes.text, children: "This tournament requires you to enter a Password in order to join the tournament" }), _jsx("form", { className: classes.form, children: _jsx(CombinedTextSubmit, { type: "password", name: "passwordEntry", onChange: handleChange, onBlur: handleChange, value: password, label: "Password", placeholder: "Enter Password", submitText: submitText, onSubmit: handleFormSubmit, className: classes.field, loading: loading, autoComplete: "off", autoFocus: true }) })] }) }));
};
export default PasswordModal;
