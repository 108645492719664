import { ErrorWithCause } from '@helpers';
import { captureMessage, Severity } from '@sentry/react';
import axios from 'axios';
const completePaypalDeposit = async (request) => {
    const requestPath = '/api/deposit/paypal/v1/';
    try {
        const { paypalOrderId, ...requestBody } = request;
        const response = await axios.patch(`${requestPath}${paypalOrderId}`, requestBody);
        return response.data;
    }
    catch (error) {
        captureMessage('Unable to complete the paypal deposit', {
            level: Severity.Critical,
            extra: {
                requestMethod: 'PATCH',
                requestPath,
                paypalOrderId: request.paypalOrderId,
                newOrderState: request.newOrderState,
            },
        });
        throw new ErrorWithCause(error);
    }
};
export default completePaypalDeposit;
