import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { RepeatText, RepeatLogo } from '@components/Icons/Brand';
import getMenuPalette from '@helpers/getMenuPalette';
import useRootRedirectUrl from '@hooks/useRootRedirectUrl';
const useStyles = makeStyles()((theme, { focusedPaletteName }) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        logoWrapper: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2, 2.5),
            background: focusedPaletteName ? theme.palette[focusedPaletteName].main : theme.palette.common.white,
            transition: 'background 0.2s',
            height: '55px',
            borderRight: `1px solid ${theme.palette.grey[100]}`,
            '& .logo': {
                width: '32px',
                marginRight: '2px',
                fill: focusedPaletteName ? theme.palette.common.white : theme.palette.primary.main,
            },
            '& .logoText': {
                fill: focusedPaletteName ? theme.palette.common.white : theme.palette.grey[900],
            },
            '& a': {
                color: 'white',
            },
            '& button': {
                color: 'white',
            },
        },
    };
});
const Logo = ({ focusedMenuItem }) => {
    const { classes } = useStyles({
        focusedPaletteName: getMenuPalette(focusedMenuItem),
    });
    const rootRedirectUrl = useRootRedirectUrl();
    return (_jsx("nav", { className: classes.container, children: _jsxs(Link, { className: classes.logoWrapper, to: rootRedirectUrl, children: [_jsx(RepeatLogo, { className: "logo" }), _jsx(RepeatText, { className: "logoText" })] }) }));
};
export default Logo;
