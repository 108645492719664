import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { AppBar, Divider, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Skeleton from 'react-loading-skeleton';
import { Feature } from 'flagged';
import getMenuGames from '@helpers/getMenuGames';
import { useUserState } from '@context/UserContext';
import useGamesList from '@hooks/API/useGamesList';
import CoreButton from '@components/Buttons/CoreButton';
import Currency from '@components/UI/Currency';
import CaretDown from '@components/Icons/CaretDown';
import ButtonPopper from '@components/UI/ButtonPopper';
import Wallet from '@components/Icons/Wallet';
import { useGetBalance } from '@components/Hooks';
import NotificationMenu from '@domain/Notification/NotificationMenu';
import LanguageMenu from './Menus/LanguageMenu';
import NavigationSubMenu from './Menus/NavigationSubMenu';
import ProfileMenu from './Menus/ProfileMenu';
import NavigationSite from './Menus/NavigationSite';
import useFocusedMenuItem from './Menus/hooks';
import PlatformSelectionMenu from './Menus/PlatformSelectionMenu';
import NavigationSiteRight from './Menus/NavigationSiteRight';
import Logo from './Menus/Logo';
import { usePlatformSelectAnimation } from '@context/PlatformSelectAnimationContext';
import styled from '@mui/system/styled';
import classnames from 'classnames';
const useStyles = makeStyles()((theme) => ({
    container: {
        fontFamily: theme.typography.fontFamily,
        height: '55px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
    },
    navigationWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    loginActions: {
        display: 'flex',
        padding: theme.spacing(1),
        '&.skeleton > span': {
            display: 'flex',
            '& > span:first-of-type': {
                marginRight: theme.spacing(1),
            },
        },
    },
    button: {
        whiteSpace: 'nowrap',
        borderRadius: '0px',
    },
    callToAction: {
        width: '100px',
    },
    loginButton: {
        border: `2px solid ${theme.palette.grey[100]}`,
        color: theme.palette.grey[300],
        marginRight: theme.spacing(1),
        '&:hover, &:active, &:focus': {
            color: theme.palette.primary.main,
            border: `2px solid ${theme.palette.primary.main}`,
        },
    },
    languageMenu: {
        display: 'flex',
        alignItems: 'center',
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        '& button': {
            height: '55px',
            padding: theme.spacing(0, 2),
            border: '0',
        },
    },
    profileBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '55px',
        width: '90px',
        borderRadius: '0',
        padding: theme.spacing(0, 2),
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        '& img': {
            borderRadius: '4px',
            width: '30px',
            height: '30px',
        },
        [`&.open .caret`]: {
            transform: 'rotate(180deg)',
            fill: theme.palette.grey[400],
            width: '10px',
        },
    },
    yourFunds: {
        position: 'relative',
        display: 'flex',
        height: '55px',
        padding: '0px',
        background: theme.palette.common.white,
        paddingRight: '50px',
        border: 'none',
        borderRadius: 0,
        [`&:hover .walletIcon`]: {
            maxWidth: '100%',
            '& span': {
                maxWidth: '100%',
                opacity: 1,
            },
            '& svg': {
                marginRight: theme.spacing(1),
            },
        },
    },
    yourFundsText: {
        textAlign: 'left',
        padding: theme.spacing(1.5, 1),
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(1.5, 2),
        },
        '& p': {
            margin: '0px',
            color: theme.palette.grey[400],
            fontSize: '10px',
            fontWeight: 600,
        },
        [`& .currency:first-of-type`]: {
            marginRight: theme.spacing(1),
        },
    },
    walletIcon: {
        position: 'absolute',
        background: theme.palette.primary.main,
        width: '100%',
        maxWidth: '50px',
        height: '55px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        right: '0px',
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(.4,0,1,1)',
        fontSize: '12px',
        color: theme.palette.common.white,
        '& span': {
            maxWidth: '0%',
            opacity: 0,
            transition: 'max-width: 0.5s, opacity 0.7s',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '& svg': {
            fill: theme.palette.common.white,
            width: '18px',
        },
    },
    caret: {
        fill: theme.palette.grey[200],
        marginLeft: theme.spacing(2),
        transition: 'transform 0.2s, fill 0.2s',
    },
    popper: {
        zIndex: theme.zIndex.appBar + 2,
        fontFamily: theme.typography.fontFamily,
    },
    currency: {
        color: theme.palette.grey[900],
        fontSize: '12px',
        fontWeight: 600,
    },
    desktopProfileMenu: {
        padding: theme.spacing(1.5, 1, 0),
        width: '205px',
        border: `1px solid ${theme.palette.grey[100]}`,
    },
    profileName: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 600,
        color: theme.palette.grey[900],
        '& p': {
            margin: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        '& img': {
            width: '35px',
            height: '35px',
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(1.5),
        },
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
}));
const PlatformSelectionMenuHeader = styled(PlatformSelectionMenu) `
  width: 177px;
  height: 100%;
  button {
    height: 100%;
    border-radius: 0px;
  }
`;
/**
 * Used as the primary navigation for users across repeat.gg
 */
const HeaderDesktop = ({ onShowLoginModal, onShowRegisterModal, onShowCouponModal, onShowWithdrawalModal, onShowFundsModal, }) => {
    const { allGames } = useGamesList();
    const menuGames = getMenuGames(allGames);
    const { authenticated, userProfile, isLoading: isLoadingUser } = useUserState();
    const { data: balance, isLoading: isLoadingBalance } = useGetBalance();
    const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
    const [showPlatformSelect, setShowPlatformSelect] = useState(false);
    const theme = useTheme();
    const showFundsText = useMediaQuery(theme.breakpoints.up('xl'));
    const { setEndAnimationIconPosition, shouldAnimate } = usePlatformSelectAnimation();
    let timeout = null;
    const handleStartMenuCloseTimer = () => {
        if (hoveredMenuItem) {
            timeout = setTimeout(function () {
                setHoveredMenuItem(null);
            }, 400);
        }
    };
    const handleStopMenuCloseTimer = () => {
        if (timeout) {
            clearTimeout(timeout);
        }
    };
    const focusedMenuItem = useFocusedMenuItem();
    const { classes, cx } = useStyles();
    const currentGame = focusedMenuItem
        ? menuGames.find((game) => game.gameKey === focusedMenuItem)
        : undefined;
    const platformIconRef = useRef();
    useEffect(() => {
        if (platformIconRef.current) {
            const rect = platformIconRef.current.getBoundingClientRect();
            setEndAnimationIconPosition({ x: rect.top, y: rect.left });
        }
    }, [platformIconRef.current]);
    return (_jsxs(_Fragment, { children: [_jsxs(AppBar, { position: "fixed", color: "inherit", elevation: 0, className: classes.container, children: [_jsxs("nav", { className: classes.container, children: [_jsx(Logo, { focusedMenuItem: focusedMenuItem }), _jsx(PlatformSelectionMenuHeader, { shouldAnimate: shouldAnimate, isOpen: showPlatformSelect, onClose: () => setShowPlatformSelect(false), onOpen: () => setShowPlatformSelect(true), onClickOutside: () => setShowPlatformSelect(false), ref: platformIconRef }), _jsx(NavigationSite, {})] }), _jsxs("div", { className: classes.navigationWrapper, children: [_jsx(NavigationSiteRight, {}), _jsx(LanguageMenu, { className: classes.languageMenu }), isLoadingUser && (_jsx("div", { className: cx(classes.loginActions, 'skeleton'), children: _jsx(Skeleton, { width: 100, height: 45, count: 2 }) })), !authenticated && !isLoadingUser && (_jsx(_Fragment, { children: _jsxs("div", { className: classes.loginActions, children: [_jsx(CoreButton, { onClick: onShowLoginModal, variant: "white", appearance: "outline", className: cx(classes.button, classes.callToAction, classes.loginButton), children: "Log In" }), _jsx(CoreButton, { onClick: onShowRegisterModal, className: cx(classes.callToAction, classes.button), children: "Sign Up" })] }) })), authenticated && !isLoadingUser && (_jsxs(_Fragment, { children: [_jsx(Feature, { name: "enable_notifications", children: _jsx(NotificationMenu, { className: classes.popper }) }), userProfile && (_jsx(ButtonPopper, { buttonProps: {
                                            variant: 'white',
                                            className: cx(classes.button, classes.profileBtn),
                                            'aria-controls': 'simple-menu',
                                            'aria-haspopup': 'true',
                                            ariaLabel: 'Profile Menu',
                                            children: (_jsxs(_Fragment, { children: [_jsx("img", { src: userProfile.profileTinyUrl, alt: "player icon", height: "45", width: "45" }), ' ', _jsx(CaretDown, { className: cx('caret', classes.caret) })] })),
                                        }, className: classes.popper, children: _jsxs("div", { className: classes.desktopProfileMenu, children: [_jsxs("div", { className: classes.profileName, children: [_jsx("img", { src: userProfile.profileTinyUrl, alt: "player icon" }), _jsx("p", { "data-notranslate": true, children: userProfile.handle })] }), _jsx(Divider, { className: classes.divider }), _jsx(ProfileMenu, { onWithdrawClick: onShowWithdrawalModal, showCouponModal: onShowCouponModal })] }) })), _jsxs(CoreButton, { ariaLabel: "Add Funds", className: classes.yourFunds, variant: "white", size: "sm", onClick: onShowFundsModal, children: [_jsx("div", { style: { margin: 'auto' }, children: _jsxs("div", { className: classes.yourFundsText, children: [!isLoadingBalance && balance && (_jsx(Currency, { amount: balance.USD, currency: "USD", className: cx('currency', classes.currency) })), !isLoadingBalance && balance && (_jsx(Currency, { amount: balance.PM, currency: "PM", className: cx('currency', classes.currency) })), showFundsText && _jsx("p", { children: "Your Funds" })] }) }), _jsxs("div", { className: classnames('walletIcon', classes.walletIcon), children: [_jsx(Wallet, {}), " ", _jsx("span", { children: "Add Funds" })] })] })] }))] })] }), _jsx(NavigationSubMenu, { currentGame: currentGame, hoveredMenuItem: hoveredMenuItem, focusedMenuItem: focusedMenuItem, onMouseEnter: handleStopMenuCloseTimer, onMouseLeave: handleStartMenuCloseTimer })] }));
};
export default HeaderDesktop;
