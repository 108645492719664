import { jsx as _jsx } from "react/jsx-runtime";
import Icon from '@components/Icons/Icon';
import { useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()((theme, { variant }) => ({
    iconContainer: {
        borderRadius: '25px',
        width: '45px',
        height: '45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '13px',
        background: theme.palette[variant].light,
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        '& svg': {
            width: '18px',
        },
    },
}));
/**
 * Used to display an icon for the notification item
 * @param props
 * @param props.variant - Color Palette to use
 * @param props.icon - Valid notification icon name. When set, src is ignored.
 * @param props.src - URL of an image. Defaults to Repeat logo. This is used if icon name is not provided.
 * @param props.className - class name
 */
const NotificationIcon = ({ variant, src, icon, className }) => {
    const { classes, cx } = useStyles({ variant });
    const theme = useTheme();
    const imgSrc = src || '/img/logos/repeat_logo_228x228.png';
    return (_jsx("div", { className: cx(classes.iconContainer, className), children: icon ? _jsx(Icon, { icon: icon, color: theme.palette[variant].main }) : _jsx("img", { src: imgSrc }) }));
};
export default NotificationIcon;
