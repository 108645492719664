import { differenceInSeconds } from 'date-fns';
import { getTournamentCloseTime, getTournamentFinishTime } from '@domain/Tournament/helpers';
import { getTournamentState } from '@domain/Tournament/helpers/getTournamentStatus';
const statusHasWon = ['Allocated', 'Paid'];
const getTournamentPhaseContext = ({ tournament, theme, registrationEndingMinutes, }) => {
    var _a, _b;
    const status = getTournamentState(tournament, registrationEndingMinutes);
    const start = new Date(tournament.start_time);
    const tournamentEntryCloseTime = getTournamentCloseTime(tournament.start_time, tournament.length_minutes, registrationEndingMinutes);
    const tournamentFinishTime = getTournamentFinishTime(tournament.start_time, tournament.length_minutes);
    const hasEntered = !!tournament.user_entry;
    const { eligible, status: entryStatus } = (_a = tournament.user_entry) !== null && _a !== void 0 ? _a : {};
    const hasWon = entryStatus && statusHasWon.includes(entryStatus);
    const isFull = status !== 'finished' && !tournament.maximum_prize_positions && tournament.entries_count >= ((_b = tournament.slots) !== null && _b !== void 0 ? _b : 0);
    // same message for all users
    if (status === 'ended') {
        return {
            text: 'Tournament is finished. Payment pending',
            subText: 'We are calculating prize distribution',
            icon: 'clock',
            color: theme.palette.purple.main,
            variant: 'outlined',
        };
    }
    if (hasEntered) {
        if (status === 'closed' || status === 'open') {
            return {
                text: 'You have joined - start competing in',
                seconds: differenceInSeconds(start, new Date()),
                subText: 'Matches will be tracked only after the tournament starts',
                icon: 'check',
                variant: 'contained',
                color: theme.palette.success.main,
            };
        }
        if (status === 'open_started' || status === 'started') {
            if (eligible) {
                return {
                    text: 'You have qualified - results are tracking for',
                    seconds: differenceInSeconds(tournamentFinishTime, new Date()),
                    subText: 'Play more games to try and improve your score',
                    icon: 'check',
                    variant: 'contained',
                    color: theme.palette.success.main,
                };
            }
            return {
                text: 'You have joined but not yet qualified - results are tracking for',
                seconds: differenceInSeconds(tournamentFinishTime, new Date()),
                subText: 'Play more eligible games to qualify',
                icon: 'warning',
                variant: 'outlined',
                color: theme.palette.success.main,
            };
        }
        if (!eligible) {
            return {
                text: 'Tournament is finished. Failed to qualify.',
                subText: 'Did not play enough eligible games to qualify',
                icon: 'trophy',
                color: theme.palette.error.main,
                variant: 'outlined',
            };
        }
        if (hasWon) {
            return {
                text: 'Tournament is finished. You have won prizes!',
                icon: 'trophy',
                color: theme.palette.purple.main,
                variant: 'contained',
            };
        }
        return {
            text: 'Tournament is finished',
            subText: 'Payments are complete',
            icon: 'trophy',
            color: theme.palette.purple.main,
            variant: 'outlined',
        };
    }
    // Not joined or Anonymous user
    if (isFull) {
        return {
            text: 'Tournament is full',
            icon: 'users',
            color: theme.palette.error.main,
            textColor: theme.palette.grey[400],
            variant: 'outlined',
            subText: 'Browse other tournaments',
            subLink: '../',
        };
    }
    if (status === 'closed' || status === 'open') {
        return {
            text: 'Tournament starts in',
            seconds: differenceInSeconds(start, new Date()),
            icon: 'clock',
            color: theme.palette.primary.main,
            textColor: theme.palette.grey[400],
            variant: 'outlined',
        };
    }
    if (status === 'open_started') {
        return {
            text: 'Tournament started - registration ends in ',
            seconds: differenceInSeconds(tournamentEntryCloseTime, new Date()),
            icon: 'clock',
            color: theme.palette.primary.main,
            textColor: theme.palette.grey[400],
            variant: 'outlined',
        };
    }
    if (status === 'started') {
        return {
            text: 'Tournament is in the final hour. Registration has ended.',
            icon: 'clock',
            color: theme.palette.primary.main,
            textColor: theme.palette.grey[400],
            variant: 'outlined',
        };
    }
    return {
        text: 'Tournament is finished',
        icon: 'trophy',
        color: theme.palette.purple.main,
        variant: 'outlined',
    };
};
export default getTournamentPhaseContext;
