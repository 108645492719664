import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getTournamentStatusVariant } from '../helpers';
import { styled } from '@mui/material';
import StatusIndicator from '../../../components/UI/StatusIndicator';
import ProgressBar from '../../../components/UI/ProgressBar';
const Wrapper = styled('div') `
  padding: ${({ theme }) => theme.spacing(0, 2)};
  font-family: ${({ theme }) => theme.typography.fontFamily};
`;
const Caption = styled('div') `
  font-weight: 600;
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 12px;
`;
const FlexRow = styled('div') `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProgressBarRow = styled(FlexRow) `
  padding-top: 13px;
`;
const EntryCount = styled('div') `
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[900]};
  font-weight: 600;
  margin: 0;
`;
const StatusText = styled(StatusIndicator) `
  font-weight: 700;
  font-size: 12px;
`;
const ProgressWrapper = styled('div', { shouldForwardProp: (p) => p !== 'showMaxEntriesCount' }) `
  min-width: ${(props) => (props.showMaxEntriesCount ? '65%' : '100%')};
  display: inline-flex;
  align-items: center;
`;
export const Progress = styled(ProgressBar) `
  margin: 0 !important;
`;
const TournamentStatus = ({ tournamentState }) => {
    const tournamentStatus = getTournamentStatusVariant(tournamentState);
    return (_jsx(StatusText, { status: tournamentStatus, children: `${tournamentState.charAt(0).toUpperCase()}${tournamentState.slice(1)}` }));
};
const TournamentProgressBar = ({ currentEntries, maxEntries, tournamentState, className = '', showMaxEntriesCount = false, maxPrizePositions = 0, listView = false, }) => {
    if (maxEntries === null) {
        const finished = tournamentState === 'closed' || tournamentState === 'finished';
        return (_jsxs(Wrapper, { className: className, children: [_jsxs(FlexRow, { children: [_jsxs(EntryCount, { children: [!finished && 'Top', " ", maxPrizePositions === null || maxPrizePositions === void 0 ? void 0 : maxPrizePositions.toLocaleString(), " Players"] }), !listView ? _jsx(TournamentStatus, { tournamentState: tournamentState }) : null] }), _jsx(FlexRow, { children: _jsx(Caption, { children: finished ? 'Received prizes' : 'Receive prizing' }) }), listView ? (_jsx(FlexRow, { children: _jsx(TournamentStatus, { tournamentState: tournamentState }) })) : null] }));
    }
    const percentage = currentEntries > 0 ? Math.floor((currentEntries / maxEntries) * 100) : 0;
    return (_jsxs(Wrapper, { className: className, children: [_jsxs(FlexRow, { children: [_jsx(EntryCount, { "data-notranslate": true, children: showMaxEntriesCount ? (`${currentEntries.toLocaleString(undefined)} of ${maxEntries.toLocaleString(undefined)}`) : (_jsxs(Caption, { children: [_jsxs("span", { "data-ignore": true, children: [percentage, "%"] }), " Full"] })) }), !listView ? _jsx(TournamentStatus, { tournamentState: tournamentState }) : null] }), listView ? (_jsx(FlexRow, { children: _jsx(TournamentStatus, { tournamentState: tournamentState }) })) : null, _jsxs(ProgressBarRow, { children: [showMaxEntriesCount && (_jsxs(Caption, { children: [_jsxs("span", { "data-ignore": true, children: [percentage, "%"] }), " Full"] })), _jsx(ProgressWrapper, { showMaxEntriesCount: showMaxEntriesCount, children: _jsx(Progress, { currentValue: currentEntries, maxValue: maxEntries }) })] })] }));
};
export default TournamentProgressBar;
