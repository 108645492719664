import { checkAgeConsent } from '@helpers';
import { useEffect } from 'react';
/**
 * Used by AddGameProvider - This custom hook is invoked when selected game data has changed.
 * It will perform an age check for the newly selected game and trigger the game changed effect
 * @param config
 * @param config.state - Add Game context state
 * @param config.userDetails - Current user's details if there is one.
 * @param config.onUserIsOfAge - Invoked when the user IS of age (as in only when it's true)
 * @param config.onSelectedGameChanged - Effect invoked when the selected game is changed and is not null
 */
const useSelectedGameChanged = ({ state, userDetails, onUserIsOfAge, onSelectedGameChanged, }) => {
    var _a;
    /**
     * Dependencies: state.selectedGame.id
     * When a new game is selected:
     * - Perform age check
     * - Fetch for availableGameServices (platforms and regions) for the selected game
     */
    useEffect(() => {
        if (state.selectedGame !== null) {
            if (!state.isOverConsentAge && state.requireAgePrompt) {
                const isOfAge = checkAgeConsent(state.selectedGame, userDetails === null || userDetails === void 0 ? void 0 : userDetails.age);
                if (isOfAge) {
                    onUserIsOfAge();
                }
            }
            onSelectedGameChanged(state.selectedGame.gameKey);
        }
    }, [(_a = state === null || state === void 0 ? void 0 : state.selectedGame) === null || _a === void 0 ? void 0 : _a.id, userDetails === null || userDetails === void 0 ? void 0 : userDetails.age]);
};
export default useSelectedGameChanged;
