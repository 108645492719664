import { jsx as _jsx } from "react/jsx-runtime";
const tournamentDetailTourSteps = [
    {
        target: '.tournament-info-container',
        title: _jsx("p", { children: "Tournament Specifics" }),
        content: _jsx("p", { children: "View the dates, prize pool, entries, game, and tournament ID here." }),
        disableBeacon: true,
    },
    {
        target: '.tournament-conditions',
        title: _jsx("p", { children: "Tournament Overview & Rules" }),
        content: (_jsx("p", { children: "Gametypes, regions, and ranks allowed will be shown here. Only ranked games after you enter are counted, and we ban smurf accounts immediately." })),
    },
    {
        target: '.tournament-scoring-container',
        title: _jsx("p", { children: "Scoring" }),
        content: _jsx("p", { children: "Scoring will differ by game and tournament: See how we score your results here." }),
    },
    {
        target: '.tournament-cta-button',
        title: _jsx("p", { children: "Add Your Game ID" }),
        content: (_jsx("p", { children: "Start earning by linking your game ID! You only need to do this once and can enter as many tournaments as you want after." })),
    },
];
export default tournamentDetailTourSteps;
