import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { styled } from '@mui/material';
import YouTube from 'react-youtube';
import useVideoEntry from '../hooks/useVideoEntry';
import CoreButton from '@components/Buttons/CoreButton';
import { CheckboxField } from '@components/Fields';
import { SROnly } from '@components/UI';
const Wrapper = styled('div') `
  padding: ${({ theme }) => theme.spacing(0, 4)};
  width: 100%;
`;
const Placeholder = styled('button') `
  align-items: center;
  background: transparent;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.grey[100]};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 360px;
  width: 100%;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  i {
    font-size: 60px;
  }
`;
const ActionButtons = styled('div') `
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(0, 4, 4)};
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;
const Countdown = styled('div') `
  background-color: ${({ theme }) => theme.palette.common.black};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 20px;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  text-align: right;
`;
const Player = styled(YouTube) `
  display: flex;
  height: 360px;
  max-width: 100%;
  max-height: 100%;
`;
const StyledButton = styled(CoreButton) `
  align-items: baseline;
  background: ${(props) => props.buttonColor}!important;
  border-color: ${(props) => props.buttonColor}!important;
  gap: 8px;
`;
const VideoPlayer = ({ video_id, onComplete, url, linkText, onCancel, showAdvertisingConsent = false, buttonColor, }) => {
    const { remaining, handleFinished, handlePlay, handlePause, handleError, handleConsentChange, watched, consent, reset, } = useVideoEntry({ showAdvertisingConsent });
    const canEnter = showAdvertisingConsent ? watched && consent : watched;
    return (_jsxs(_Fragment, { children: [_jsx("p", { children: "Watch this video to claim your free tournament entry" }), watched ? (_jsx(Wrapper, { children: _jsxs(Placeholder, { type: "button", onClick: reset, children: [_jsx("i", { className: "far fa-play-circle" }), _jsx(SROnly, { children: "Play again" })] }) })) : (_jsxs(Wrapper, { children: [remaining && _jsx(Countdown, { children: remaining }), _jsx(Player, { videoId: video_id, opts: { playerVars: { controls: 0 } }, onEnd: handleFinished, onPlay: handlePlay, onPause: handlePause, onError: handleError })] })), showAdvertisingConsent && (_jsx(CheckboxField, { label: "I accept that entering this tournament authorizes Repeat to share my details with the sponsor of this tournament.", onChange: handleConsentChange, value: "", name: "agreeEnterTournament" })), _jsxs(ActionButtons, { children: [_jsx(StyledButton, { onClick: onComplete, disabled: !canEnter, to: url, block: true, size: "md", variant: "primary", buttonColor: buttonColor, children: watched ? (_jsxs(_Fragment, { children: [_jsx("span", { children: linkText }), " ", _jsx("i", { className: "fa fa-external-link-alt" })] })) : ('Watch the video to continue') }), _jsx(CoreButton, { appearance: "subdued", type: "button", size: "md", variant: "grey", block: true, onClick: onCancel, children: "Cancel" })] })] }));
};
export default VideoPlayer;
