import { useQuery } from 'react-query';
import { useUserState } from '@context/UserContext';
import getCart from '@api/getCart';
import { getCartQuantity, isAxiosError, isShoppingCart } from '@helpers';
function getCurrency(cart) {
    const defaultCurrency = 'PM';
    return (cart && isShoppingCart(cart) && cart.currency) || defaultCurrency;
}
export const cartQueryKey = 'cart';
const useCartApi = () => {
    var _a;
    const { authenticated } = useUserState();
    const { refetch, error, data, isLoading, isError, remove } = useQuery(cartQueryKey, getCart, {
        enabled: authenticated,
    });
    const isCart404Error = isError && isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404;
    return {
        cart: error ? undefined : data,
        refetch,
        remove,
        isLoading,
        totalPrice: data && isShoppingCart(data) ? data.totalPrice : 0,
        currency: getCurrency(data),
        quantity: !isCart404Error ? getCartQuantity(data) : 0,
    };
};
export default useCartApi;
