import { useMemo, useState } from 'react';
import { captureException } from '@sentry/react';
import useCountdown from '@components/Hooks/useCountdown';
const useVideoEntry = ({ onComplete = () => undefined, showAdvertisingConsent = false }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [watched, setWatched] = useState(false);
    const [consent, setConsent] = useState(false);
    const { setRemainingSeconds, remainingSecondsFormatted } = useCountdown({
        startSeconds: 0,
    });
    const handleConsentChange = (e) => setConsent(e.currentTarget.checked);
    const formatRemaining = (seconds) => {
        if (seconds === 0)
            return '';
        const displayMin = Math.floor(seconds / 60);
        const displaySec = seconds % 60;
        return `${displayMin}:${displaySec < 10 ? '0' : ''}${displaySec}`;
    };
    const updateDuration = (target) => {
        const remaining = Math.floor(target.getDuration() - target.getCurrentTime());
        setTimeLeft(remaining);
        setRemainingSeconds(remaining);
    };
    const handleFinished = ({ target }) => {
        updateDuration(target);
        setIsPlaying(false);
        setWatched(true);
        onComplete();
    };
    const handlePlay = ({ target }) => {
        updateDuration(target);
        setIsPlaying(true);
    };
    const handlePause = ({ target }) => {
        updateDuration(target);
        setIsPlaying(false);
    };
    const handleError = (event) => {
        updateDuration(event.target);
        setIsPlaying(false);
        captureException(event);
    };
    const remaining = useMemo(() => {
        if (isPlaying) {
            return remainingSecondsFormatted;
        }
        return formatRemaining(timeLeft);
    }, [isPlaying, remainingSecondsFormatted, timeLeft]);
    return {
        remaining,
        handleFinished,
        handlePlay,
        handlePause,
        handleError,
        watched,
        consent,
        handleConsentChange,
        reset: () => setWatched(false),
    };
};
export default useVideoEntry;
