/**
 * Return a list of games that should be returned to display User Stats
 *
 * @param games - Games to filter
 * @returns list of games that should display user stats
 */
const getGamesWithUserStats = (games) => {
    const validStatus = ['active', 'beta', 'paused'];
    return games.filter((game) => validStatus.includes(game.status));
};
export default getGamesWithUserStats;
