import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { styled } from '@mui/material';
import classnames from 'classnames';
import GenericModal from '../../GenericModal';
import VideoPlayer from './VideoPlayer';
import Verifying from './Verifying';
import Confirmation from './Confirmation';
import { CancelCircle } from '@components/UI/Icon/Icons';
const ModalContent = styled('div') `
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${({ theme }) => theme.spacing(2)};
  text-align: center;

  p {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
`;
const Banner = styled('img') `
  max-height: 240px;
  max-width: 100%;
  width: 630px;
  object-fit: cover;
`;
const StyledCloseButton = styled('button') `
  background: transparent;
  border: none;
  mix-blend-mode: multiply;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
`;
export const VideoLinkModal = ({ entry, show, className, handleSuccess, handleClose, tournamentBanner, }) => {
    const [state, setState] = useState('pending');
    const [agreed, setAgreed] = useState(false);
    const { instance_data } = entry;
    const { bannerImageUrl, video_id, show_consent, color, url, verifyingText, timeoutSeconds, link_text } = instance_data;
    const timerDuration = parseInt(timeoutSeconds, 10);
    const showAdvertisingConsent = show_consent !== false;
    const handleEnterClick = () => {
        const entryData = showAdvertisingConsent ? { agreed } : {};
        handleSuccess(entryData);
    };
    const handleVideoComplete = () => {
        if (showAdvertisingConsent) {
            setAgreed(true);
        }
        setState('verifying');
    };
    return (_jsx(GenericModal, { className: classnames(className), size: "large", show: show, onClose: handleClose, "data-testid": "video modal", children: _jsx("div", { children: _jsxs(ModalContent, { children: [_jsxs("div", { children: [_jsx(Banner, { src: bannerImageUrl !== null && bannerImageUrl !== void 0 ? bannerImageUrl : tournamentBanner, alt: "" }), _jsx(StyledCloseButton, { onClick: handleClose, children: _jsx(CancelCircle, { width: 24 }) })] }), state === 'pending' && (_jsx(VideoPlayer, { video_id: video_id, onComplete: handleVideoComplete, onCancel: handleClose, url: url, linkText: link_text, buttonColor: color })), state === 'verifying' && (_jsx(Verifying, { buttonColor: color, onComplete: () => setState('finished'), title: verifyingText, timeout: timerDuration })), state === 'finished' && _jsx(Confirmation, { buttonColor: color, onComplete: handleEnterClick })] }) }) }));
};
export default VideoLinkModal;
