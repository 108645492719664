import { ErrorWithCause } from '@helpers';
import axios from 'axios';
const getAdvertisements = async (params) => {
    try {
        const response = await axios.get('/api/engagement/v1', { params });
        return response.data;
    }
    catch (error) {
        throw new ErrorWithCause(error);
    }
};
export default getAdvertisements;
