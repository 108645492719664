import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ContentCarousel } from '@components';
import ConnectTwitterAccount from './ConnectTwitter/ConnectTwitterAccount';
import { getSocialMediaServiceBaseUrlFromType } from '@helpers/SocialMedia';
import ConnectSocialMediaStatus from './ConnectSocialMediaStatus';
import ConnectYouTubeAccount from './ConnectYouTube/ConnectYouTubeAccount';
import ConnectInstagramAccount from './ConnectInstagram/ConnectInstagramAccount';
import ConnectTwitchAccount from './ConnectTwitch/ConnectTwitchAccount';
const ConnectSocialMediaCarousel = ({ step, username, socialMediaType, className, onStepChange, onSuccess, onError, onConfirm, onRetry, addedSocialMediaAccount, addSocialMediaAccountError, }) => {
    const [currentStep, setCurrentStep] = useState(addedSocialMediaAccount ? 1 : step || 0);
    const [accountConnectStatus, setAccountConnectStatus] = useState(addedSocialMediaAccount && addSocialMediaAccountError ? 'error' : 'success');
    const [accountConnectErrorMessage, setAccountConnectErrorMessage] = useState(addedSocialMediaAccount && addSocialMediaAccountError ? addSocialMediaAccountError : '');
    const handleSuccess = (response) => {
        // Go to the success step
        setCurrentStep(1);
        setAccountConnectStatus('success');
        setAccountConnectErrorMessage('');
        if (onSuccess) {
            onSuccess(response);
        }
    };
    const handleError = (errorMessage) => {
        setCurrentStep(1);
        setAccountConnectStatus('error');
        setAccountConnectErrorMessage(errorMessage);
        if (onError) {
            onError(errorMessage);
        }
    };
    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
    };
    const handleRetry = () => {
        setCurrentStep(0);
        if (onRetry) {
            onRetry();
        }
    };
    // If parent uses the step prop to force the content to go to a certain step
    useEffect(() => {
        if (step !== undefined && step !== null) {
            setCurrentStep(step);
        }
    }, [step]);
    // invoked when steps change
    useEffect(() => {
        if (onStepChange) {
            onStepChange(currentStep);
        }
    }, [currentStep]);
    return (_jsxs(ContentCarousel, { currentStep: currentStep, className: className, name: "Connect Social Media", children: [socialMediaType === 'twitch' && _jsx(ConnectTwitchAccount, {}), socialMediaType === 'twitter' && (_jsx(ConnectTwitterAccount, { existingUsername: username, onSuccess: handleSuccess, onError: handleError })), socialMediaType === 'youtube' && (_jsx(ConnectYouTubeAccount, { existingUrl: username ? `https://${getSocialMediaServiceBaseUrlFromType(socialMediaType)}/${username}` : '', onSuccess: handleSuccess, onError: handleError })), socialMediaType === 'instagram' && (_jsx(ConnectInstagramAccount, { existingUsername: username, onSuccess: handleSuccess, onError: handleError })), _jsx(ConnectSocialMediaStatus, { socialMediaType: socialMediaType, status: accountConnectStatus, errorMessage: accountConnectErrorMessage, onConfirm: handleConfirm, onRetry: handleRetry })] }));
};
export default ConnectSocialMediaCarousel;
