import { jsx as _jsx } from "react/jsx-runtime";
import ChevronRight from '@components/Icons/ChevronRight';
import { makeStyles } from 'tss-react/mui';
import useTrackEvent from '@hooks/useTrackEvent';
const useStyles = makeStyles()((theme) => ({
    trail: {
        color: theme.palette.grey[300],
        width: '100%',
        height: '43px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        boxShadow: `0px 2px 5px rgba(0,0,0,0.06)`,
        padding: theme.spacing(0, 3),
        fontFamily: theme.typography.fontFamily,
        transition: 'opacity 0.3s, transform 0.6s',
    },
    trailHidden: {
        display: 'none',
    },
    breadcrumb: {
        border: 'none',
        background: 'transparent',
        color: theme.palette.primary.main,
        fontSize: '10px',
        fontWeight: 600,
    },
    breadcrumbArrow: {
        width: '4px',
        height: '7px',
    },
    currentBreadcrumb: {
        color: theme.palette.grey[300],
    },
    hideCrumb: {
        display: 'none',
    },
}));
/**
 * Breadcrumb component used for modals.
 * If building a new modal from scratch, take a look at `ModalCarouselContent`
 * It still uses `ModalBreadcrumb` internally, but has a slightly more focused API.
 *
 * @param currentIndex - position of the currently selected breadcrumb.
 * @param breadcrumbs - array of ModalBreadcrumbMeta objects. Available props
 *  - id - uniquely identifies the breadcrumb
 *  - component - name of the breadcrumb, make it short, and it allows components
 *  - onClick - Optional onClick handler, that will have the breadcrumb ID as the argument.
 * @param onClick - Optional onclick event that returns the selected breadcrumb position and the ModalBreadcrumbMeta
 * @param isVisible - Optional boolean to hide the breadcrumb with opacity animation.
 */
const ModalBreadcrumb = ({ currentIndex, breadcrumbs = [], onClick, isVisible, loading = false, hideCrumbs = [], }) => {
    const { classes, cx } = useStyles();
    const trackEvent = useTrackEvent();
    const handleClick = (breadcrumbIndex, breadcrumb) => {
        trackEvent({
            type: 'game_linking',
            data: {
                eventName: 'game linking',
                label: breadcrumb.component,
                moduleName: 'Breadcrumb link',
                position: 0,
            },
        });
        if (!loading && onClick && breadcrumbIndex !== currentIndex) {
            onClick(breadcrumbIndex, breadcrumb);
        }
        if (!loading && (breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.onClick)) {
            breadcrumb.onClick(breadcrumb.id);
        }
    };
    const renderBreadcrumbs = breadcrumbs
        .flatMap((breadcrumb, idx) => {
        var _a;
        return [
            _jsx("button", { className: cx(classes.breadcrumb, {
                    [classes.currentBreadcrumb]: idx === currentIndex || loading,
                }, hideCrumbs.includes(idx) ? classes.hideCrumb : ''), type: "button", disabled: idx === currentIndex || loading, onClick: () => handleClick(idx, breadcrumb), children: (_a = breadcrumb.component) !== null && _a !== void 0 ? _a : breadcrumb.id }, `breadcrumb-${idx}`),
            _jsx(ChevronRight, { className: cx(classes.breadcrumbArrow, hideCrumbs.includes(idx) ? classes.hideCrumb : '') }, `join-${idx}`),
        ];
    })
        .slice(0, -1);
    return (_jsx("nav", { className: cx(classes.trail, {
            [classes.trailHidden]: !isVisible,
        }), "aria-hidden": !isVisible, children: renderBreadcrumbs }));
};
export default ModalBreadcrumb;
