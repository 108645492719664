import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import Overwatch from './Overwatch';
import Counterstrike from './Counterstrike';
import Valorant from './Valorant';
import ApexLegends from './ApexLegends';
import Pubg from './Pubg';
import PubgMobile from './PubgMobile';
import BrawlStars from './BrawlStars';
import Destiny2 from './Destiny2';
import RocketLeague from './RocketLeague';
import CallOfDutyWarzone2 from './CallOfDutyWarzone2';
import MLBTheShow23 from './MLBTheShow23';
import Fortnite from './Fortnite';
import CallOfDuty from './CallOfDuty';
import LeagueOfLegends from './LeagueOfLegends';
import Dota2 from './Dota2';
import RainbowSix from './RainbowSix';
import getGamePalette from '@helpers/getGamePalette';
const useStyles = makeStyles()((theme, { gameKey }) => {
    const gameColor = theme.palette[getGamePalette(gameKey)].main;
    return {
        gameColor: {
            fill: gameColor,
        },
    };
});
/** @deprecated - use symfony/assets/js/react/components/UI/Icon/GameIcon.tsx */
const GameIcon = ({ game, className = '', optimise = false, showGameColor, title }) => {
    if (optimise) {
        switch (game) {
            case 'fortnite':
                return _jsx("img", { className: className, src: "/img/games/strategy/fortnite/fortnite.png", alt: "Fortnite" });
            case 'cod-warzone':
                return (_jsx("img", { className: className, src: "/img/games/fps/cod-warzone/warzone.png", alt: "Call of Duty Warzone Caldera" }));
            case 'cod-warzone-2':
                return (_jsx("img", { className: className, src: "/img/games/fps/cod-warzone-2/cod-warzone-2.png", alt: "Call of Duty Warzone" }));
            case 'pc-dota_2':
                return _jsx("img", { className: className, src: "/img/games/strategy/dota/dota2.png", alt: "Dota 2" });
            case 'league_of_legends':
                return (_jsx("img", { className: className, src: "/img/games/strategy/league_of_legends/pc-league_of_legends.png", alt: "League of Legends" }));
            case 'rainbow6':
                // @TODO - Need to update this with full icon sets when Rainbow6 is out.
                return _jsx("img", { className: className, src: "/img/game_icons/rainbow6.svg", alt: "Rainbow 6" });
            case 'overwatch':
                return _jsx("img", { className: className, src: "/img/game_icons/overwatch.svg", alt: "Overwatch" });
            case 'csgo':
                return (_jsx("img", { className: className, src: "/img/game_icons/counterstrike.svg", alt: "Counter Strike: Global Offensive" }));
            case 'valorant':
                return _jsx("img", { className: className, src: "/img/games/fps/valorant/valorant.png", alt: "Valorant" });
            case 'pubg':
                return _jsx("img", { className: className, src: "/img/games/fps/pubg/pubg.png", alt: "PUBG" });
            case 'pubg-mobile':
                return _jsx("img", { className: className, src: "/img/games/fps/pubg-mobile/pubg-mobile.png", alt: "PUBG Mobile" });
            case 'brawl-stars':
                return _jsx("img", { className: className, src: "/img/games/fps/brawl-stars/brawl-stars.png", alt: "Brawl Stars" });
            case 'destiny-2':
                return _jsx("img", { className: className, src: "/img/games/fps/destiny-2/destiny-2.png", alt: "Destiny 2" });
            case 'apex-legends':
                return _jsx("img", { className: className, src: "/img/games/fps/apex-legends/apex-legends.png", alt: "Apex Legends" });
            case 'rocket-league':
                return (_jsx("img", { className: className, src: "/img/games/sports/rocket_league/rocket-league.png", alt: "Rocket League" }));
            case 'mlb-the-show-23':
                return (_jsx("img", { className: className, src: "/img/games/sports/mlb-the-show-23/mlb-the-show-23.png", alt: "MLB\u00AE The Show 23\u2122" }));
        }
    }
    const { classes, cx } = useStyles({ gameKey: game });
    const componentClasses = cx({
        [classes.gameColor]: showGameColor,
    }, className);
    switch (game) {
        case 'fortnite':
            return _jsx(Fortnite, { className: componentClasses, title: title });
        case 'cod-warzone':
            return _jsx(CallOfDuty, { className: componentClasses, title: title });
        case 'cod-warzone-2':
            return _jsx(CallOfDutyWarzone2, { className: componentClasses, title: title });
        case 'pc-dota_2':
            return _jsx(Dota2, { className: componentClasses, title: title });
        case 'league_of_legends':
            return _jsx(LeagueOfLegends, { className: componentClasses, title: title });
        case 'rainbow6':
            return _jsx(RainbowSix, { className: componentClasses, title: title });
        case 'overwatch':
            return _jsx(Overwatch, { className: componentClasses, title: title });
        case 'csgo':
            return _jsx(Counterstrike, { className: componentClasses, title: title });
        case 'valorant':
            return _jsx(Valorant, { className: componentClasses, title: title });
        case 'apex-legends':
            return _jsx(ApexLegends, { className: componentClasses, title: title });
        case 'pubg':
            return _jsx(Pubg, { className: componentClasses, title: title });
        case 'pubg-mobile':
            return _jsx(PubgMobile, { className: componentClasses, title: title });
        case 'brawl-stars':
            return _jsx(BrawlStars, { className: componentClasses, title: title });
        case 'destiny-2':
            return _jsx(Destiny2, { className: componentClasses, title: title });
        case 'rocket-league':
            return _jsx(RocketLeague, { className: componentClasses, title: title });
        case 'mlb-the-show-23':
            return _jsx(MLBTheShow23, { className: componentClasses, title: title });
        default:
            return _jsx(_Fragment, {});
    }
};
export default GameIcon;
