// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
// https://tc39.es/ecma402/#sec-intl-pluralrules-constructor
const englishOrdinalRules = new Intl.PluralRules('en', { type: 'ordinal' });
const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
};
/**
 * Given a number, Returns 1st, 2nd 3rd etc
 *
 * @param number The number
 * @returns the ordinal suffix for the number
 */
const getOrdinalSuffix = (number) => {
    const category = englishOrdinalRules.select(number);
    const suffix = suffixes[category];
    return number + suffix;
};
export default getOrdinalSuffix;
