import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlusIcon } from '@heroicons/react/20/solid';
import { css, styled } from '@mui/material/styles';
import IconBackground from '@components/UI/Icon/IconBackground';
import { isPlatformSlug } from '@Types/platform';
import Flex from '@components/Layout/Flex';
import { Icon, PlatformIcon } from '../UI/Icon';
import UnstyledLinkButton from './UnstyledLinkButton';
const shouldForwardProp = (prop) => {
    const disallowedHtmlAttributes = ['minimize', 'size'];
    return !disallowedHtmlAttributes.includes(prop);
};
const Container = styled(UnstyledLinkButton, { shouldForwardProp }) `
  color: ${({ theme }) => theme.palette.grey[900]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ size, theme }) => (size === 'sm' ? theme.spacing(1.5) : theme.spacing(1))};
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  background: unset;
  border: unset;
  text-align: start;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? theme.palette.grey[0] : theme.palette.primary.light)};
  }

  ${({ disabled }) => disabled &&
    css `
      opacity: 0.2;
    `}

  @keyframes ShrinkAndGrowContainer {
    0% {
      min-width: 100%;
    }

    10% {
      min-width: 0%;
    }

    80% {
      min-width: 0%;
    }

    100% {
      min-width: 100%;
    }
  }

  ${(props) => props.minimize &&
    css `
      animation: ShrinkAndGrowContainer 2000ms linear 1 normal forwards;
    `}
`;
const Chevron = styled(Icon) `
  fill: ${({ theme }) => theme.palette.grey[400]};
  padding: 2px;
  max-width: 400px;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;
const Plus = styled(PlusIcon) `
  margin: auto;
  height: 16px;
`;
const PlusBackground = styled('div', { shouldForwardProp: (prop) => prop !== 'size' }) `
  width: ${({ size }) => (size === 'sm' ? '28px' : '36px')};
  height: ${({ size }) => (size === 'sm' ? '28px' : '36px')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(1)};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  i {
    margin: 0;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 400;
  }
`;
const PlatformText = styled('span', { shouldForwardProp }) `
  margin-left: ${({ theme }) => theme.spacing(1)};
  flex: 1;
  overflow: hidden;
  white-space: nowrap;

  @keyframes ShrinkAndGrowText {
    0% {
      flex: 1;
    }

    10% {
      flex: 0;
    }

    80% {
      flex: 0;
    }

    100% {
      flex: 1;
    }
  }

  ${(props) => props.minimize &&
    css `
      animation: ShrinkAndGrowText 2000ms linear 1 normal forwards;
    `}
`;
/**
 * Platform icon and label rendered inside either a Link or Button
 */
const PlatformLinkButton = ({ minimize, platform, className, to, onClick, chevron, innerRef, disabled, iconRef, size = 'sm', }) => {
    var _a;
    const platformSlug = platform === null || platform === void 0 ? void 0 : platform.slug;
    let content = (_jsx(PlusBackground, { size: size, ref: iconRef, children: _jsx(Plus, {}) }));
    if (disabled && isPlatformSlug(platformSlug)) {
        content = (_jsx(IconBackground, { size: size, platformSlug: platformSlug, children: _jsx(PlatformIcon, { width: 20, platform: platformSlug, color: platformSlug, title: platform === null || platform === void 0 ? void 0 : platform.abbreviation }) }));
    }
    else if (isPlatformSlug(platformSlug)) {
        content = (_jsx(IconBackground, { size: size, ref: iconRef, platformSlug: platformSlug, children: _jsx(PlatformIcon, { width: 20, platform: platformSlug, color: platformSlug, title: platform === null || platform === void 0 ? void 0 : platform.abbreviation }) }));
    }
    return (_jsxs(Container, { size: size, minimize: minimize, className: className, to: to, onClick: onClick, ref: innerRef, disabled: disabled, children: [_jsxs(Flex, { flexDirection: "row", align: "center", gap: 0, children: [content, _jsx(PlatformText, { minimize: minimize, children: (_a = platform === null || platform === void 0 ? void 0 : platform.name) !== null && _a !== void 0 ? _a : 'Select Platform' })] }), chevron === 'down' && _jsx(Chevron, { icon: "chevrondown", width: 16, height: 16 }), chevron === 'right' && _jsx(Chevron, { icon: "chevronright", width: 16, height: 16 })] }));
};
export default PlatformLinkButton;
