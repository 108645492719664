import { jsx as _jsx } from "react/jsx-runtime";
import { Icon } from '@components/UI/Icon';
import { Choice } from '@components/UI/Choice';
import { getAccountLoginSocialMediaTypes } from '@domain/User/constants';
import { getSocialMediaServiceNameFromType } from '@helpers/SocialMedia';
import isMobileBrowser from '@helpers/isMobileBrowser';
import isMobileInAppBrowser from '@helpers/isMobileInAppBrowser';
const isDisabledLoginMethodInInAppBrowser = (type) => ['facebook', 'google'].includes(type);
const AuthMethodSelector = ({ authAction, onSelect, className = '' }) => {
    const availableTypes = [...getAccountLoginSocialMediaTypes(authAction), 'Email'];
    const labelPrefix = authAction === 'login' ? 'Log In with ' : 'Sign Up with ';
    const socialMediaAccountOptions = availableTypes.map((type) => ({
        id: type,
        value: type,
        label: _jsx("span", { children: `${labelPrefix} ${type === 'Email' ? 'Email' : getSocialMediaServiceNameFromType(type)}` }),
        icon: _jsx(Icon, { icon: type === 'Email' ? 'repeatlogo' : type, color: type === 'Email' ? 'primary' : type }),
        paletteName: 'primary',
        type,
        disabled: isMobileBrowser() && isMobileInAppBrowser() && isDisabledLoginMethodInInAppBrowser(type),
    }));
    return (_jsx(Choice, { className: className, choices: socialMediaAccountOptions, onSelect: (selected) => onSelect(selected), selectButtonLabel: "Choose how you want to signup" }));
};
export default AuthMethodSelector;
