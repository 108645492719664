import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from 'tss-react/mui';
import { Choice } from '@components/UI/Choice';
import GenericModal from './GenericModal';
import GameConnectBanner from './AddGameModal/Components/GameConnectBanner';
const useStyles = makeStyles()((theme) => ({
    modalBody: {
        width: '430px',
        maxWidth: '100%',
        fontFamily: theme.typography.fontFamily,
    },
    content: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        background: 'transparent',
        border: 'none',
        color: theme.palette.grey[300],
        fontWeight: 'bold',
        fontSize: '20px',
    },
    cancelButton: {
        marginTop: theme.spacing(2),
        width: '100%',
        padding: theme.spacing(2),
        fontWeight: 400,
        height: '58px',
        fontSize: '13px',
    },
}));
const UGRSelectModal = ({ gameKey, gameName, open = false, userRegions, onConfirm, onClose, className, }) => {
    const { classes } = useStyles();
    const choices = userRegions
        .sort((a, b) => a.handle.localeCompare(b.handle))
        .map((ugr) => {
        var _a;
        return {
            id: ugr.id,
            value: ugr.id,
            label: `${ugr.handle} - ${(_a = ugr === null || ugr === void 0 ? void 0 : ugr.gameService) === null || _a === void 0 ? void 0 : _a.name} (${ugr === null || ugr === void 0 ? void 0 : ugr.region}) - ${ugr.platform.name}`,
        };
    });
    const resolveChoiceMode = (count) => {
        // The text for these choices is long, so don't use the grid mode.
        if (count <= 5) {
            return 'buttons';
        }
        return 'select';
    };
    return (_jsx(GenericModal, { show: open, disableBackdropClick: false, disableEscapeKeyDown: false, onClose: onClose, className: className, children: _jsxs("div", { className: classes.modalBody, children: [_jsx("button", { type: "button", onClick: onClose, className: classes.closeButton, "aria-label": "close add game modal", children: _jsx("i", { className: "fal fa-times" }) }), _jsx(GameConnectBanner, { gameKey: gameKey, gameName: gameName, subtitle: "Select your game ID" }), _jsxs("div", { className: classes.content, children: [_jsx("h4", { children: "Select a game ID to enter" }), _jsx(Choice, { choices: choices, selectButtonLabel: "Confirm", onSelect: onConfirm, resolveChoiceMode: resolveChoiceMode })] })] }) }));
};
export default UGRSelectModal;
