import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { TextInput } from '@components';
import Button from "@components/Buttons/Button";
import { ModalContent } from '@components/Modals/components';
import { captureException } from '@sentry/react';
import addSocialMediaAccount from '@helpers/SocialMedia/addSocialMediaAccount';
/**
 * This is one of the steps in the add social media account modal flow
 */
const ConnectTwitterAccount = ({ existingUsername, onSuccess, onError }) => {
    const inputFieldId = 'twitter-username-field';
    const [username, setUsername] = useState(existingUsername || '');
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleChange = (e) => {
        setUsername(e.target.value);
        setErrorMessage('');
        setHasError(false);
    };
    const handleAddTwitter = async () => {
        var _a, _b, _c, _d;
        if (!username) {
            setErrorMessage('Please enter a username');
            setHasError(true);
        }
        else {
            try {
                setLoading(true);
                const response = await addSocialMediaAccount('twitter', {
                    username,
                });
                if (onSuccess && response) {
                    setLoading(false);
                    onSuccess(response === null || response === void 0 ? void 0 : response.data);
                }
                else if (onError) {
                    setLoading(false);
                    onError(_jsxs(_Fragment, { children: ["We are having trouble adding your ", _jsx("span", { "data-ignore": true, children: "Twitter" }), " account. Please make sure it's a valid ", _jsx("span", { "data-ignore": true, children: "Twitter" }), " username."] }));
                }
            }
            catch (e) {
                setLoading(false);
                captureException(e, {
                    extra: {
                        component: 'ConnectTwitterAccount',
                        username,
                    },
                });
                if (onError) {
                    onError(_jsxs(_Fragment, { children: ["We are having trouble adding your ", _jsx("span", { "data-ignore": true, children: "Twitter" }), " account. Please make sure it's a valid ", _jsx("span", { "data-ignore": true, children: "Twitter" }), " username.", ' ', ((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error)
                                ? `Error code: ${(_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.error}`
                                : ''] }));
                }
            }
        }
    };
    return (_jsxs(ModalContent, { children: [_jsxs("div", { children: [_jsxs("h3", { id: "connect-twitter-heading", children: ["Connect ", _jsx("span", { "data-ignore": true, children: "Twitter" })] }), _jsxs("p", { children: ["Enter your ", _jsx("span", { "data-ignore": true, children: "Twitter" }), " username below."] }), _jsx(TextInput, { id: inputFieldId, placeholder: "Your Twitter Username", onChange: handleChange, error: hasError, errorMessage: errorMessage, value: username, "aria-labelledby": "connect-twitter-heading" })] }), _jsx(Button, { type: "button", onClick: handleAddTwitter, size: "lg", variant: "palette", paletteName: "twitter", loading: loading, children: _jsxs("span", { children: ["Connect your ", _jsx("span", { "data-ignore": true, children: "Twitter" })] }) })] }));
};
export default ConnectTwitterAccount;
