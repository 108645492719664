import axios from 'axios';
const getUrl = ({ extensionId, webAccessibleResourceRelativePath }) => {
    return `chrome-extension://${extensionId}/${webAccessibleResourceRelativePath}`;
};
export const isBrowserExtensionInstalled = async (extensionFingerprint) => {
    try {
        const response = await axios.get(getUrl(extensionFingerprint));
        return response.status === 200;
    }
    catch (err) {
        return false;
    }
};
export default isBrowserExtensionInstalled;
