import { jsx as _jsx } from "react/jsx-runtime";
import Icon from './Icon';
const PlatformIcon = ({ platform, optimise = false, color, strength = 'main', height, width, title, }) => {
    switch (platform) {
        case 'mobile':
            return (_jsx(Icon, { icon: "mobile", optimise: optimise, color: color, strength: strength, height: height, width: width, title: title }));
        case 'pc':
            return (_jsx(Icon, { icon: "pc", optimise: optimise, color: color, strength: strength, height: height, width: width, title: title }));
        case 'playstation':
            return (_jsx(Icon, { icon: "ps4", optimise: optimise, color: color, strength: strength, height: height, width: width, title: title }));
        case 'xbox':
            return (_jsx(Icon, { icon: "xboxone", optimise: optimise, color: color, strength: strength, height: height, width: width, title: title }));
        case 'switch':
            return (_jsx(Icon, { icon: "nintendo-switch", optimise: optimise, color: color, strength: strength, height: height, width: width, title: title }));
    }
};
export default PlatformIcon;
