import { SupportedLanguages } from "@Types";
const getLanguageFromStorage = () => {
    const storedLanguageCode = localStorage.getItem('ljs-lang');
    if (storedLanguageCode) {
        const foundLanguage = SupportedLanguages.find((language) => language.code === storedLanguageCode);
        if (foundLanguage) {
            return foundLanguage;
        }
    }
    return SupportedLanguages[0];
};
export default getLanguageFromStorage;
